import type {
  AttachmentGalleryDialogState,
  FormWithValidationState,
} from '@stimcar/libs-uitoolkit';
import { EMPTY_ATTACHMENT_GALLERY_DIALOG_STATE } from '@stimcar/libs-uitoolkit';
import type { GeneralInformationsFormData } from '../../../utils/generalInformations/typings/store.js';
import { EMPTY_GENERAL_INFORMATIONS_FORM } from '../../../utils/generalInformations/typings/store.js';

export type MarketplaceEditAdForm = Pick<
  GeneralInformationsFormData,
  'brand' | 'model' | 'color' | 'motor' | 'mileage' | 'dateOfRegistration'
> & {
  readonly ownerSellingPrice: string;
  readonly publicSellingPrice: string;
  readonly gearbox: string;
  readonly fuel: string;
  readonly marketplaceProcess: string;
  readonly markdownText: string;
};

export type MarketplaceEditAdDialogState = FormWithValidationState<MarketplaceEditAdForm> & {
  readonly active: boolean;
  readonly initialMarkdownText: string;
};

export type MarketplaceDetailsState = {
  readonly editedPictureAttribute: string | undefined;
  readonly attachmentGalleryDialogModal: AttachmentGalleryDialogState;
  readonly editAdDialog: MarketplaceEditAdDialogState;
  readonly selectedTab: 'preview' | 'photoAttachmentsGallery' | 'textAttachmentsGallery';
  readonly markdownText: string;
};

const SAMPLE_TEXT = `Stimcar, la référence du reconditionnement de véhicules d’occasion sur le marché vous propose...
  
Options et équipements (liste non exhaustive) :

- Option 1
- Option 2
...

Options de reconditionnement :

* Option 1 : XXX euros TTC
* Option 2 : XXX euros TTC

`;

export const MARKETPLACE_DETAILS_EMPTY_STATE: MarketplaceDetailsState = {
  editedPictureAttribute: undefined,
  attachmentGalleryDialogModal: {
    ...EMPTY_ATTACHMENT_GALLERY_DIALOG_STATE,
    // Disable selection by default (because the state is both used by the dialog in which
    // attachments are selectable and simple gallery in which we don't want items to be
    // selectable)
    selectedPaths: undefined,
  },
  selectedTab: 'preview',
  markdownText: '',
  editAdDialog: {
    active: false,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    formWarning: undefined,
    formData: {
      ...EMPTY_GENERAL_INFORMATIONS_FORM,
      ownerSellingPrice: '',
      publicSellingPrice: '',
      gearbox: '',
      fuel: '',
      marketplaceProcess: '',
      markdownText: SAMPLE_TEXT,
    },
    initialMarkdownText: '',
  },
};
