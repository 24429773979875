import type {
  CarViewCategory,
  Kanban,
  Memo,
  MemoDesc,
  Operation,
  PackageDeal,
  PackageDealDesc,
  PackageDealVariable,
  Sequence,
  SiteConfiguration,
  SparePart,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext, NoArgActionCallback } from '@stimcar/libs-uikernel';
import {
  contractHelpers,
  DEFAULT_VALIDATE_EXPERTISE_OPERATION,
  EXPERTISE_STAND_ID,
  forEachRecordValues,
  isBooleanVariable,
  isNumericVariable,
  isTextualVariable,
  nonDeleted,
  packageDealHelpers,
  purchaseOrderHelpers,
  workflowHelpers,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import type { SparePartFormData } from '../admin/packageDealDesc/typings/store.js';
import type { Store } from '../state/typings/store.js';
import { convertSparePartFormDataToSparePart } from '../admin/packageDealDesc/adminPackageDealDescUtils.js';
import { EMPTY_SPARE_PART_FORM_DATA } from '../admin/packageDealDesc/typings/store.js';
import type {
  OperatorExpertViewState,
  OperatorExpertViewValidationErrors,
  OperatorViewState,
  SelectedElementType,
} from './typings/store.js';
import {
  ADD_OR_UPDATE_PACKAGE_DEAL_MODALE_EMPTY_STATE,
  DELETION_MODAL_EMPTY_STATE,
} from './typings/store.js';

export const FORM_HORIZONTAL_BODY_FLEX_GROW = 3;

export const DISPLAY_TEXTUAL_SPARE_PARTS_SEPARATOR = '\n';

export const NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID =
  'NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID';

export function getTabs(
  siteConfiguration: SiteConfiguration,
  standId: string
): readonly CarViewCategory[] {
  const stand = nonnull(siteConfiguration.stands.find((s) => s.id === standId));
  return stand.expertiseCategories ?? [];
}

export const convertActiveSparePartsInExpertiseStringDisplay = (
  spareParts: readonly SparePart[]
): string => {
  return spareParts
    .filter(nonDeleted)
    .map((sp): string => sp.label)
    .join(DISPLAY_TEXTUAL_SPARE_PARTS_SEPARATOR);
};

function isThereAlreadyUnfinishedValidationOperation(pkg: PackageDeal): boolean {
  const tab = pkg.operations.filter(
    (op) => op.type === DEFAULT_VALIDATE_EXPERTISE_OPERATION.type && !op.completionDate
  );
  const result = tab.length > 0;
  return result;
}

function getStandIdForValidationOperation(siteConfiguration: SiteConfiguration): string {
  const expertiseValidationStands = workflowHelpers.getAllStandsOfType(
    siteConfiguration,
    'expertiseValidation'
  );
  return expertiseValidationStands[0].id;
}

export function addValidationOperation(
  sequence: Sequence,
  kanban: Kanban,
  siteConfiguration: SiteConfiguration
): Kanban {
  const updatedPackageDeals = kanban.packageDeals.map((packageDeal) => {
    if (
      packageDealHelpers.isExpertisePackageDeal(packageDeal) &&
      !isThereAlreadyUnfinishedValidationOperation(packageDeal)
    ) {
      const validationOperation = {
        ...DEFAULT_VALIDATE_EXPERTISE_OPERATION,
        id: sequence.next(),
        standId: getStandIdForValidationOperation(siteConfiguration),
      };
      return {
        ...packageDeal,
        operations: [...packageDeal.operations, validationOperation],
      };
    }
    return packageDeal;
  });

  return { ...kanban, packageDeals: updatedPackageDeals };
}

function updateSelectedPackageDealDetailsAction(
  { actionDispatch }: ActionContext<Store, OperatorExpertViewState>,
  packageDeal: PackageDeal | undefined
): void {
  if (!packageDeal) {
    actionDispatch.reduce((initial) => {
      return {
        ...initial,
      };
    });
  } else {
    actionDispatch.reduce((initial): OperatorExpertViewState => {
      return {
        ...initial,
        detailsAndMessagesComponentState: {
          ...initial.detailsAndMessagesComponentState,
          selectedPackageDealId: packageDeal.id,
          attachments: isTruthy(packageDeal.attachments) ? packageDeal.attachments : [],
        },
        selectedElementType: 'packageDeal',
      };
    });
  }
}

export async function selectPackageDealAction(
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  packageDealId: string
): Promise<void> {
  const { packageDealListComponentState } = getState();
  const selectedPackageDeal = packageDealListComponentState.packageDeals.find(
    (o) => o.id === packageDealId
  );
  if (selectedPackageDeal) {
    await actionDispatch.exec(updateSelectedPackageDealDetailsAction, selectedPackageDeal);
    actionDispatch.reduce((initial): OperatorExpertViewState => {
      return {
        ...initial,
        detailsAndMessagesComponentState: {
          ...initial.detailsAndMessagesComponentState,
          selectPackageDealOrMessagesPanelTab: 'packageDeal',
        },
        selectedElementType: 'packageDeal',
      };
    });
  }
}

export function isAPackageDeal(entity: PackageDealDesc | PackageDeal): entity is PackageDeal {
  return isTruthy((entity as PackageDeal).packageDealDescId);
}

export function addPackageDealAndRecomputeExpressions(
  selectedElementType: SelectedElementType,
  initialKanban: Kanban,
  packageDealList: readonly PackageDeal[],
  packageDealToAdd: PackageDeal
): readonly PackageDeal[] {
  // We have to compute the whole new packageDealList and use a reduce instead of an
  // applyPayload because in the case where we are deleting a spare part from a package deal
  // that is notyet in the repository, the correct behavior is to delete if (instead of the
  // classic logical deletion) and the apply payload is not able to do that on array of objects
  let newPackageDeals: PackageDeal[];
  if (selectedElementType === 'packageDealDesc') {
    newPackageDeals = [...packageDealList, packageDealToAdd];
  } else {
    newPackageDeals = packageDealList.map((pd): PackageDeal => {
      if (pd.id === packageDealToAdd.id) {
        return packageDealToAdd;
      }
      return pd;
    });
  }

  const updatedKanban = {
    ...initialKanban,
    packageDeals: newPackageDeals,
  };

  const packageDeals = packageDealHelpers.updateAllPackageDealsExpressionComputations(
    updatedKanban,
    true
  );
  return packageDeals;
}

export function isPackageDealAlreadyPresentInKanban(
  existingPackageDeals: readonly PackageDeal[],
  packageDealToAdd: PackageDeal
): boolean {
  let isPresent = false;
  for (const pd of existingPackageDeals) {
    if (
      !pd.deleted &&
      packageDealToAdd.code === pd.code &&
      (packageDealToAdd.carElement?.category ?? 'MISC') === (pd.carElement?.category ?? 'MISC') &&
      (packageDealToAdd.carElement?.label ?? '') === (pd.carElement?.label ?? '')
    ) {
      isPresent = true;
      break;
    }
  }
  return isPresent;
}

function extractSparePartFromSparePartFormData(
  partFromForm: SparePartFormData,
  partsFromPkgDeal: readonly SparePart[],
  sparePartManagementType: string
): SparePart | undefined {
  const activePart = nonDeleted(partFromForm);
  const existingPart = partsFromPkgDeal.find((p) => p.id === partFromForm.id);

  // if part coming from form has been deleted we have to delete the one in the existing parts
  if (!activePart) {
    if (existingPart !== undefined) {
      return {
        ...existingPart,
        deleted: true,
      };
    }
    // The part in form has been deleted and there is no existing part
    // we have nothing to return
    return undefined;
  }

  // The existing part exists but has not been modified, we have nothing to do
  if (existingPart !== undefined && partFromForm.label === existingPart.label) {
    return existingPart;
  }
  return convertSparePartFormDataToSparePart(
    partFromForm,
    sparePartManagementType as SparePartManagementType
  );
}

function getSpareParts(
  partsFromForm: readonly SparePartFormData[],
  partsFromPkgDeal: readonly SparePart[],
  sparePartManagementType: string
): SparePart[] {
  const newParts: SparePart[] = [];

  // Transform the spare parts coming from the form
  partsFromForm.forEach((partFromForm) => {
    const convertedPart = extractSparePartFromSparePartFormData(
      partFromForm,
      partsFromPkgDeal,
      sparePartManagementType
    );
    if (convertedPart !== undefined) {
      newParts.push(convertedPart);
    }
  });

  // check if there are previous (deleted) spare parts that are not in the spare parts list
  const oldPartsNotInNewParts = partsFromPkgDeal.filter((partFromPdd) => {
    return newParts.find((newPart) => newPart.id === partFromPdd.id) === undefined;
  });
  newParts.push(...oldPartsNotInNewParts);

  return newParts;
}

export async function addOrUpdatePackageDealAction(
  {
    getState,
    actionDispatch,
    getGlobalState,
    httpClient,
  }: ActionContext<Store, OperatorExpertViewState>,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const {
    addOrUpdatePackageDealModaleState,
    selectedElementType,
    packageDealListComponentState,
    initialKanban,
  } = getState();
  const { packageDeal, forceAddDuplicatePD, formData, overridablePrice, computedPrice } =
    addOrUpdatePackageDealModaleState;
  const { contracts } = getGlobalState();
  const contract = nonnull(
    contractHelpers.findContractByCode(contracts, initialKanban.contract.code)
  );

  if (packageDeal) {
    if (selectedElementType === 'packageDealDesc' && !forceAddDuplicatePD) {
      const isPresent = isPackageDealAlreadyPresentInKanban(
        packageDealListComponentState.packageDeals,
        packageDeal
      );
      if (selectedElementType === 'packageDealDesc' && isPresent) {
        actionDispatch.reduce((initial) => {
          return {
            ...initial,
            addOrUpdatePackageDealModaleState: {
              ...initial.addOrUpdatePackageDealModaleState,
              openEditionModal: false,
              isForceAddPDDModalActive: true,
            },
          };
        });
        return;
      }
    }

    const { spareParts: sparePartsFromForm, newSparePartLabel } =
      getState().addOrUpdatePackageDealModaleState.formData;

    // Add a part for the "new" field even if the user did not click on +
    if (isTruthyAndNotEmpty(newSparePartLabel)) {
      actionDispatch.applyPayload({
        addOrUpdatePackageDealModaleState: {
          formData: {
            spareParts: [
              {
                ...EMPTY_SPARE_PART_FORM_DATA,
                id: httpClient.getBrowserSequence().next(),
                label: newSparePartLabel,
              },
            ],
          },
        },
      });
    }

    // Compute spare parts from the form and the potential existing spare parts
    const newSpareParts = getSpareParts(
      sparePartsFromForm,
      nonnull(packageDeal).spareParts,
      contract.sparePartManagementType
    );

    const newVariables: Record<string, PackageDealVariable> = {};
    forEachRecordValues(packageDeal.variables, (variable, key) => {
      const chosenValue = formData.variableValues[key];
      if (isBooleanVariable(variable) && typeof chosenValue === 'boolean') {
        newVariables[key] = {
          ...variable,
          value: chosenValue,
        };
      } else if (isNumericVariable(variable) && typeof chosenValue === 'number') {
        newVariables[key] = {
          ...variable,
          value: chosenValue,
        };
      } else if (isTextualVariable(variable) && typeof chosenValue === 'string') {
        newVariables[key] = {
          ...variable,
          value: chosenValue,
        };
      } else {
        throw Error('Variable defined type and value mismatch');
      }
    });

    let newPackageDeal: PackageDeal = {
      ...packageDeal,
      spareParts: newSpareParts,
      comment: formData.packageDealComment,
      estimateComment: formData.estimateComment,
      operations: packageDeal.operations,
      priceIsOverridden: false,
      variables: newVariables,
    };
    if (overridablePrice && formData.price !== String(computedPrice)) {
      newPackageDeal = {
        ...newPackageDeal,
        price: Number.parseFloat(formData.price),
        priceIsOverridden: true,
      };
    } else if (formData.noCost) {
      newPackageDeal = {
        ...newPackageDeal,
        price: 0,
        priceIsOverridden: true,
      };
    }

    const purchaseOrderId =
      formData.selectedPurchaseOrderId === NOT_ALLOCATED_PACKAGE_DEAL_PURCHASE_ORDER_ID
        ? undefined
        : formData.selectedPurchaseOrderId;
    newPackageDeal = {
      ...newPackageDeal,
      purchaseOrderId,
    };

    const packageDeals = addPackageDealAndRecomputeExpressions(
      selectedElementType,
      initialKanban,
      packageDealListComponentState.packageDeals,
      newPackageDeal
    );

    actionDispatch.reduce((initial): OperatorExpertViewState => {
      return {
        ...initial,
        selectedElementType: 'packageDeal',
        addOrUpdatePackageDealModaleState: ADD_OR_UPDATE_PACKAGE_DEAL_MODALE_EMPTY_STATE,
        packageDealListComponentState: {
          ...initial.packageDealListComponentState,
          packageDeals,
        },
      };
    });

    await actionDispatch.execCallback(updateStandRelatedPackageDealsAndOperationsActionCallback);
    await actionDispatch.exec(selectPackageDealAction, newPackageDeal.id);
  }
}

function deletePackageDeal(
  initialKanban: Kanban,
  packageDeals: readonly PackageDeal[],
  packageDealToRemoveId: string
): readonly PackageDeal[] {
  let newPackageDeals: readonly PackageDeal[];
  const initialPD = initialKanban.packageDeals.find((pd) => pd.id === packageDealToRemoveId);
  if (initialPD) {
    newPackageDeals = packageDeals.map((pd): PackageDeal => {
      if (pd.id === packageDealToRemoveId) {
        return { ...pd, deleted: true };
      }
      return pd;
    });
  } else {
    newPackageDeals = packageDeals.filter((pd) => pd.id !== packageDealToRemoveId);
  }
  newPackageDeals = packageDealHelpers.updateAllPackageDealsExpressionComputations(
    {
      ...initialKanban,
      packageDeals: newPackageDeals,
    },
    true
  );
  return newPackageDeals;
}

export async function deletePackageDealAction(
  { actionDispatch, getState }: ActionContext<Store, OperatorExpertViewState>,
  updateStandRelatedPackageDealsAndOperationsActionCallback: NoArgActionCallback<Store>
): Promise<void> {
  const { initialKanban, packageDealListComponentState, deletionModalState } = getState();
  const { packageDeals } = packageDealListComponentState;
  const { packageDealId } = deletionModalState;

  let newPackageDeals: readonly PackageDeal[];
  if (isTruthyAndNotEmpty(packageDealId)) {
    newPackageDeals = deletePackageDeal(initialKanban, packageDeals, packageDealId);
  }

  actionDispatch.reduce((initial): OperatorExpertViewState => {
    return {
      ...initial,
      detailsAndMessagesComponentState: {
        ...initial.detailsAndMessagesComponentState,
        selectedPackageDealId: '',
      },
      packageDealListComponentState: {
        ...initial.packageDealListComponentState,
        packageDeals: newPackageDeals,
      },
      deletionModalState: DELETION_MODAL_EMPTY_STATE,
    };
  });
  await actionDispatch.execCallback(updateStandRelatedPackageDealsAndOperationsActionCallback);
}

function isEmptyMemo(memo: Memo | undefined, isNAAllowed: boolean): boolean {
  if (!memo) {
    return true;
  }

  if (typeof memo.value === 'string') {
    return memo.value.trim() === '';
  }

  if (isNAAllowed && memo.value === null) {
    return false;
  }

  return !isTruthy(memo.value);
}

export function getExpertiseValidationErrors(
  kanban: Kanban,
  memoDescs: Record<CarViewCategory, MemoDesc[]>,
  standRelatedOperations: readonly Operation[],
  upperExpertiseTabLabels: Record<string, string>
): OperatorExpertViewValidationErrors {
  const mandatoryMemos: (MemoDesc & { category: string })[] = Object.entries(memoDescs).flatMap(
    ([key, descs]) =>
      (descs ?? [])
        .filter(({ additionalBehavior }) => additionalBehavior !== 'optional')
        .map((memoDesc) => ({ ...memoDesc, category: key }))
  );

  const memoErrors: string[] = mandatoryMemos
    .filter(({ id, additionalBehavior }) =>
      isEmptyMemo(kanban.memos[id], additionalBehavior === 'naAllowed')
    )
    .map(({ id, category }) => `${id} ${upperExpertiseTabLabels[category]}`);

  const operationsErrors: string[] = standRelatedOperations
    .filter(nonDeleted)
    .filter(({ completionDate }) => !completionDate)
    .map(({ label }) => label);

  const hasMultiplePurchaseOrders = purchaseOrderHelpers.hasMultiplePurchaseOrders(
    kanban.purchaseOrders
  );
  const purchaseOrdersErrors = hasMultiplePurchaseOrders
    ? kanban.packageDeals
        .filter(nonDeleted)
        .filter(({ purchaseOrderId, price }) => !purchaseOrderId && price !== 0)
        .map((packageDeal) => packageDealHelpers.getPackageDealDisplayedLabel(packageDeal))
    : [];

  return {
    OPERATIONS: operationsErrors,
    MEMOS: memoErrors,
    PURCHASE_ORDERS: purchaseOrdersErrors,
  };
}

export function replaceUpdatedOperationsInPackageDeals(
  packageDeals: readonly PackageDeal[],
  updatedOperations: readonly Operation[]
): readonly PackageDeal[] {
  return packageDeals.map((packageDeal) => {
    if (isTruthy(packageDeal.operations)) {
      return {
        ...packageDeal,
        operations: packageDeal.operations.map((operation) => {
          const updatedOperation = updatedOperations.find(({ id }) => id === operation.id);
          if (isTruthy(updatedOperation)) {
            return updatedOperation;
          }
          return operation;
        }),
      };
    }
    return packageDeal;
  });
}

export function updateStandRelatedPackageDealsAndOperationsAction({
  actionDispatch,
  getState,
}: ActionContext<Store, OperatorViewState>): void {
  const { packageDeals } = getState().expertOperatorState.packageDealListComponentState;

  const standRelatedPackageDeals = packageDealHelpers
    .getNotCancelledPackageDealsRelatedToStand(packageDeals, EXPERTISE_STAND_ID)
    .filter(({ recommendedByExpert, category }) => recommendedByExpert && category !== 'EXP');

  const previousStandRelatedOperations = getState().expertOperatorState.standRelatedOperations;

  const currentStandRelatedOperations = packageDealHelpers.getAllOperationsForStandId(
    standRelatedPackageDeals,
    EXPERTISE_STAND_ID
  );

  const removedOperationsIds = previousStandRelatedOperations
    .filter(({ id }) => !currentStandRelatedOperations.find((operation) => operation.id === id))
    .map(({ id }) => id);

  const mergedStandRelatedOperations = currentStandRelatedOperations.map((operation) => {
    const previousOperation = previousStandRelatedOperations.find(({ id }) => operation.id === id);
    if (previousOperation) return previousOperation;
    if (removedOperationsIds.includes(operation.id)) return { ...operation, deleted: true };
    return operation;
  });

  const standRelatedOperationsIds = mergedStandRelatedOperations.map(({ id }) => id);

  actionDispatch.reduce((initial) => ({
    ...initial,
    operationsToDoIds: standRelatedOperationsIds,
    generalOperatorState: {
      ...initial.generalOperatorState,
      standRelatedPackageDeals: replaceUpdatedOperationsInPackageDeals(
        standRelatedPackageDeals,
        mergedStandRelatedOperations
      ),
    },
    expertOperatorState: {
      ...initial.expertOperatorState,
      standRelatedOperations: mergedStandRelatedOperations,
    },
  }));
}

export function applyToggleOperationsPayloadForExpertise(
  { actionDispatch, getState }: ActionContext<Store, OperatorViewState>,
  _kanbanId: string,
  updatedPackageDeals: readonly DeepPartial<PackageDeal>[]
) {
  const { generalOperatorState, expertOperatorState } = getState();
  const { standRelatedPackageDeals } = generalOperatorState;
  const standRelatedPackageDealIds = standRelatedPackageDeals.map(({ id }) => id);

  const standRelatedOperationIds = expertOperatorState.standRelatedOperations.map(({ id }) => id);
  const operationPayloads: DeepPartial<Operation>[] = [];
  updatedPackageDeals.forEach(({ operations }) => {
    if (operations !== undefined) {
      operations.forEach((opPayload) => {
        if (opPayload.id !== undefined && standRelatedOperationIds.includes(opPayload.id)) {
          operationPayloads.push(opPayload);
        }
      });
    }
  });

  actionDispatch.applyPayload({
    generalOperatorState: {
      standRelatedPackageDeals: updatedPackageDeals.filter(
        ({ id }) => id !== undefined && standRelatedPackageDealIds.includes(id)
      ),
    },
    expertOperatorState: {
      standRelatedOperations: operationPayloads,
    },
  });
}
