import React, { useMemo } from 'react';
import type {
  CarViewCategory,
  ContractAttributeDesc,
  MemoDesc,
  StandType,
} from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { nonnull } from '@stimcar/libs-kernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import type { ApplyToggleOperationsPayloadAction } from '../../utils/subcontractor/SelectSubcontractorFirmModalDialog.js';
import type { OperatorViewState } from '../typings/store.js';
import { CommonOperatorWorkingArea } from './CommonOperatorWorkingArea.js';
import { ValidateExpertiseComponent } from './expertiseValidation/ValidateExpertiseComponent.js';
import { SparePartsReferenceComponent } from './spareparts/SparePartsReferenceComponent.js';

interface OperatorWorkingAreaProps extends AppProps<Store> {
  readonly standId: string;
  readonly memoDescs: Record<CarViewCategory, MemoDesc[]>;
  readonly attributeDescs: readonly ContractAttributeDesc[];
  // Optional callback used to overload the default behavior when operations status are toggled
  readonly applyToggleOperationsPayloadAction?: ApplyToggleOperationsPayloadAction<OperatorViewState>;
}

export function OperatorWorkingArea({
  $gs,
  standId,
  memoDescs,
  attributeDescs,
  applyToggleOperationsPayloadAction,
}: OperatorWorkingAreaProps): JSX.Element {
  const stands = useGetState($gs.$siteConfiguration.$stands);

  const standType = useMemo((): StandType | undefined => {
    const theStand = nonnull(stands.find((s) => s.id === standId));
    return theStand.type;
  }, [standId, stands]);

  const isInSparePartReference = standType === 'sparepartsReference';
  const isInExpertiseValidation = standType === 'expertiseValidation';
  const otherwise = !isInSparePartReference && !isInExpertiseValidation;

  return (
    <>
      {isInSparePartReference && <SparePartsReferenceComponent $gs={$gs} />}
      {isInExpertiseValidation && (
        <ValidateExpertiseComponent $gs={$gs} standId={standId} memoDescs={memoDescs} />
      )}
      {otherwise && (
        <CommonOperatorWorkingArea
          $gs={$gs}
          standId={standId}
          attributeDescs={attributeDescs}
          applyToggleOperationsPayloadAction={applyToggleOperationsPayloadAction}
        />
      )}
    </>
  );
}
