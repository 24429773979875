/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useMemo } from 'react';
import type { OperationDesc } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { FormFieldEntry, FormFieldProps } from '@stimcar/libs-uitoolkit';
import { nonDeleted, sortingHelpers } from '@stimcar/libs-base';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { Button, FormField, ListSelect } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { EditPackageDealDescModalState } from '../typings/store.js';
import { openOperationDescModalAction } from './EditOperationDescModal.js';

export interface OperationDescListFormFieldProps
  extends Omit<FormFieldProps, 'children' | 'warning'> {
  readonly operationDescs: readonly OperationDesc[];
  readonly $: StoreStateSelector<Store, EditPackageDealDescModalState>;
}

export function OperationDescListFormField({
  $,
  label,
  operationDescs,
  horizontal,
  noExclamationTriangleIfWarning,
}: OperationDescListFormFieldProps): JSX.Element {
  const selectedId = useGetState($.$selectedOdID);
  const dispatchWarning = useGetState($.$formData.$warnings.$operationDescs);

  const createNewOD = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(openOperationDescModalAction);
    },
    [],
    $
  );

  const editSelectedOD = useActionCallback(
    async ({ actionDispatch, getState }): Promise<void> => {
      const { formData, selectedOdID } = getState();
      const selectedOd = formData.operationDescs.find((od) => od.id === selectedOdID);
      await actionDispatch.exec(openOperationDescModalAction, selectedOd);
    },
    [],
    $
  );

  const moveSelectedODUp = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { formData, selectedOdID } = getState();
      const selectedOd = formData.operationDescs.find((od) => od.id === selectedOdID);
      if (selectedOd && selectedOd.orderIndex > 0) {
        const parentOD = formData.operationDescs.find(
          (od) => !od.deleted && od.orderIndex === selectedOd.orderIndex - 1
        );
        if (parentOD) {
          const newSelectedOD = { ...selectedOd, orderIndex: selectedOd.orderIndex - 1 };
          const newParentOD = { ...parentOD, orderIndex: parentOD.orderIndex + 1 };
          actionDispatch.applyPayload({
            formData: {
              operationDescs: [newSelectedOD, newParentOD],
            },
          });
        }
      }
    },
    [],
    $
  );

  const moveSelectedODDown = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { formData, selectedOdID } = getState();
      const selectedOd = formData.operationDescs.find((od) => od.id === selectedOdID);
      if (selectedOd && selectedOd.orderIndex < formData.operationDescs.length - 1) {
        const parentOD = formData.operationDescs.find(
          (od) => !od.deleted && od.orderIndex === selectedOd.orderIndex + 1
        );
        if (parentOD) {
          const newSelectedOD = { ...selectedOd, orderIndex: selectedOd.orderIndex + 1 };
          const newParentOD = { ...parentOD, orderIndex: parentOD.orderIndex - 1 };
          actionDispatch.applyPayload({
            formData: {
              operationDescs: [newSelectedOD, newParentOD],
            },
          });
        }
      }
    },
    [],
    $
  );

  const deleteSelectedOD = useActionCallback(
    ({ actionDispatch, getState }) => {
      const { formData, selectedOdID, initialPackageDealDesc } = getState();
      const initialOD = initialPackageDealDesc?.operationDescs.find((od) => od.id === selectedOdID);
      const deletedODIndex = formData.operationDescs.find(
        (od) => od.id === selectedOdID
      )?.orderIndex;

      let filteredOperationDescs: OperationDesc[];
      if (initialOD) {
        filteredOperationDescs = formData.operationDescs.map((od): OperationDesc => {
          if (od.id === initialOD.id) {
            return { ...od, deleted: true };
          }
          return od;
        });
      } else {
        filteredOperationDescs = formData.operationDescs.filter((od) => od.id !== selectedOdID);
      }

      const newOperationDescs = filteredOperationDescs.map((od): OperationDesc => {
        if (deletedODIndex && !od.deleted && od.orderIndex > deletedODIndex) {
          return { ...od, orderIndex: od.orderIndex - 1 };
        }
        return od;
      });

      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          selectedOdID: '',
          formData: {
            ...initial.formData,
            operationDescs: newOperationDescs,
          },
        };
      });
    },
    [],
    $
  );

  const orderOperationDescEntries = useMemo(() => {
    const orderedOperationDescs = operationDescs.filter(nonDeleted);
    const sortFunction = sortingHelpers.createSortByNumericField<OperationDesc>(
      'DOWN',
      'orderIndex'
    );
    return orderedOperationDescs.sort(sortFunction).map((od): FormFieldEntry<string> => {
      return { id: od.id, label: od.label };
    });
  }, [operationDescs]);

  const isAtListBottom = useMemo((): boolean => {
    const selectedOd = operationDescs.find((od) => od.id === selectedId);
    if (selectedOd && selectedOd.orderIndex === operationDescs.filter(nonDeleted).length - 1) {
      return true;
    }
    return false;
  }, [operationDescs, selectedId]);

  const isAtListTop = useMemo((): boolean => {
    const selectedOd = operationDescs.find((od) => od.id === selectedId);
    if (selectedOd && selectedOd.orderIndex === 0) {
      return true;
    }
    return false;
  }, [operationDescs, selectedId]);

  return (
    <FormField
      label={label}
      warning={dispatchWarning}
      horizontal={horizontal}
      noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
    >
      <div className="columns p-sx">
        <div className="column is-narrow">
          <div className="m-b-sm">
            <Button onClick={createNewOD} iconId="plus-square" tooltip="create" size="small" />
          </div>
          <div className="m-b-sm">
            <Button
              onClick={editSelectedOD}
              iconId="edit"
              tooltip="edit"
              size="small"
              disabled={selectedId === ''}
            />
          </div>
          <div className="m-b-sm">
            <Button
              onClick={deleteSelectedOD}
              iconId="trash"
              tooltip="up"
              size="small"
              disabled={selectedId === ''}
            />
          </div>
        </div>
        <div className="column">
          <ListSelect
            $={$.$selectedOdID}
            entries={orderOperationDescEntries}
            height={140}
            hasBorder
          />
        </div>
        <div className="column is-narrow">
          <div className="m-b-sm">
            <Button
              onClick={moveSelectedODUp}
              iconId="chevron-up"
              tooltip="up"
              size="small"
              disabled={isAtListTop}
            />
          </div>
          <div className="m-b-sm">
            <Button
              onClick={moveSelectedODDown}
              iconId="chevron-down"
              tooltip="down"
              size="small"
              disabled={isAtListBottom}
            />
          </div>
        </div>
      </div>
    </FormField>
  );
}
