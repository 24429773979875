import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';

export type SelectSubcontractorFirmForm = {
  readonly realizedBy: 'stimcar' | 'subcontractor';
  readonly subcontractor: string;
};

export type SelectSubcontractorFirmModalState =
  FormWithValidationState<SelectSubcontractorFirmForm> & {
    readonly active: boolean;
    readonly operationId: string;
    readonly subcontractors: readonly string[];
  };

export const EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE: SelectSubcontractorFirmModalState = {
  active: false,
  subcontractors: [],
  operationId: '',
  formData: {
    realizedBy: 'subcontractor',
    subcontractor: '',
    warnings: {},
  },
  formSubmitClickedOnce: false,
  formSubmitted: false,
  formWarning: undefined,
};
