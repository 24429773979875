import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Operation, PackageDeal } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type {
  ActionCallbackFromFunction,
  ActionContext,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import type { ChoiceButtonDef, HorizontalFormFieldProps } from '@stimcar/libs-uitoolkit';
import { packageDealHelpers, STIMCAR_FIRM } from '@stimcar/libs-base';
import { isTruthy, keysOf } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  ChoiceAddOnButtonsFormField,
  ModalCardDialog,
  RadioButtonsFormField,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  SelectSubcontractorFirmForm,
  SelectSubcontractorFirmModalState,
} from './typings/store.js';
import { EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE } from './typings/store.js';

export const STIMCAR_FAKE_LOGIN = '$STIMCAR$';

// Default implementation
function getPackageDealWithOperationToggled(
  packageDeal: PackageDeal,
  operationId: string,
  login: string | null,
  subcontractor: string | null
): DeepPartial<PackageDeal> {
  const operation = packageDeal.operations.find(({ id }) => id === operationId)!;
  const isOperationCompleted = isTruthy(operation.completionDate);
  const updatedOperation: DeepPartial<Operation> = {
    id: operationId,
    user: isOperationCompleted ? null : login,
    completionDate: isOperationCompleted ? null : Date.now(),
    // Only append the subcontractot if it is set or if the key is already
    // present in the object
    ...(typeof subcontractor === 'string' || keysOf(operation).includes('subcontractor')
      ? { subcontractor }
      : {}),
  };
  const updatedPackageDeal: DeepPartial<PackageDeal> = {
    id: packageDeal.id,
    operations: [updatedOperation],
    status: packageDeal.status,
  };
  return updatedPackageDeal;
}

export type ApplyToggleOperationsPayloadAction<
  S extends SelectSubcontractorFirmModalStateContainer,
> = (
  ctx: ActionContext<Store, S>,
  kanbanId: string,
  updatedPackageDeals: readonly DeepPartial<PackageDeal>[]
) => Promise<void> | void;

export async function toggleOperationOrOpenSelectSubcontractorFirmModalAction<
  S extends SelectSubcontractorFirmModalStateContainer,
>(
  { actionDispatch, getGlobalState }: ActionContext<Store, S>,
  kanbanId: string,
  packageDeals: readonly PackageDeal[],
  operationId: string,
  applyToggleOperationPayloadAction: ApplyToggleOperationsPayloadAction<S>,
  subcontractableInfo?: SubcontractableInfo
) {
  // Find package deal & operation
  const flatOperations = packageDealHelpers.getFlatOperationList(packageDeals, 'achievable', true);
  const flatOperation = flatOperations.find(({ id }) => id === operationId)!;
  const packageDeal = packageDeals.find(({ id }) => id === flatOperation.packageDealId)!;
  if (
    subcontractableInfo === undefined &&
    !isTruthy(flatOperation.completionDate) &&
    packageDeal.isSubcontractable
  ) {
    // Load subcontractors
    const { subcontractors } = getGlobalState().siteConfiguration;
    // open the modal
    actionDispatch.scopeProperty('selectSubcontractorFirmModal').setValue({
      ...EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
      subcontractors,
      operationId,
      active: true,
    });
  }

  // Otherwise apply the modifications
  else {
    const { login } = getGlobalState().session.user!;
    const packageDealWithOperationToggled = getPackageDealWithOperationToggled(
      packageDeal,
      operationId,
      login,
      subcontractableInfo && subcontractableInfo.isSubcontracted
        ? subcontractableInfo.subcontractor
        : null
    );

    // Apply the payload (by default in the repository, see DEFAULT_APPLY_TOGGLE_OPERATIONS_PAYLOAD)
    await actionDispatch.exec(applyToggleOperationPayloadAction, kanbanId, [
      packageDealWithOperationToggled,
    ]);
  }
}

function mandatoryFieldsProvider({
  realizedBy,
}: SelectSubcontractorFirmForm): readonly (keyof SelectSubcontractorFirmForm)[] {
  if (realizedBy === STIMCAR_FIRM) {
    return [];
  }
  return ['subcontractor'];
}

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  labelFlexGrow: 1,
  bodyFlexGrow: 3,
};

export type SelectSubcontractorFirmModalStateContainer = {
  readonly selectSubcontractorFirmModal: SelectSubcontractorFirmModalState;
};

export type SubcontractableInfo =
  | {
      readonly isSubcontracted: true;
      readonly subcontractor: string;
    }
  | {
      readonly isSubcontracted: false;
    };

interface Props {
  readonly $: StoreStateSelector<Store, SelectSubcontractorFirmModalStateContainer>;
  readonly toggleOperationActionCallback: ActionCallbackFromFunction<
    Store,
    (operationId: string, subcontractorInfos: SubcontractableInfo) => Promise<void>
  >;
}

export function SelectSubcontractorFirmModalDialog({
  $,
  toggleOperationActionCallback,
}: Props): JSX.Element {
  const [t] = useTranslation('operators');

  const submitValidDataActionCallback = useActionCallback(
    async function submitValidDataAction({ actionDispatch, getState }) {
      const { formData, operationId } = getState().selectSubcontractorFirmModal;
      const { realizedBy, subcontractor } = formData;
      await actionDispatch.execCallback(
        toggleOperationActionCallback,
        operationId,
        realizedBy === STIMCAR_FIRM
          ? { isSubcontracted: false }
          : { isSubcontracted: true, subcontractor }
      );
      // Close the dialog
      actionDispatch.applyPayload({
        selectSubcontractorFirmModal: {
          active: false,
        },
      });
    },
    [toggleOperationActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    SelectSubcontractorFirmModalState
  >({
    $: $.$selectSubcontractorFirmModal,
    mandatoryFields: mandatoryFieldsProvider,
    submitValidDataAction: submitValidDataActionCallback,
    t,
  });

  const realizedByOptions = useMemo((): readonly ChoiceButtonDef[] => {
    return [STIMCAR_FIRM, 'subcontractor'].map((id) => ({
      id,
      label: t(`selectSubcontractorFirmModal.form.realizedByOptions.${id}`),
    }));
  }, [t]);

  const realizedBy = useGetState($formDataWithChangeTrigger.$realizedBy);

  const subcontractors = useGetState($.$selectSubcontractorFirmModal.$subcontractors);

  const clearSubcontractorActionCallback = useActionCallback(
    ({ actionDispatch, getState }) => {
      if (getState().realizedBy === STIMCAR_FIRM) {
        actionDispatch.setProperty('subcontractor', '');
      }
    },
    [],
    $formDataWithChangeTrigger
  );

  const $realizedByWithActionTrigger = useSelectorWithChangeTrigger(
    $formDataWithChangeTrigger.$realizedBy,
    clearSubcontractorActionCallback
  );

  return (
    <ModalCardDialog
      $active={$.$selectSubcontractorFirmModal.$active}
      title={t('selectSubcontractorFirmModal.title')}
      onOkClicked={onFormSubmit}
    >
      <p>{t('selectSubcontractorFirmModal.rationale')}</p>
      <ChoiceAddOnButtonsFormField
        $={$realizedByWithActionTrigger}
        label={t('selectSubcontractorFirmModal.form.realizedBy')}
        buttonsDesc={realizedByOptions}
        horizontal={HORIZONTAL_PROPS}
      />
      <RadioButtonsFormField
        id="login"
        $={$formDataWithChangeTrigger.$subcontractor}
        label={t('selectSubcontractorFirmModal.form.subcontractor')}
        entries={subcontractors}
        radioGroupLayout="vertical"
        disabled={realizedBy === STIMCAR_FIRM}
        horizontal={HORIZONTAL_PROPS}
      />
    </ModalCardDialog>
  );
}
