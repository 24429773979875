import i18next from 'i18next';
import React, { Fragment, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import type { RepositoryHTTPClient } from '@stimcar/core-libs-repository';
import type {
  AuthenticatedUser,
  Kanban,
  Operation,
  PackageDeal,
  PackageDealStatus,
  SiteConfiguration,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, ButtonProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  CUSTOMER_VALIDATION_STAND_ID,
  expertiseHelpers,
  kanbanHelpers,
  nonDeleted,
  packageDealHelpers,
  transverseHelpers,
  workflowProgressHelpers,
} from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Button,
  HORIZONTAL_FORM_SMALL_LABEL,
  InputFormField,
  ModalCardDialog,
  useFormWithValidation,
} from '@stimcar/libs-uitoolkit';
import type { KanbanCancellationModalState } from '../details/typings/store.js';
import type { Store } from '../state/typings/store.js';
import { EMPTY_KANBAN_CANCELLATION_MODAL_STATE } from '../details/typings/store.js';

type KanbanCancellationProps = AppProps<Store> &
  Pick<ButtonProps, 'isFullWidth'> & {
    readonly kanban: Kanban;
    readonly $: StoreStateSelector<Store, KanbanCancellationModalState>;
  };

function getActivePackageDealsWithActiveOperations(
  packageDeals: readonly PackageDeal[]
): readonly PackageDeal[] {
  return packageDeals.filter(nonDeleted).map((packageDeal) => ({
    ...packageDeal,
    operations: packageDeal.operations.filter(nonDeleted),
  }));
}

function getPackageDealsToCharge(packageDeals: readonly PackageDeal[]): readonly PackageDeal[] {
  const activePackageDealsWithActiveOperations = getActivePackageDealsWithActiveOperations(
    packageDeals
  ).filter((packageDeal) => packageDeal.status !== 'canceled');

  const packagesDealsToKeep = activePackageDealsWithActiveOperations.filter(
    ({ operations }) =>
      operations.length === 0 ||
      operations.some(({ completionDate }) => isTruthy(completionDate)) ||
      hasOperationsThatWillBeAutomaticallyHandled(operations)
  );

  const packageDealsWithAvailableStatus = packagesDealsToKeep.map((packageDeal) => ({
    ...packageDeal,
    status: 'available' as PackageDealStatus,
  }));

  return packageDealsWithAvailableStatus;
}

function hasOperationsThatWillBeAutomaticallyHandled(operations: readonly Operation[]) {
  return (
    operations.find(({ type }) => type === 'CreateInvoice') ||
    operations.find(({ type }) => type === 'Delivery')
  );
}

function openKanbanCancellationDialogAction(
  { actionDispatch }: ActionContext<Store, KanbanCancellationModalState>,
  kanban: Kanban
) {
  const packageDealsToCharge = getPackageDealsToCharge(kanban.packageDeals);

  const packageDealsWithSparePartsNotReceived = packageDealsToCharge.filter((packageDeal) =>
    packageDeal.spareParts
      .filter(nonDeleted)
      .filter((sparePart) => sparePart.managementType !== 'fullyManagedByCustomer')
      .some(({ dateOfOrder, dateOfReception }) => isTruthy(dateOfOrder) && !dateOfReception)
  );
  if (packageDealsWithSparePartsNotReceived.length) {
    actionDispatch.applyPayload({
      ...EMPTY_KANBAN_CANCELLATION_MODAL_STATE,
      notAllowedDialog: {
        active: true,
      },
    });
    return;
  }

  const startedButNotFinishedPackageDeals = packageDealsToCharge.filter(
    ({ operations }) =>
      operations.some(({ completionDate, type }) => type !== 'Validation' && !completionDate) &&
      !hasOperationsThatWillBeAutomaticallyHandled(operations)
  );
  if (startedButNotFinishedPackageDeals.length) {
    const blockingPackageDeals = startedButNotFinishedPackageDeals.map((packageDeal) => {
      const sortedOperations = [...packageDeal.operations].sort((a) => (a.completionDate ? 1 : -1));
      return {
        ...packageDeal,
        operations: sortedOperations,
      };
    });

    actionDispatch.applyPayload({
      ...EMPTY_KANBAN_CANCELLATION_MODAL_STATE,
      notAllowedDialog: {
        active: true,
      },
      blockingPackageDeals,
      packageDealsToCharge,
    });
    return;
  }

  actionDispatch.applyPayload({
    ...EMPTY_KANBAN_CANCELLATION_MODAL_STATE,
    reasonDialog: {
      active: true,
    },
    packageDealsToCharge,
  });
}

function openKanbanCancellationConfirmDialogAction(
  { actionDispatch, getGlobalState }: ActionContext<Store, KanbanCancellationModalState>,
  kanban: Kanban,
  packageDealsToCharge: readonly PackageDeal[],
  blockingPackageDeals: readonly PackageDeal[] | undefined
) {
  const packageDealsWithAllOperationsCompleted = packageDealsToCharge.map((packageDeal) => {
    const isBlockingPackageDeal = blockingPackageDeals?.find(({ id }) => packageDeal.id === id);
    if (isBlockingPackageDeal) {
      return {
        ...packageDeal,
        operations: packageDeal.operations.map((operation) => {
          if (operation.completionDate) {
            return operation;
          }
          return {
            ...operation,
            completionDate: Date.now(),
            user: i18next.t('details:actions.kanbanCancellation.userForTaskCompletion', {
              user: getGlobalState().session.user?.login,
            }),
          };
        }),
      };
    }
    return packageDeal;
  });

  const updatedPackageDealsToCharge =
    packageDealHelpers.updateAllPackageDealsExpressionComputations({
      ...kanban,
      packageDeals: packageDealsWithAllOperationsCompleted,
    });

  const totalPriceToChargeWithoutVat =
    packageDealHelpers.getInvoiceablePriceOperationsAndSpareParts(updatedPackageDealsToCharge);
  const vat = transverseHelpers.getVATFromPrice(totalPriceToChargeWithoutVat);
  const totalPriceToCharge = transverseHelpers.addVATToGivenPrice(totalPriceToChargeWithoutVat);

  actionDispatch.applyPayload({
    reasonDialog: {
      active: false,
    },
    confirmDialog: {
      active: true,
    },
    packageDealsToCharge: updatedPackageDealsToCharge,
    totalPriceToChargeWithoutVat,
    vat,
    totalPriceToCharge,
  });
}

function isKanbanCancellationButtonEnabled(
  configuration: SiteConfiguration,
  kanban: Kanban
): boolean {
  const workflow = configuration.workflows.find((w) => w.id === kanban.workflowId);
  if (workflow) {
    const standId = workflowProgressHelpers.computeProgress(workflow.definition, kanban);
    if (standId.expectedStandIds.includes(CUSTOMER_VALIDATION_STAND_ID)) {
      return true;
    }
  }
  return false;
}

async function kanbanCancellation(
  kanban: Kanban,
  reason: string,
  packageDealsToCharge: readonly PackageDeal[],
  httpClient: RepositoryHTTPClient,
  { firstName, lastName, login }: AuthenticatedUser
): Promise<boolean> {
  const kanbanWithActivePackageDealsAndOperations = {
    ...kanban,
    packageDeals: getActivePackageDealsWithActiveOperations(kanban.packageDeals),
  };

  const updatedPackageDeals = kanbanWithActivePackageDealsAndOperations.packageDeals.map(
    (packageDeal) => {
      const packageDealToCharge = packageDealsToCharge.find(({ id }) => packageDeal.id === id);
      if (packageDealToCharge) {
        return packageDealToCharge;
      }
      return { ...packageDeal, status: 'canceled' as PackageDealStatus };
    }
  );

  const messages = [
    ...kanbanWithActivePackageDealsAndOperations.messages,
    kanbanHelpers.createKanbanComment(
      httpClient.getBrowserSequence().next(),
      login,
      i18next.t('details:actions.kanbanCancellation.confirmationLog', {
        firstName,
        lastName,
        reason,
      })
    ),
  ];

  const updatedKanban = {
    ...kanbanWithActivePackageDealsAndOperations,
    packageDeals: updatedPackageDeals,
    messages,
  };

  const totalRefurbishRevenueWithoutVAT = kanbanHelpers.computeAvailableRevenue(
    updatedKanban.packageDeals,
    'all',
    false
  );

  const availablePackageDealIds = updatedKanban.packageDeals
    .filter((packageDeal) => packageDeal.status === 'available')
    .map((packageDeal) => packageDeal.id);

  const canceledPackageDealIds = expertiseHelpers.deduceCanceledPackageDealIdListFromAvailableOnes(
    updatedKanban.packageDeals,
    availablePackageDealIds
  );

  await httpClient.httpPostAsJSON(
    CoreBackendRoutes.KANBAN_CANCELLATION('kanban'),
    {
      initialKanban: kanbanWithActivePackageDealsAndOperations,
      updatedKanban,
      additionalDataForCustomerAction: {
        availablePackageDealIds,
        canceledPackageDealIds,
        totalRefurbishRevenueWithoutVAT,
      },
    },
    'POST'
  );

  return true;
}

async function kanbanCancellationAction(
  {
    getState,
    actionDispatch,
    httpClient,
    globalActionDispatch,
    getGlobalState,
  }: ActionContext<Store, KanbanCancellationModalState>,
  kanban: Kanban,
  packageDealsToCharge: readonly PackageDeal[]
): Promise<void> {
  const { reason } = getState().formData;
  const success = await kanbanCancellation(
    kanban,
    reason,
    packageDealsToCharge,
    httpClient,
    nonnull(getGlobalState().session.user)
  );
  actionDispatch.applyPayload(EMPTY_KANBAN_CANCELLATION_MODAL_STATE);
  if (success) {
    const { license } = kanban.infos;
    const successMessage = i18next.t('details:actions.kanbanCancellation.confirmationMessage', {
      license,
    });
    globalActionDispatch.setProperty('message', successMessage);
  }
}

export function KanbanCancellationButton({
  kanban,
  $,
  $gs,
  ...props
}: KanbanCancellationProps): JSX.Element {
  const [t] = useTranslation('details');
  const configuration = useGetState($gs.$siteConfiguration);

  const isButtonDisabled = useMemo(
    () => !isKanbanCancellationButtonEnabled(configuration, kanban),
    [configuration, kanban]
  );

  const revokeButtonTooltip = useMemo(
    () =>
      isButtonDisabled
        ? t('actions.kanbanCancellation.tooltipNotClickableButton')
        : t('actions.kanbanCancellation.tooltipClickableButton'),
    [t, isButtonDisabled]
  );

  const openDialog = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openKanbanCancellationDialogAction, kanban);
    },
    [kanban],
    $
  );

  return (
    <>
      <Button
        {...props}
        iconId="hand"
        onClick={openDialog}
        disabled={isButtonDisabled}
        tooltip={revokeButtonTooltip}
        label={t('actions.kanbanCancellation.shortButtonLabel')}
      />
      <KanbanCancellationNotAllowedDialog $={$} />
      <KanbanCancellationReasonDialog $={$} kanban={kanban} />
      <KanbanCancellationConfirmationDialog $={$} kanban={kanban} />
    </>
  );
}

interface KanbanCancellationNotAllowedDialogProps {
  $: StoreStateSelector<Store, KanbanCancellationModalState>;
}

function KanbanCancellationNotAllowedDialog({
  $,
}: KanbanCancellationNotAllowedDialogProps): JSX.Element {
  const [t] = useTranslation('details');
  const blockingPackageDeals = useGetState($.$blockingPackageDeals);
  const packageDealsToCharge = useGetState($.$packageDealsToCharge);

  const continueKanbanCancellation = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(() =>
        actionDispatch.applyPayload({
          ...EMPTY_KANBAN_CANCELLATION_MODAL_STATE,
          reasonDialog: {
            active: true,
          },
          packageDealsToCharge,
        })
      );
    },
    [packageDealsToCharge],
    $
  );

  return (
    <ModalCardDialog
      titleIconId="exclamation-triangle"
      title={t('actions.kanbanCancellation.dialogTitle')}
      okLabel={t(
        `actions.kanbanCancellation.${blockingPackageDeals ? 'understoodAndContinue' : 'understood'}`
      )}
      $active={$.$notAllowedDialog.$active}
      onOkClicked={blockingPackageDeals ? continueKanbanCancellation : undefined}
    >
      {blockingPackageDeals ? (
        <p className="has-text-left mb-5">
          {t('actions.kanbanCancellation.packageDealsHaveBeenStarted')}
        </p>
      ) : (
        <p className="has-text-left">
          {t('actions.kanbanCancellation.sparePartsNotDeliveredForPackageDeals')}
        </p>
      )}
      {blockingPackageDeals?.map((packageDeal, index) => (
        <details key={packageDeal.id} className="has-text-left mb-3" open={index === 0}>
          <summary className="mb-1 has-text-weight-bold">
            {t('actions.kanbanCancellation.packageDealWithPrice', {
              packageDealLabel: packageDealHelpers.getPackageDealDisplayedLabel({
                label: packageDeal.label,
                variables: packageDeal.variables,
              }),
              price: packageDealHelpers.getTotalPrice(packageDeal, 'received'),
            })}
          </summary>
          {packageDeal.operations.map((operation) => (
            <div key={operation.id} className="ml-3">
              <i
                className={`far ${operation.completionDate ? 'fa-check-square' : 'fa-square'}  mr-3`}
              />
              {`${packageDealHelpers.getOperationDisplayedLabel(operation, packageDeal.variables)}`}
            </div>
          ))}
        </details>
      ))}
      {blockingPackageDeals && (
        <p className="p-2 mb-0 notification is-danger is-light has-text-justified">
          {t('actions.kanbanCancellation.operationsWillBeCompleted')}
        </p>
      )}
    </ModalCardDialog>
  );
}

interface KanbanCancellationReasonDialogProps {
  kanban: Kanban;
  $: StoreStateSelector<Store, KanbanCancellationModalState>;
}

function KanbanCancellationReasonDialog({
  kanban,
  $,
}: KanbanCancellationReasonDialogProps): JSX.Element {
  const [t] = useTranslation('details');
  const blockingPackageDeals = useGetState($.$blockingPackageDeals);
  const packageDealsToCharge = useGetState($.$packageDealsToCharge);

  const submitReturnReasonAction = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openKanbanCancellationConfirmDialogAction,
        kanban,
        packageDealsToCharge,
        blockingPackageDeals
      );
    },
    [kanban, packageDealsToCharge, blockingPackageDeals],
    $
  );
  const [onFormSubmit, , $formWithChangeTrigger] = useFormWithValidation<
    Store,
    KanbanCancellationModalState
  >({
    $,
    mandatoryFields: ['reason'],
    submitValidDataAction: submitReturnReasonAction,
    t,
  });

  return (
    <ModalCardDialog
      titleIconId="trash"
      onOkClicked={onFormSubmit}
      $active={$.$reasonDialog.$active}
      warning={useGetState($.$formWarning)}
      title={t('actions.kanbanCancellation.dialogTitle')}
      okLabel={t('actions.kanbanCancellation.buttonLabel')}
    >
      {t('actions.kanbanCancellation.dialogMessage', { license: kanban.infos.license })}
      <div className="m-t-md">
        <InputFormField
          type="text"
          $={$formWithChangeTrigger.$reason}
          label={t('actions.kanbanCancellation.reason')}
          placeholder={t('actions.kanbanCancellation.reason')}
          horizontal={HORIZONTAL_FORM_SMALL_LABEL}
        />
      </div>
    </ModalCardDialog>
  );
}

interface KanbanCancellationConfirmationDialogProps {
  kanban: Kanban;
  $: StoreStateSelector<Store, KanbanCancellationModalState>;
}

function KanbanCancellationConfirmationDialog({
  kanban,
  $,
}: KanbanCancellationConfirmationDialogProps): JSX.Element {
  const [t] = useTranslation('details');

  const { packageDealsToCharge, totalPriceToChargeWithoutVat, vat, totalPriceToCharge } =
    useGetState($);

  const kanbanCancellationActionHandler = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(kanbanCancellationAction, kanban, packageDealsToCharge);
    },
    [kanban, packageDealsToCharge],
    $
  );

  return (
    <ModalCardDialog
      titleIconId="circle-question"
      $active={$.$confirmDialog.$active}
      title={t('actions.kanbanCancellation.dialogTitle')}
      onOkClicked={kanbanCancellationActionHandler}
    >
      <p className="has-text-left">
        {t('actions.kanbanCancellation.confirmationQuestion', { license: kanban.infos.license })}
      </p>
      <p className="has-text-left">
        <Trans
          i18nKey={t('actions.kanbanCancellation.priceThatWillBeChargedToCustomer', {
            price: totalPriceToCharge,
          })}
        />
      </p>
      <table className="table is-bordered has-text-left is-narrow">
        <thead>
          <tr>
            <th>{t('actions.kanbanCancellation.invoiceRowName')}</th>
            <th>{t('actions.kanbanCancellation.invoiceRowQuantity')}</th>
            <th>{t('actions.kanbanCancellation.invoiceRowPrice')}</th>
          </tr>
        </thead>
        <tbody>
          {packageDealsToCharge?.map((packageDeal) => (
            <Fragment key={packageDeal.id}>
              <tr>
                <td>
                  {packageDealHelpers.getPackageDealDisplayedLabel({
                    label: packageDeal.label,
                    variables: packageDeal.variables,
                  })}
                </td>
                <td aria-label="Quantity : 1" className="has-text-right" />
                <td className="has-text-right">
                  {t('actions.kanbanCancellation.formattedPrice', {
                    price: packageDeal.price,
                  })}
                </td>
              </tr>
              {packageDeal.spareParts.map((sparePart) => (
                <tr key={sparePart.id}>
                  <td>
                    {`- ${packageDealHelpers.getSparePartDisplayedLabel(sparePart, packageDeal)}`}
                  </td>
                  <td className="has-text-right">{sparePart.quantity}</td>
                  <td className="has-text-right">
                    {t('actions.kanbanCancellation.formattedPrice', { price: sparePart.price })}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
          <tr className="has-text-right has-text-weight-bold">
            <td colSpan={2}>{t('actions.kanbanCancellation.totalWithoutVat')}</td>
            <td>
              {t('actions.kanbanCancellation.formattedPrice', {
                price: totalPriceToChargeWithoutVat,
              })}
            </td>
          </tr>
          <tr className="has-text-right has-text-weight-bold">
            <td colSpan={2}>{t('actions.kanbanCancellation.vat')}</td>
            <td>{t('actions.kanbanCancellation.formattedPrice', { price: vat })}</td>
          </tr>
          <tr className="has-text-right has-text-weight-bold">
            <td colSpan={2}>{t('actions.kanbanCancellation.totalWithVat')}</td>
            <td>{t('actions.kanbanCancellation.formattedPrice', { price: totalPriceToCharge })}</td>
          </tr>
        </tbody>
      </table>
    </ModalCardDialog>
  );
}
