import React from 'react';
import type { Kanban } from '@stimcar/libs-base';
import { KANBAN_ATTRIBUTES } from '@stimcar/libs-base';
import { FaIcon } from '@stimcar/libs-uitoolkit';

interface MarketplaceIconProps {
  readonly kanban: Kanban;
}

export function MarketplaceIcon({ kanban }: MarketplaceIconProps): JSX.Element {
  const ownerSellingPrice = kanban.attributes[KANBAN_ATTRIBUTES.MKTP_OWNER_SELLING_PRICE];
  return <>{ownerSellingPrice && <FaIcon id="globe" additionalClass="has-text-primary" />}</>;
}
