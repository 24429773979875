import type { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { CarViewCategory, Kanban, MemoDesc } from '@stimcar/libs-base';
import type { ActionContext } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  expertiseHelpers,
  globalHelpers,
  kanbanHelpers,
  packageDealHelpers,
} from '@stimcar/libs-base';
import { applyPayload, computePayload, isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { MemosBloc, ModalCardDialog } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';
import type { OperatorViewState, ValidateExpertiseComponentState } from '../../typings/store.js';
import { addValidationOperation } from '../../expertiseUtils.js';
import { validateExpertiseAndStopWork } from '../../validateExpertiseUtils.js';
import { ValidationPackageDealListComponent } from './ValidationPackageDealListComponent.js';

async function validateExpertiseAndStopWorkAction(
  { actionDispatch, getState }: ActionContext<Store, OperatorViewState>,
  t: TFunction
): Promise<void> {
  const { operatedKanban } = getState();
  const { standId } = getState().validateExpertiseViewState;
  await actionDispatch.exec(validateExpertiseAndStopWork, t, nonnull(operatedKanban), standId);
}

export function updateExpertiseValidationAction(
  { actionDispatch, getState }: ActionContext<Store, ValidateExpertiseComponentState>,
  kanban: Kanban
): void {
  const { packageDealsWorkingCopy, kanbanId } = getState();
  if (isTruthy(kanbanId) && kanbanId === kanban.id) {
    const actualPayload = computePayload(kanban.packageDeals, packageDealsWorkingCopy);
    let newPackageDeals = applyPayload(kanban.packageDeals, actualPayload);
    newPackageDeals = packageDealHelpers.updateAllPackageDealsExpressionComputations({
      ...kanban,
      packageDeals: newPackageDeals,
    });

    actionDispatch.reduce((initial): ValidateExpertiseComponentState => {
      return {
        ...initial,
        packageDealsWorkingCopy: newPackageDeals,
      };
    });
  }
}

async function initValidateExpertiseComponentState(
  {
    actionDispatch,
    packageDealDescRepository,
    carElementRepository,
    getState,
    httpClient,
    getGlobalState,
  }: ActionContext<Store, OperatorViewState>,
  standId: string
): Promise<void> {
  const { operatedKanban } = getState();
  if (operatedKanban === undefined) {
    return;
  }

  const { siteConfiguration } = getGlobalState();
  const { packageDealDatabase } = operatedKanban.contract;

  const carElements = await carElementRepository.getAllEntities();
  const packageDealDescs = await packageDealDescRepository.getEntitiesFromIndex(
    PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
    packageDealDatabase
  );

  const kanbanWithValidationOperation = addValidationOperation(
    httpClient.getBrowserSequence(),
    operatedKanban,
    siteConfiguration
  );

  const selectedPackageDealIds =
    expertiseHelpers.deduceAvailablePackageDealsIdsFromActualStatusOrExpertRecommendation(
      kanbanWithValidationOperation.packageDeals
    );
  const canceledPackageDealIds = expertiseHelpers.deduceCanceledPackageDealIdListFromAvailableOnes(
    kanbanWithValidationOperation.packageDeals,
    selectedPackageDealIds
  );
  const packageDealsWorkingCopy = kanbanHelpers.applyStatusOnAllPackageDeals(
    kanbanWithValidationOperation,
    selectedPackageDealIds,
    canceledPackageDealIds,
    false,
    null
  );

  actionDispatch
    .scopeProperty('validateExpertiseViewState')
    .reduce((initial): ValidateExpertiseComponentState => {
      return {
        ...initial,
        kanbanId: operatedKanban.id,
        standId,
        carElements,
        packageDealDescs,
        packageDealsWorkingCopy,
      };
    });
}

interface ValidateExpertiseComponentProps extends AppProps<Store> {
  readonly standId: string;
  readonly memoDescs: Record<CarViewCategory, MemoDesc[]>;
}

export function ValidateExpertiseComponent({
  standId,
  memoDescs,
  $gs,
}: ValidateExpertiseComponentProps): JSX.Element {
  const [t] = useTranslation('operators');
  const { $operatorView } = $gs;

  const initValidateExpertiseComponentStateCallback = useActionCallback(
    initValidateExpertiseComponentState,
    [],
    $operatorView
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initValidateExpertiseComponentStateCallback(standId);
  }, [initValidateExpertiseComponentStateCallback, standId]);

  const { $validateExpertiseViewState } = $operatorView;

  const packageDealsWorkingCopy = useGetState($validateExpertiseViewState.$packageDealsWorkingCopy);
  const operatedKanban = useGetState($operatorView.$operatedKanban);
  const kanban = nonnull(operatedKanban);

  const availableCost = useMemo(() => {
    let available = 0;
    packageDealsWorkingCopy.forEach((pd) => {
      if (!pd.deleted) {
        if (packageDealHelpers.isPackageDealAvailable(pd)) {
          available += packageDealHelpers.getTotalPrice(pd, 'all');
        }
      }
    });
    return available;
  }, [packageDealsWorkingCopy]);
  const validateKanbanExpertiseActionCallback = useActionCallback(
    async ({ actionDispatch }) => actionDispatch.exec(validateExpertiseAndStopWorkAction, t),
    [t],
    $operatorView
  );

  return (
    <>
      {(kanban.memos ?? {}) && (
        <div className="box memo-box">
          <MemosBloc
            memos={kanban.memos ?? {}}
            memoDescs={memoDescs}
            showOnlyEstimateMemos
            numberOfColumns={3}
          />
        </div>
      )}
      <div className="box">
        <ValidationPackageDealListComponent $gs={$gs} />
      </div>
      <ModalCardDialog
        title={t('expertiseValidation.validateModal.title')}
        $active={$validateExpertiseViewState.$showFinishModalDialog}
        onOkClicked={validateKanbanExpertiseActionCallback}
      >
        {t('expertiseValidation.validateModal.content', {
          count: globalHelpers.roundTo(availableCost),
        })}
      </ModalCardDialog>
    </>
  );
}
