import { isTruthy, isTruthyAndNotEmpty, keysOf } from '@stimcar/libs-kernel';
import type { RepositoryEntities } from '../../model/index.js';

export type AvailableRights = 'forbidden' | 'access' | 'modify';

// Gives the precedence of rights between them. Higher is overriding
const rightsPrecedenceMap: Record<AvailableRights, number> = {
  forbidden: 0,
  access: 1,
  modify: 2,
};

export const PERMISSION_WILDCARD_KEY = '*';

// This needs to be written like this and not with a Record due to a TS limitation issue
// https://github.com/microsoft/TypeScript/issues/41164
export type PermissionDefinition = AvailableRights | { [key: string]: PermissionDefinition };

// Used for profile definition server side, to ease the process.
// Since the conversion between profile names and associated permissions can only
// be done server side, this should not be used here by any means
export interface ProfileDefinition {
  readonly extendedProfiles: readonly string[];
  readonly permissions: PermissionDefinition;
  readonly isSubcontractor: boolean;
}

/**
 * This is used to represent all the available permissions accross the app.
 * It is used with the user permissions configuration to compute the whole PermissionConfiguration of the user
 */
export type FullPermissionConfiguration = { [key: string]: PermissionDefinition };

/**
 * Returns the permission with the highest priority
 * @param a
 * @param b
 */
export function permissionPrecedenceComparator(
  a: AvailableRights,
  b: AvailableRights
): AvailableRights {
  return rightsPrecedenceMap[a] >= rightsPrecedenceMap[b] ? a : b;
}

function getChildrenPermissionForKey(
  permissions: FullPermissionConfiguration,
  requestedPermissionItems: string[]
): [string, string[], PermissionDefinition] {
  const [item, ...rest] = requestedPermissionItems;
  let subPermission = permissions[item];
  // If there is no specific permissions, try to find a wildcard permission
  if (!subPermission) {
    subPermission = permissions[PERMISSION_WILDCARD_KEY];
  }
  return [item, rest, subPermission];
}

function containsAtLeastOneLeafWithRequestedPermission(
  permissions: FullPermissionConfiguration,
  requestedRight: AvailableRights
): boolean {
  for (const key of keysOf(permissions)) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    if (doTestIfPermissionIsGranted(permissions[key], ['*'], requestedRight)) {
      return true;
    }
  }
  return false;
}

function doTestIfPermissionIsGranted(
  permissions: PermissionDefinition,
  requestedPermissionItems: string[],
  requestedRight: AvailableRights
): boolean {
  if (typeof permissions === 'string') {
    // Special case for forbidden. We don't want to receive true if we ask if some path is forbidden
    // and the user has a higher permission
    return requestedRight === 'forbidden'
      ? requestedRight === permissions
      : rightsPrecedenceMap[requestedRight] <= rightsPrecedenceMap[permissions];
  }

  const [item, rest, subPermission] = getChildrenPermissionForKey(
    permissions,
    requestedPermissionItems
  );

  if (item === '*' && rest.length === 0) {
    return containsAtLeastOneLeafWithRequestedPermission(permissions, requestedRight);
  }

  return isTruthy(subPermission)
    ? doTestIfPermissionIsGranted(subPermission, rest, requestedRight)
    : false;
}

// Permission paths constants
// This is strongly coupled with the WHOLE_PERMISSION_TREE constant in the server.
// A modification of this constant should probably leads to modifications in the WHOLE_PERMISSION_TREE constant
export const AvailablePermissionPaths = {
  STAND_ACCESS_PERMISSION: (standId: string): string => {
    return `internal/stands/${standId}`;
  },
  REPOSITORY_ACCESS_PERMISSION: (repositoryName: keyof RepositoryEntities): string => {
    return `internal/repositories/${repositoryName}`;
  },
  MARK_PACKAGE_DEALS_AS_UNACHIEVABLE_ACTION: 'internal/actions/markPckAsUnachievable',
  CAN_OVERRIDE_SPAREPART_PRICE: 'internal/actions/RFPR/overrideSparepartPrice',
  CAN_REGISTER_WORKSHOP_POST: 'internal/actions/registerWorkshopPost',
  CAN_CREATE_DATA_DUMP: 'internal/actions/createDump',
  CAN_NOTIFY: 'internal/actions/notify',
  CAN_DISPATCH_OPERATIONS: 'internal/actions/dispatchOperations',
  CAN_CREATE_KANBAN: 'internal/actions/createKanban',
  CAN_IMPORT_KANBAN: 'internal/actions/importKanban',
  CAN_REVOKE_KANBAN: 'internal/actions/revokeKanban',
  CAN_FORCE_KANBAN_REVOCATION: 'internal/actions/forceKanbanRevocation',
  USERS_VIEW: 'internal/view/admin/users',
  CONTRACTS_VIEW: 'internal/view/admin/contracts',
  SCHEDULED_TASKS_VIEW: 'internal/view/admin/scheduledTasks',
  WORKFLOW_VIEW: 'internal/view/admin/workflow',
  SPAREPARTS_PROVIDERS_VIEW: 'internal/view/admin/sparepartsProviders',
  SHIFT_PARAMETERS_PERMISSION_VIEW: 'internal/view/admin/shiftParameters',
  CAN_ACCESS_KANBAN_DETAILS: 'internal/view/kanbanDetails',
  CAN_ACCESS_DASHBOARD: 'internal/view/dashboard',
  CAN_SHOW_ESTIMATE_FROM_WORKSHOP: 'internal/view/estimateFromWorkshop',
  CAN_ACCESS_ARCHIVES: 'internal/view/archives',
  CAN_ACCESS_ADMIN_CAR_ELEMENT: 'internal/view/admin/carElement',
  CAN_ACCESS_ADMIN_PACKAGE_DEAL_DESC: 'internal/view/admin/packageDealDesc',
  CAN_ACCESS_ADMIN_CUSTOMER: 'internal/view/admin/customer',
  INTERNAL_ACCESS: 'internal/*',
  CAN_COMPLETE_OPERATION: (operationType: string): string =>
    `internal/operation/completion/${operationType}`,
  CAN_ACCESS_ADMIN_INVOICING: 'internal/view/admin/invoicing',
  SUBCONTRACTOR_ACCESS: 'subcontractor/*',
  SUBCONTRACTOR_SELECT_VIEW: 'subcontractor/select',
  SUBCONTRACTOR_CAN_SEE_ALL_PACKAGE_DEALS: 'subcontractor/canSeeAllPackageDeals',
  SUBCONTRACTOR_STANDS_ACCESS: (standId: string): string => `subcontractor/stands/${standId}`,
  SUBCONTRACTOR_ATTACHMENTS_ACCESS: (folderId: string): string =>
    `subcontractor/attachments/${folderId}`,
  SUBCONTRACTOR_IMPLANTATION_POST_ACCESS: (
    standId: string,
    implantationId: string | undefined,
    categoryId: string
  ): string =>
    `subcontractor/stands/${standId}/${
      isTruthyAndNotEmpty(implantationId) ? implantationId : '*'
    }/${categoryId}`,
};

function tokenizePermissionPath(path: string): string[] {
  return path.split('/').filter((i) => isTruthyAndNotEmpty(i));
}

function hasPermission(
  permissions: FullPermissionConfiguration,
  requestedPermissionPath: string,
  requestedRight: AvailableRights
): boolean {
  return doTestIfPermissionIsGranted(
    permissions,
    tokenizePermissionPath(requestedPermissionPath),
    requestedRight
  );
}

export function hasModifyPermission(
  permissions: FullPermissionConfiguration,
  requestedPermissionPath: string
): boolean {
  return hasPermission(permissions, requestedPermissionPath, 'modify');
}

export function hasAccessPermission(
  permissions: FullPermissionConfiguration,
  requestedPermissionPath: string
): boolean {
  return hasPermission(permissions, requestedPermissionPath, 'access');
}

export function isForbidden(
  permissions: FullPermissionConfiguration,
  requestedPermissionPath: string
): boolean {
  return hasPermission(permissions, requestedPermissionPath, 'forbidden');
}
