import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { loadUIToolkitResourceBundles } from '@stimcar/libs-uitoolkit';
import bulmaLegacyFR from '../lib/bulmalegacy/i18n/bulmalegacy.fr.json';
import libComponentsFR from '../lib/components/i18n/libComponents.fr.json';
import registeredAppFR from '../registeredapp/components/i18n/registeredApp.fr.json';
import shareFR from '../share/i18n/share.fr.json';
import adminCarElementsFR from './admin/carElement/i18n/adminCarElements.fr.json';
import adminContractsFR from './admin/contract/i18n/adminContracts.fr.json';
import adminCustomersFR from './admin/customer/i18n/adminCustomers.fr.json';
import kanbansImportFR from './admin/kanbansImport/i18n/kanbansImport.fr.json';
import exportMarketplaceInvoicingFR from './admin/marketplace/i18n/exportMarketplaceInvoicing.fr.json';
import packageDealDescsFR from './admin/packageDealDesc/i18n/packageDealDescs.fr.json';
import adminScheduledTasksFr from './admin/scheduledTasks/i18n/adminScheduledTasks.fr.json';
import adminShiftParametersFR from './admin/shiftParameters/i18n/adminShiftParameters.fr.json';
import adminSparePartsProvidersFR from './admin/sparePartsProvider/i18n/adminSparePartsProviders.fr.json';
import usersAdminFR from './admin/users/i18n/usersAdmin.fr.json';
import workflowsFR from './admin/workflow/i18n/workflow.fr.json';
import archivesFR from './archives/i18n/archives.fr.json';
import creationFR from './creation/i18n/creation.fr.json';
import detailsFR from './details/i18n/details.fr.json';
import displayFR from './display/i18n/display.fr.json';
import globalsFR from './i18n/globals.fr.json';
import refititFR from './i18n/refitit.fr.json';
import operatorsFR from './operators/i18n/operators.fr.json';
import selectKanbanFR from './selectKanban/i18n/selectKanban.fr.json';
import refititCommonComponentsFR from './utils/i18n/refititCommonComponents.fr.json';
import workshop from './workshop/i18n/workshop.fr.json';

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      debug: true,
      lng: 'fr',
      fallbackLng: 'fr',
      interpolation: {
        escapeValue: false, // react is already safe from xss
      },
      returnNull: false,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any): void => {
      if (error) {
        throw error;
      }
      // Load UI Toolkit labels to be able to reuse them in the client components
      loadUIToolkitResourceBundles(i18n);
      i18n.addResourceBundle('fr', 'refitit', refititFR);
      i18n.addResourceBundle('fr', 'globals', globalsFR);
      i18n.addResourceBundle('fr', 'details', detailsFR);
      i18n.addResourceBundle('fr', 'selectKanban', selectKanbanFR);
      i18n.addResourceBundle('fr', 'adminCarElements', adminCarElementsFR);
      i18n.addResourceBundle('fr', 'adminCustomers', adminCustomersFR);
      i18n.addResourceBundle('fr', 'adminShiftParameters', adminShiftParametersFR);
      i18n.addResourceBundle('fr', 'refititCommonComponents', refititCommonComponentsFR);
      i18n.addResourceBundle('fr', 'packageDealDescs', packageDealDescsFR);
      i18n.addResourceBundle('fr', 'workflows', workflowsFR);
      i18n.addResourceBundle('fr', 'registeredApp', registeredAppFR);
      i18n.addResourceBundle('fr', 'operators', operatorsFR);
      i18n.addResourceBundle('fr', 'kanbansImport', kanbansImportFR);
      i18n.addResourceBundle('fr', 'bulmaLegacy', bulmaLegacyFR);
      i18n.addResourceBundle('fr', 'libComponents', libComponentsFR);
      i18n.addResourceBundle('fr', 'display', displayFR);
      i18n.addResourceBundle('fr', 'share', shareFR);
      i18n.addResourceBundle('fr', 'archives', archivesFR);
      i18n.addResourceBundle('fr', 'creation', creationFR);
      i18n.addResourceBundle('fr', 'usersAdmin', usersAdminFR);
      i18n.addResourceBundle('fr', 'workshop', workshop);
      i18n.addResourceBundle('fr', 'adminScheduledTasks', adminScheduledTasksFr);
      i18n.addResourceBundle('fr', 'adminContracts', adminContractsFR);
      i18n.addResourceBundle('fr', 'adminSparePartsProviders', adminSparePartsProvidersFR);
      i18n.addResourceBundle('fr', 'exportMarketplaceInvoicing', exportMarketplaceInvoicingFR);
    }
  );

// eslint-disable-next-line import/no-default-export
export default i18n;
