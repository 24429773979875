import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentFolder,
  AttachmentMetadata,
  Civility,
  Customer,
  Kanban,
  KanbanLogisticInfos,
  PackageDeal,
  RepositoryEntityStatus,
  SpecificFields,
  StorageCategories,
  UIContract,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps, EditCustomerDialogState, TabElementDef } from '@stimcar/libs-uitoolkit';
import {
  computePurchaseOrderChanges,
  updatePkgDealsForPurchaseOrder,
  updatePurchaseOrders,
} from '@stimcar/core-libs-common';
import { PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX } from '@stimcar/core-libs-repository';
import {
  arrayToMap,
  contractHelpers,
  CoreBackendRoutes,
  getSpecificFields,
  KANBAN_ATTRIBUTES,
  kanbanHelpers,
  nonDeleted,
  purchaseOrderHelpers,
  SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
  URL_LIST_ELEMENTS_SEPARATOR,
  WORK_SHEET_ATTRIBUTE_NAME,
  workflowHelpers,
} from '@stimcar/libs-base';
import { isTruthy, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  AttachmentsGallery,
  DeliveryView,
  downloadAndSave,
  EditCustomerDialog,
  EstimateView,
  GridCellBox,
  MemosBloc,
  openEditCustomerDialogAction,
  Tabs,
  useInitializeSelectedPurchaseOrderTabActionCallback,
} from '@stimcar/libs-uitoolkit';
import type {
  EditDueDateDialogState,
  EditRefitEndDateDialogState,
} from '../../lib/components/identityPictureAndGeneralInfoDisplay/typings/store.js';
import type { SelectPredefinedCustomerDialogState } from '../creation/typings/store.js';
import type { Store } from '../state/typings/store.js';
import type { EditGeneralInformationsDialogState } from '../utils/generalInformations/typings/store.js';
import {
  convertToAttributeExpectedType,
  DisplayAttributesComponent,
} from '../../lib/components/DisplayAttributesComponent.js';
import {
  openDeleteDueDateDialogAction,
  openDeleteRefitEndDateDialogAction,
  openEditDueDateDialogAction,
  openEditRefitEndDateDialogAction,
} from '../../lib/components/identityPictureAndGeneralInfoDisplay/KanbanGeneralInformations.js';
import { KanbanIdentityPictureAndGeneralInformationsComponent } from '../../lib/components/identityPictureAndGeneralInfoDisplay/KanbanIdentityPictureAndGeneralInformationsComponent.js';
import { KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE } from '../../lib/components/typings/store.js';
import {
  openSelectPredefinedCustomerDialog,
  SelectPredefinedCustomerDialog,
} from '../creation/components/SelectPredefinedCustomerDialog.js';
import { EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE } from '../creation/typings/store.js';
import {
  convertToPdfAction,
  importAttachmentsAction,
  importAttachmentsAndOverrideAction,
  loadAttachmentsGalleryAction,
  loadEstimateAttachmentsAction,
  removePdfPageAction,
  showRemoveAttachmentConfirmDialogAction,
} from '../utils/attachmentGalleryActions.js';
import {
  EditGeneralInformationsDialog,
  openEditGeneralInformationsDialogAction,
} from '../utils/generalInformations/EditGeneralInformationsDialog.js';
import { useComputeAttachmentUrl } from '../utils/useComputeAttachmentUrl.js';
import { useGetContractCodes } from '../utils/useGetContract.js';
import type { DesktopKanbanDetailsState, KanbanDetailsState } from './typings/store.js';
import { DisplayAllOperationsComponent } from './DisplayAllOperationsComponent.js';
import { DisplayAllPackageDealsComponent } from './DisplayAllPackageDealsComponent.js';
import { DisplayAllSparePartsComponent } from './DisplayAllSparePartsComponent.js';
import { DisplayHistoryComponent } from './DisplayHistoryComponent.js';
import { DisplayKanbanMessagesComponent } from './DisplayKanbanMessagesComponent.js';
import { InvoiceDetailsComponent } from './invoicing/InvoiceDetailsComponent.js';
import { KanbanDetailsActionsComponent } from './KanbanDetailsActionsComponent.js';
import { shouldAllowAttachmentDeletion } from './kanbanDetailsUtils.js';
import { MarketplaceDetails } from './marketplace/MarketplaceDetails.js';
import { KanbanDetailsContentDisplayTabs } from './typings/store.js';
import { WorkSheetTabComponent } from './WorkSheetTabComponent.js';

async function submitKanbanAttributeModification({
  getState,
  actionDispatch,
  kanbanRepository,
}: ActionContext<Store, KanbanDetailsState>): Promise<void> {
  const kanban = nonnull(getState().selectedKanban);
  const { attributeKey, attributeValue } =
    getState().desktopState.attributesTab.kanbanAttributeUpdateModalState;

  const newKanban: Kanban = {
    ...kanban,
    attributes: {
      ...kanban.attributes,
      [attributeKey]: convertToAttributeExpectedType(
        kanban.attributes,
        attributeKey,
        attributeValue
      ),
    },
  };
  await kanbanRepository.updateEntity(newKanban);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  actionDispatch.scopeProperty('desktopState').reduce((initial: DesktopKanbanDetailsState) => {
    return {
      ...initial,
      attributesTab: {
        ...initial.attributesTab,
        kanbanAttributeUpdateModalState: KANBAN_UPDATE_ATTRIBUTE_MODAL_EMPTY_STATE,
      },
    };
  });
}

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly documentsFolders: readonly AttachmentFolder[];
  readonly contract: UIContract;
}

export function KanbanDetailsComponent({ $gs, $, documentsFolders, contract }: Props): JSX.Element {
  const [t] = useTranslation('details');
  const { $imageModal } = $gs;
  const {
    $desktopState,
    $attachmentsState,
    $editCustomerDialogState,
    $selectPredefinedCustomerDialogState,
    $editGeneralInformationsDialogState,
    $editDueDateDialogState,
    $editRefitEndDateDialogState,
  } = $;

  const selectedKanban = useGetState($.$selectedKanban);
  const tabs: Record<string, string | TabElementDef> = useMemo(() => {
    const hasPendingRequestMessage =
      (selectedKanban?.messages ?? []).filter((m) => m.type === 'request' && m.status === 'pending')
        .length > 0;
    return {
      history: t('tabs.history.title'),
      packageDeals: t('tabs.packageDeals.allPackageDealsTitle'),
      spareParts: t('tabs.spareParts.title'),
      operations: t('tabs.operations.title'),
      kanbanMessages: {
        label: t('tabs.kanbanMessages.title', {
          count: selectedKanban?.messages.length ?? 0,
        }),
        icon: hasPendingRequestMessage
          ? {
              id: SUBCONTRACTOR_REQUEST_MESSAGE_ICON_ID,
              colorClass: 'has-text-warning',
            }
          : undefined,
      },
      ...(isTruthy(selectedKanban) && kanbanHelpers.isMarketplaceKanban(selectedKanban)
        ? { marketplace: t('tabs.marketplace.title') }
        : {}),
      kanbanAttributes: t('tabs.kanbanAttributes.title'),
      workSheet: t('tabs.workSheet.title'),
      invoice: t('tabs.invoice.title'),
      memos: t('tabs.memos.title'),
      files: t('tabs.files.title'),
    };
  }, [selectedKanban, t]);

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $attachmentsState
  );

  const isKanbanOpen = selectedKanban?.status === 'open';

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const selectDetailView = useSetCallback($desktopState.$viewToShow, 'details');

  const loadEstimateAttachmentsActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      await actionDispatch.exec(
        loadEstimateAttachmentsAction,
        'kanban',
        nonnull(selectedKanban).id
      );
    },
    [selectedKanban],
    $desktopState.$estimateView
  );

  const downloadAttachmentsZipCallback = useActionCallback(
    async ({ httpClient }) =>
      await downloadAndSave(
        httpClient,
        CoreBackendRoutes.ZIPPED_ATTACHMENT_FOLDER(
          'kanban',
          nonnull(selectedKanban).id,
          'expertise'
        ),
        'download.zip'
      ),
    [selectedKanban],
    $
  );

  const updateKanbanIdentityPictureAction = useActionCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { actionDispatch }: ActionContext<Store, any>,
      category: StorageCategories,
      objectId: string,
      folder: string,
      file: File
    ) => {
      await actionDispatch.exec(importAttachmentsAndOverrideAction, category, objectId, folder, [
        file,
      ]);
    },
    [],
    $
  );

  const onEditCustomerButtonClicked = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, EditCustomerDialogState>): Promise<void> => {
      await actionDispatch.exec(openEditCustomerDialogAction, {
        ...nonnull(selectedKanban).customer,
        contract: '', // Fake contractId
      });
    },
    [selectedKanban],
    $editCustomerDialogState
  );

  const onCustomerChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
    }: ActionContext<Store, EditCustomerDialogState>): Promise<void> => {
      const { formData } = getState();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { warnings, type, ...formDataWithoutWarnings } = formData;
      const newCustomer: SpecificFields<Customer> = {
        ...formDataWithoutWarnings,
        invoiceId: Number.parseInt(formDataWithoutWarnings.invoiceId, 10),
        contract: formDataWithoutWarnings.contractCode,
        civility: formDataWithoutWarnings.civility as Civility,
        individual: type === 'individual',
      };
      const newKanban: Kanban = {
        ...nonnull(selectedKanban),
        customer: newCustomer,
      };
      await kanbanRepository.updateEntity(newKanban);
      // Close the dialog
      actionDispatch.setProperty('active', false);
    },
    [selectedKanban],
    $editCustomerDialogState
  );

  const onSelectPredefinedCustomerButtonClicked = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<Store, SelectPredefinedCustomerDialogState>): Promise<void> => {
      await actionDispatch.exec(
        openSelectPredefinedCustomerDialog,
        selectedKanban?.contract.code ?? ''
      );
    },
    [selectedKanban],
    $selectPredefinedCustomerDialogState
  );
  const onPredefinedCustomerChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      customerRepository,
      kanbanRepository,
    }: ActionContext<Store, SelectPredefinedCustomerDialogState>): Promise<void> => {
      const newCustomer = getSpecificFields(
        await customerRepository.getEntity(getState().formData.customerId)
      );
      const newKanban: Kanban = {
        ...nonnull(selectedKanban),
        customer: newCustomer,
      };
      await kanbanRepository.updateEntity(newKanban);
      actionDispatch.reduce((initial) => {
        return {
          ...initial,
          selectPredefinedCustomerDialogState: EMPTY_SELECT_PREDEFINED_CUSTOMER_DIALOG_SATE,
        };
      });
      actionDispatch.setProperty('active', false);
    },
    [selectedKanban],
    $selectPredefinedCustomerDialogState
  );

  const onEditGeneralInformationsButtonClicked = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<Store, EditGeneralInformationsDialogState>): Promise<void> => {
      await actionDispatch.exec(openEditGeneralInformationsDialogAction, {
        ...nonnull(selectedKanban),
      });
    },
    [selectedKanban],
    $editGeneralInformationsDialogState
  );

  const contracts = useGetState($gs.$contracts);

  const initializeSelectedPurchaseOrderTabActionCallback =
    useInitializeSelectedPurchaseOrderTabActionCallback(
      $gs.$detailsView.$desktopState.$estimateView
    );

  const onGeneralInformationsChanged = useActionCallback(
    async ({
      actionDispatch,
      getState,
      kanbanRepository,
      packageDealDescRepository,
      carElementRepository,
      httpClient,
      getGlobalState,
    }: ActionContext<Store, EditGeneralInformationsDialogState>): Promise<void> => {
      const sequence = httpClient.getBrowserSequence();
      const { formData } = getState();
      const { workflowId } = formData;
      const { siteConfiguration } = getGlobalState();
      const uiContract = contractHelpers.findContractByCode(contracts, formData.contractCode);
      if (!uiContract) {
        throw new Error(`Unknown contract : ${formData.contractCode}`);
      }
      const kanbanContract = contractHelpers.getKanbanContractFromUIContract(nonnull(uiContract));
      const packageDealDescs = await packageDealDescRepository.getEntitiesFromIndex(
        PDD_BY_PACKAGE_DEAL_DESC_DATABASE_INDEX,
        kanbanContract.packageDealDatabase
      );
      const pkgDealDescsByCode = arrayToMap(packageDealDescs, ({ code }) => code);
      const carElements = await carElementRepository.getAllEntities();
      const carElementsById = arrayToMap(carElements, ({ id }) => id);
      const workflow = siteConfiguration.workflows.find((c) => c.id === workflowId);
      const linearizedWorkflow = workflowHelpers.linearize(nonnull(workflow).definition);
      const validationStands =
        workflowHelpers.getAllStandsOfTypeByWorkflowId(siteConfiguration, 'expertiseValidation')[
          workflowId
        ] ?? [];
      const lastValidationStandPosition = linearizedWorkflow.reduce(
        (acc, s, i): number => (validationStands.includes(s) ? i : acc),
        -1
      );
      const currentKanban = nonnull(selectedKanban);
      const { login } = nonnull(getGlobalState().session.user);

      const hasLocationChanged = kanbanHelpers.hasLocationChanged(
        currentKanban,
        formData.carLocation
      );
      const logisticInfos: KanbanLogisticInfos = hasLocationChanged
        ? kanbanHelpers.getUpdatedLogisticInfos(
            currentKanban,
            formData.carLocation,
            httpClient.getBrowserSequence().next()
          )
        : currentKanban.logisticInfos;
      const kanbanMessage = hasLocationChanged
        ? [
            ...currentKanban.messages,
            kanbanHelpers.createKanbanComment(
              httpClient.getBrowserSequence().next(),
              login,
              t('tabs.kanbanMessages.carLocationUpdated', {
                user: login,
                carLocation: formData.carLocation,
              })
            ),
          ]
        : currentKanban.messages;
      const newPurchaseOrders = purchaseOrderHelpers.getPurchaseOrdersFromString(
        formData.purchaseOrders,
        currentKanban.purchaseOrders,
        sequence
      );
      const purchaseOrdersChanges = computePurchaseOrderChanges(currentKanban, newPurchaseOrders);

      let newKanban: Kanban = {
        ...currentKanban,
        contract: contractHelpers.getKanbanContractFromUIContract(nonnull(uiContract)),
        workflowId: formData.workflowId,
        dueDate: !Number.isNaN(formData.dueDate) ? formData.dueDate : null,
        refitEndDate: !Number.isNaN(formData.refitEndDate) ? formData.refitEndDate : null,
        infos: {
          ...currentKanban.infos,
          brand: formData.brand,
          color: formData.color,
          dateOfRegistration: formData.dateOfRegistration,
          license: formData.license,
          mileage: Number(formData.mileage),
          model: formData.model,
          motor: formData.motor,
          vin: formData.vin,
        },
        logisticInfos,
        messages: kanbanMessage,
      };

      newKanban = updatePurchaseOrders(newKanban, purchaseOrdersChanges);
      newKanban = updatePkgDealsForPurchaseOrder(
        newKanban,
        purchaseOrdersChanges,
        linearizedWorkflow,
        lastValidationStandPosition,
        pkgDealDescsByCode,
        carElementsById,
        sequence
      );

      await kanbanRepository.updateEntity(newKanban);

      await actionDispatch.execCallback(
        initializeSelectedPurchaseOrderTabActionCallback,
        newPurchaseOrders
      );

      actionDispatch.setProperty('active', false);
    },
    [contracts, selectedKanban, t, initializeSelectedPurchaseOrderTabActionCallback],
    $editGeneralInformationsDialogState
  );

  const onEditDueDateButtonClicked = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, EditDueDateDialogState>): Promise<void> => {
      await actionDispatch.exec(
        openEditDueDateDialogAction,
        nonnull(selectedKanban).dueDate,
        nonnull(selectedKanban).refitEndDate
      );
    },
    [selectedKanban],
    $editDueDateDialogState
  );
  const onDeleteDueDateButtonClicked = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, EditDueDateDialogState>): Promise<void> => {
      await actionDispatch.exec(openDeleteDueDateDialogAction, nonnull(selectedKanban).dueDate);
    },
    [selectedKanban],
    $editDueDateDialogState
  );

  const onEditRefitEndDateButtonClicked = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<Store, EditRefitEndDateDialogState>): Promise<void> => {
      await actionDispatch.exec(
        openEditRefitEndDateDialogAction,
        nonnull(selectedKanban).dueDate,
        nonnull(selectedKanban).refitEndDate
      );
    },
    [selectedKanban],
    $.$editRefitEndDateDialogState
  );
  const onDeleteRefitEndDateButtonClicked = useActionCallback(
    async ({
      actionDispatch,
    }: ActionContext<Store, EditRefitEndDateDialogState>): Promise<void> => {
      await actionDispatch.exec(
        openDeleteRefitEndDateDialogAction,
        nonnull(selectedKanban).refitEndDate
      );
    },
    [selectedKanban],
    $.$editRefitEndDateDialogState
  );

  const contractCodes = useGetContractCodes($gs);

  const viewToShow = useGetState($desktopState.$viewToShow);
  const user = useGetState($gs.$session.$user);
  const isOnline = useGetState($gs.$session.$isOnline);
  const browserInfos = useGetState($gs.$session.$infos);

  const siteAddress = useGetState($gs.$siteConfiguration.$infos.$address);
  const siteCompany = useGetState($gs.$siteConfiguration.$infos.$companyName);
  const siteLogoUrl = useGetState($gs.$siteConfiguration.$infos.$logoUrl);
  const workflows = useGetState($gs.$siteConfiguration.$workflows);

  return (
    <>
      {viewToShow === 'estimate' && (
        <EstimateView
          returnAction={selectDetailView}
          kanban={nonnull(selectedKanban)}
          attributeDescs={contract.attributeDescs}
          memoDescs={contract.memoDescs}
          estimateMention={contract.estimateMention}
          address={siteAddress}
          companyName={siteCompany}
          logoUrl={siteLogoUrl}
          computeAttachmentUrl={computeAttachmentUrl}
          $imageModal={$imageModal}
          $={$desktopState.$estimateView}
          loadAvailableAttachmentsEffect={loadEstimateAttachmentsActionCallback}
          downloadAttachmentsZipCallback={downloadAttachmentsZipCallback}
        />
      )}
      {viewToShow === 'delivery' && (
        <DeliveryView
          returnAction={selectDetailView}
          kanban={nonnull(selectedKanban)}
          attributeDescs={contract.attributeDescs}
          address={siteAddress}
          companyName={siteCompany}
          logoUrl={siteLogoUrl}
          user={user}
        />
      )}
      {viewToShow === 'details' && (
        <div className="fixed-grid has-12-cols">
          <div className="grid">
            <GridCellBox colspan={9}>
              <KanbanIdentityPictureAndGeneralInformationsComponent
                kanban={nonnull(selectedKanban)}
                onEditCustomerButtonClicked={isKanbanOpen ? onEditCustomerButtonClicked : undefined}
                onEditGeneralInformationsButtonClicked={
                  isKanbanOpen ? onEditGeneralInformationsButtonClicked : undefined
                }
                onSelectPredefinedCustomerButtonClicked={
                  isKanbanOpen ? onSelectPredefinedCustomerButtonClicked : undefined
                }
                dueDateEditionToolkit={
                  isKanbanOpen
                    ? {
                        $: $editDueDateDialogState,
                        onEditDueDateButtonClicked,
                        onDeleteDueDateButtonClicked,
                      }
                    : undefined
                }
                refitEndDateEditionToolkit={
                  isKanbanOpen
                    ? {
                        $: $editRefitEndDateDialogState,
                        onEditRefitEndDateButtonClicked,
                        onDeleteRefitEndDateButtonClicked,
                      }
                    : undefined
                }
                identidyPictureToolkit={{
                  $imageModal,
                  computeUrlCallback: computeAttachmentUrl,
                  pictureEditionToolkit: {
                    updateKanbanIdentityPictureAction,
                    $: $desktopState.$pictureEditionToolkitState,
                    onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
                  },
                  isOnline,
                }}
                contract={contract}
              />
            </GridCellBox>
            <GridCellBox colspan={3}>
              <KanbanDetailsActionsComponent
                $gsKanbanModalState={$.$shareKanbanModal}
                $={$}
                $gs={$gs}
                role={browserInfos?.role ?? ''}
                isStandHandlingDisabled={!isKanbanOpen}
              />
            </GridCellBox>
            <GridCellBox colspan={12}>
              <Tabs
                isCentered
                labels={tabs}
                $selectedTab={$desktopState.$kanbanDetailsContentDisplayTab}
                className="narrow-bottom-margin"
              />
              <DetailsTabContent
                $gs={$gs}
                contract={contract}
                documentsFolders={documentsFolders}
                $selectedKanban={$.$selectedKanban.asDefined()}
                $desktopDetails={$desktopState}
                $detailsScopedState={$}
                isKanbanOpen={isKanbanOpen}
              />
            </GridCellBox>
          </div>
        </div>
      )}
      <EditCustomerDialog
        $={$editCustomerDialogState}
        submitValidDataAction={onCustomerChanged}
        hideContractIdField
        contractCodes={
          [
            /* No need to provide a contrat, the contract field is hidden */
          ]
        }
      />
      <EditGeneralInformationsDialog
        $={$editGeneralInformationsDialogState}
        $creationView={$gs.$creationView}
        $imageModal={$gs.$imageModal}
        $sessionIsOnline={$gs.$session.$isOnline}
        $sessionToken={$gs.$session.$infos.asDefined().$sessionToken}
        $windowWidth={$gs.$window.$width}
        submitValidDataAction={onGeneralInformationsChanged}
        contractCodes={contractCodes}
        workflows={workflows}
        hideTypeWorkflowContractPhotoFields
      />
      <SelectPredefinedCustomerDialog
        submitValidDataAction={onPredefinedCustomerChanged}
        $={$selectPredefinedCustomerDialogState}
      />
    </>
  );
}

interface ContentProps extends AppProps<Store> {
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly isKanbanOpen: boolean;
  readonly contract: UIContract;
  readonly documentsFolders: readonly AttachmentFolder[];
  readonly $desktopDetails: StoreStateSelector<Store, DesktopKanbanDetailsState>;
  readonly $detailsScopedState: StoreStateSelector<Store, KanbanDetailsState>;
}

function DetailsTabContent({
  $gs,
  $selectedKanban,
  $desktopDetails,
  isKanbanOpen,
  contract,
  documentsFolders,
  $detailsScopedState,
}: ContentProps): JSX.Element {
  const [t] = useTranslation('details');

  const { $imageModal } = $gs;

  const submitKanbanAttributeModificationCallback = useActionCallback(
    submitKanbanAttributeModification,
    [],
    $detailsScopedState
  );

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const { $attachmentsState } = $detailsScopedState;
  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $attachmentsState
  );

  const status = useGetState($selectedKanban.$status);

  const displayRemoveAttachmentHandler = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined) => {
      return shouldAllowAttachmentDeletion(status, attachment, metadata);
    },
    [status]
  );

  const rawPackageDeals = useGetState($selectedKanban.$packageDeals);

  const packageDeals = useMemo((): readonly PackageDeal[] => {
    return rawPackageDeals.filter(nonDeleted);
  }, [rawPackageDeals]);

  const kanbanDetailsContentDisplayTab = useGetState(
    $desktopDetails.$kanbanDetailsContentDisplayTab
  );

  const isOnline = useGetState($gs.$session.$isOnline);
  const browserLabel = useGetState($gs.$session.$infos.optChaining().$label);
  const importAttachmentsActionCallback = useActionCallback(importAttachmentsAction, [], $gs);
  const loadGalleryAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsState
  );

  const loadPdfAttachmentContentActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsState.$pdfCreationAndUploadModal
  );

  const selectedKanban = useGetState($selectedKanban);
  const isPurchaseOrderMandatory =
    selectedKanban.contract.configuration.mandatoryFields?.purchaseOrders ?? false;

  switch (kanbanDetailsContentDisplayTab) {
    case KanbanDetailsContentDisplayTabs.marketplace:
      return (
        <MarketplaceDetails
          $={$desktopDetails.$marketplaceTab}
          $gs={$gs}
          isEditable={isKanbanOpen}
          $selectedKanban={$selectedKanban}
        />
      );
    case KanbanDetailsContentDisplayTabs.kanbanAttributes:
      return (
        <DisplayAttributesComponent
          submitKanbanAttributeModificationCallback={submitKanbanAttributeModificationCallback}
          $={$desktopDetails.$attributesTab}
          isEditable={isKanbanOpen}
          showIsDisplayedInEstimate
          showIsMandatory
        />
      );
    case KanbanDetailsContentDisplayTabs.memos:
      return (
        <MemosBloc
          memos={selectedKanban.memos}
          memoDescs={contract.memoDescs}
          numberOfColumns={3}
        />
      );
    case KanbanDetailsContentDisplayTabs.packageDeals:
      return (
        <DisplayAllPackageDealsComponent
          packageDeals={packageDeals}
          $={$detailsScopedState}
          $gs={$gs}
          isEditable={isKanbanOpen}
          delegationSite={
            selectedKanban.attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE] as string | undefined
          }
          delegatedStatus={
            nonnull(selectedKanban).attributes[KANBAN_ATTRIBUTES.DELEGATED_STATUS] as
              | RepositoryEntityStatus
              | undefined
          }
        />
      );
    case KanbanDetailsContentDisplayTabs.spareParts:
      return (
        <DisplayAllSparePartsComponent
          packageDeals={selectedKanban.packageDeals.filter(nonDeleted)}
          isEditable={isKanbanOpen}
          $={$detailsScopedState}
          $gs={$gs}
          kanbanCustomerShortName={selectedKanban.customer.shortName}
        />
      );
    case KanbanDetailsContentDisplayTabs.operations:
      return (
        <DisplayAllOperationsComponent
          $gs={$gs}
          workflowId={selectedKanban.workflowId}
          packageDeals={selectedKanban.packageDeals.filter(nonDeleted)}
          isEditable={isKanbanOpen}
          $={$detailsScopedState}
        />
      );
    case KanbanDetailsContentDisplayTabs.history:
      return (
        <DisplayHistoryComponent
          kanban={selectedKanban}
          isEditable={isKanbanOpen}
          $={$desktopDetails.$historyTab}
          $gs={$gs}
        />
      );
    case KanbanDetailsContentDisplayTabs.kanbanMessages:
      return (
        <DisplayKanbanMessagesComponent
          kanban={selectedKanban}
          $={$desktopDetails.$kanbanMessageTab}
          isReadonly={!isOnline || !isKanbanOpen}
        />
      );
    case KanbanDetailsContentDisplayTabs.files:
      return (
        <AttachmentsGallery
          category="kanban"
          folders={documentsFolders}
          objectId={selectedKanban.id}
          $={$attachmentsState}
          $window={$gs.$window}
          uploadToolkit={{
            importAttachmentsActionCallback,
            convertToPdfToolkit: {
              loadPdfAttachmentContentActionCallback,
              convertToPdfHttpRequestAction: convertToPdfAction,
              clientSpecificFolderId: nonnull(browserLabel),
              removePdfPageHttpRequestAction: removePdfPageAction,
            },
          }}
          computeAttachmentUrl={computeAttachmentUrl}
          loadAttachmentsActionCallback={loadGalleryAttachmentsActionCallback}
          removeToolkit={{
            onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
            showRemoveAction: displayRemoveAttachmentHandler,
          }}
          $imageModal={$imageModal}
          isOnline={isOnline}
        />
      );
    case KanbanDetailsContentDisplayTabs.invoice:
      return (
        <InvoiceDetailsComponent
          purchaseOrders={selectedKanban.purchaseOrders}
          kanbanId={selectedKanban.id}
          isPurchaseOrderMandatory={isPurchaseOrderMandatory}
          $={$desktopDetails.$kanbanInvoiceTab}
          $gs={$gs}
        />
      );
    case KanbanDetailsContentDisplayTabs.workSheet:
      return (
        <WorkSheetTabComponent
          $gs={$gs}
          isOnline={isOnline}
          kanbanId={selectedKanban.id}
          computeAttachmentUrl={computeAttachmentUrl}
          workSheetFileName={selectedKanban.attributes[WORK_SHEET_ATTRIBUTE_NAME] as string}
        />
      );
    default:
      return <div>{t('tabs.incorrectTab')}</div>;
  }
}
