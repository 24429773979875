import { type TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { InvoiceInfo, PurchaseOrder } from '@stimcar/libs-base';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  AvailablePermissionPaths,
  nonDeleted,
  purchaseOrderHelpers,
  sortingHelpers,
} from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  Button,
  Checkbox,
  DisplayContentOrPlaceholder,
  ScrollableTableComponent,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { TableSortableHeaderComponent } from '../../../lib/components/TableSortableHeaderComponent.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import {
  AddInvoiceReferenceModal,
  openAddInvoiceReferenceModalAction,
} from './AddInvoiceReferenceModal.js';
import { DeleteInvoiceInfosModal } from './DeleteInvoiceInfosModal.js';
import { InvoiceDetailItem } from './InvoiceDetailItem.js';
import { RefundInvoiceModal } from './RefundInvoiceModal.js';
import { type InvoiceDetail, type KanbanInvoiceTabState } from './typings/store.js';

/**
 * Build a link to the accounting software for the provided invoide
 * @param invoiceId
 * @param invoiceFirmId
 * @param t
 * @returns
 */
function getInvoiceLink(
  invoiceId: number | null,
  invoiceFirmId: number | null,
  t: TFunction
): string | null {
  if (isTruthy(invoiceId) && isTruthy(invoiceFirmId)) {
    return t('tabs.invoice.invoiceLinkPattern', { invoiceId, invoiceFirmId });
  }
  return null;
}

/**
 * Return the reference from the invoice id of a refund
 * - ID is a number generated by the accounted software
 * - reference is a string generated with a prefix, the current year and an incremented ID
 *
 * This function search among all the known InvoiceInfos instances for the id of a refunded invoice
 * It can then return the reference which is stored on the found InvoiceInfos instance
 * @param refundedInvoiceId
 * @param invoiceInfos
 * @returns
 */
function getRefundedInvoiceReference(
  refundedInvoiceId: number | null,
  invoiceInfos: readonly InvoiceInfo[]
): string | null {
  if (isTruthy(refundedInvoiceId)) {
    // Search for reference among all the known invoice infos
    const foundInvoiceInfos = invoiceInfos.find(({ invoiceId }) => invoiceId === refundedInvoiceId);
    return foundInvoiceInfos?.reference ?? null;
  }
  return null;
}

/**
 * Return an ID to be used for an InvoiceDetail instance
 * - if both a purchaseOrder and an invoice infos, id will be '<purchaseOrderId> - <invoiceInfoId>'
 * - if only one is provided, resulting id will be the id of the non null or undefined object
 * - if none is provided, return an empty string (this case should never happen by construction)
 * @param purchaseOrderId technical ID of related purchase order (can be null, but not if invoiceInfosId is also null)
 * @param invoiceInfosId technical ID of related invoice infos object (can be null, but not if purchaseOrderId is also null)
 * @returns
 */
function getInvoiceDetailId(purchaseOrderId: string | null, invoiceInfosId: string | null): string {
  return [purchaseOrderId, invoiceInfosId].filter(isTruthy).join(' - ');
}

function createInvoiceDetail(
  purchaseOrder: PurchaseOrder,
  invoiceInfo: InvoiceInfo | null,
  t: TFunction
): InvoiceDetail {
  const invoiceInfoId = invoiceInfo?.id ?? null;
  const purchaseOrderId = purchaseOrder.id;
  const reference = invoiceInfo?.reference ?? null;
  const amount = invoiceInfo?.amount ?? null;
  const invoiceId = invoiceInfo?.invoiceId ?? null;
  const invoiceFirmId = invoiceInfo?.invoiceFirmId ?? null;
  const isRefund = invoiceInfo?.isRefund ?? null;
  const refundedInvoiceId = invoiceInfo?.refundedInvoiceId ?? null;

  const allInvoiceInfos = purchaseOrder.invoiceInfos.filter(nonDeleted) ?? [];
  // Looking for an InvoiceInfos corresponding to a refund for the current invoice
  const refundingInvoice = allInvoiceInfos.find(
    (info) => info.isRefund && info.refundedInvoiceId === invoiceId
  );
  const refundingInvoiceId = refundingInvoice?.invoiceId ?? null;

  return {
    invoiceDetailId: getInvoiceDetailId(purchaseOrderId, invoiceInfoId),
    invoiceInfoId,
    purchaseOrderId,
    reference,
    amount,
    invoiceId,
    invoiceFirmId,
    link: getInvoiceLink(invoiceId, invoiceFirmId, t),
    isRefund,
    refundedInvoiceReference: getRefundedInvoiceReference(refundedInvoiceId, allInvoiceInfos),
    purchaseOrderLabel: purchaseOrderHelpers.getPurchaseOrderDisplayedLabel(purchaseOrder),
    refundingInvoiceId,
  };
}

function getInvoiceDetailsToDisplay(
  purchaseOrders: readonly PurchaseOrder[],
  t: TFunction
): readonly InvoiceDetail[] {
  const invoiceDetailsToDisplay: InvoiceDetail[] = purchaseOrders
    .filter(nonDeleted)
    .flatMap((purchaseOrder) => {
      if (purchaseOrder.invoiceInfos.length === 0) {
        // No invoice info yet on this purchase order
        return createInvoiceDetail(purchaseOrder, null, t);
      }
      return purchaseOrder.invoiceInfos.filter(nonDeleted).map((invoiceInfo) => {
        return createInvoiceDetail(purchaseOrder, invoiceInfo, t);
      });
    });

  // Default sort
  return [...invoiceDetailsToDisplay].sort(
    (
      { purchaseOrderLabel: label1, invoiceId: invoiceId1 }: InvoiceDetail,
      { purchaseOrderLabel: label2, invoiceId: invoiceId2 }: InvoiceDetail
    ) => {
      // Sort by purchase order label
      const labelCompare = (label1 ?? '').localeCompare(label2 ?? '');
      if (labelCompare === 0) {
        // Sort by invoice Id
        return (invoiceId1 ?? -1) - (invoiceId2 ?? -1);
      }
      return labelCompare;
    }
  );
}

type InvoiceDetailsComponentProps = {
  readonly kanbanId: string;
  readonly purchaseOrders: readonly PurchaseOrder[];
  readonly isPurchaseOrderMandatory: boolean;
  readonly $: StoreStateSelector<Store, KanbanInvoiceTabState>;
} & AppProps<Store>;

export function InvoiceDetailsComponent({
  $,
  $gs,
  kanbanId,
  purchaseOrders,
  isPurchaseOrderMandatory,
}: InvoiceDetailsComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const canModifyInvoicing = useHasModifyPermission(
    $gs,
    AvailablePermissionPaths.CAN_ACCESS_ADMIN_INVOICING
  );

  const {
    $invoiceReferenceAddModalState,
    $invoiceRefundModalState,
    $invoiceInfoDeletionModalState,
  } = $;

  const invoiceDetails = useMemo(
    (): readonly InvoiceDetail[] => getInvoiceDetailsToDisplay(purchaseOrders, t),
    [purchaseOrders, t]
  );

  const sortBy = useGetState($.$sort.$by);
  const sortDirection = useGetState($.$sort.$direction);

  const sortedInvoiceDetails = useMemo((): readonly InvoiceDetail[] => {
    const sortedDatas = invoiceDetails.slice();
    let sortFunction: (i1: InvoiceDetail, i2: InvoiceDetail) => number;
    switch (sortBy) {
      case 'reference':
      case 'refundedInvoiceReference':
      case 'purchaseOrderLabel':
      case 'link':
        sortFunction = sortingHelpers.createSortByStringField(sortDirection, sortBy);
        break;
      case 'isRefund':
        sortFunction = sortingHelpers.createSortByBooleanField(sortDirection, sortBy);
        break;
      case 'amount':
        sortFunction = sortingHelpers.createSortByNumericField(sortDirection, sortBy);
        break;
      default:
        return sortedDatas;
    }
    return sortedDatas.sort(sortFunction);
  }, [invoiceDetails, sortBy, sortDirection]);

  const showTechnicalId = useGetState($.$showTechnicalId);

  const openAddReferenceDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(openAddInvoiceReferenceModalAction);
    },
    [],
    $invoiceReferenceAddModalState
  );

  return (
    <DisplayContentOrPlaceholder
      isScrollable
      displayCondition={invoiceDetails.length > 0}
      placeholder={t('tabs.invoice.emptyPlaceholder')}
    >
      <>
        <Checkbox $={$.$showTechnicalId} text={t('tabs.invoice.id')} />
        <ScrollableTableComponent tableClassName="table is-narrow is-striped is-hoverable is-fullwidth">
          <thead>
            <tr>
              {showTechnicalId && <th>{t('tabs.idTitle')}</th>}
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="purchaseOrderLabel"
                content={t('tabs.invoice.purchaseOrder')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="isRefund"
                content={t('tabs.invoice.type')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="reference"
                content={t('tabs.invoice.reference')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="amount"
                content={t('tabs.invoice.amount')}
              />
              <TableSortableHeaderComponent
                isTruncable
                $sort={$.$sort}
                centerLabel={false}
                sortedField="link"
                content={t('tabs.invoice.link')}
              />
              {canModifyInvoicing && (
                <th aria-label="addInvoiceInfos" className="has-text-centered">
                  <Button
                    iconId="plus"
                    onClick={openAddReferenceDialogCallback}
                    size="small"
                    tooltip={t('tabs.invoice.actions.add')}
                  />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedInvoiceDetails.map((invoiceDetail) => (
              <InvoiceDetailItem
                key={invoiceDetail.invoiceDetailId}
                kanbanId={kanbanId}
                invoiceDetail={invoiceDetail}
                canModify={canModifyInvoicing}
                showTechnicalId={showTechnicalId}
                $={$}
              />
            ))}
          </tbody>
        </ScrollableTableComponent>
        <AddInvoiceReferenceModal
          $={$invoiceReferenceAddModalState}
          kanbanId={kanbanId}
          purchaseOrders={purchaseOrders}
          isPurchaseOrderMandatory={isPurchaseOrderMandatory}
        />
        <RefundInvoiceModal $={$invoiceRefundModalState} kanbanId={kanbanId} />
        <DeleteInvoiceInfosModal $={$invoiceInfoDeletionModalState} kanbanId={kanbanId} />
      </>
    </DisplayContentOrPlaceholder>
  );
}
