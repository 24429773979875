import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Kanban, UIContract } from '@stimcar/libs-base';
import { kanbanHelpers } from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import {
  ClickableIcon,
  CustomerInformations,
  KanbanIdentityPictureComponent,
} from '@stimcar/libs-uitoolkit';
import { MarketplaceIcon } from '../../../app/utils/MarketplaceIcon.js';
import type {
  DueDateEditionToolkit,
  IdentidyPictureToolkit,
  MileageEditionToolkit,
  RefitEndDateEditionToolkit,
} from './typings/toolkits.js';
import { KanbanGeneralInformations } from './KanbanGeneralInformations.js';

interface Props {
  readonly kanban: Kanban;
  readonly contract: UIContract | undefined;
  readonly displayShowInEstimateAttributes?: boolean;
  readonly onEditCustomerButtonClicked?: () => Promise<void>;
  readonly onEditGeneralInformationsButtonClicked?: () => Promise<void>;
  readonly onSelectPredefinedCustomerButtonClicked?: () => Promise<void>;
  readonly dueDateEditionToolkit?: DueDateEditionToolkit;
  readonly refitEndDateEditionToolkit?: RefitEndDateEditionToolkit;
  readonly mileageEditionToolkit?: MileageEditionToolkit;
  readonly identidyPictureToolkit?: IdentidyPictureToolkit;
  readonly hideTitle?: boolean;
}

export function KanbanIdentityPictureAndGeneralInformationsComponent({
  kanban,
  displayShowInEstimateAttributes = false,
  onEditCustomerButtonClicked,
  onSelectPredefinedCustomerButtonClicked,
  onEditGeneralInformationsButtonClicked,
  dueDateEditionToolkit,
  refitEndDateEditionToolkit,
  mileageEditionToolkit,
  identidyPictureToolkit,
  contract,
  hideTitle = false,
}: Props): JSX.Element {
  const [t] = useTranslation('custom');
  const kanbanColor = isTruthyAndNotEmpty(kanban.infos.color)
    ? kanban.infos.color
    : t('kanbanGeneralInformations.unknownColor');

  const kanbanTitle = kanbanHelpers.getKanbanTitle(kanban.infos, kanban.attributes, t, true);

  return (
    <>
      <div className="columns is-vcentered">
        {isTruthy(identidyPictureToolkit) && (
          <div className="column is-narrow">
            <KanbanIdentityPictureComponent
              kanbanId={kanban.id}
              computeAttachmentUrl={identidyPictureToolkit.computeUrlCallback}
              $imageModal={identidyPictureToolkit.$imageModal}
              pictureEditionToolkit={identidyPictureToolkit.pictureEditionToolkit}
              isOnline={identidyPictureToolkit.isOnline}
            />
          </div>
        )}
        {!hideTitle && (
          <div className="column">
            <span className="title is-size-5">
              <MarketplaceIcon kanban={kanban} />
              {kanbanTitle}
            </span>
            <span className="has-text-weight-light is-uppercase">{` (${kanbanColor})`}</span>
            {onEditGeneralInformationsButtonClicked && (
              <ClickableIcon
                clickHandler={onEditGeneralInformationsButtonClicked}
                id="edit"
                elementClassName="m-l-sm"
              />
            )}
          </div>
        )}
      </div>
      <div className="columns">
        <KanbanGeneralInformations
          kanban={kanban}
          displayShowInEstimateAttributes={displayShowInEstimateAttributes}
          dueDateEditionToolkit={dueDateEditionToolkit}
          refitEndDateEditionToolkit={refitEndDateEditionToolkit}
          mileageEditionToolkit={mileageEditionToolkit}
          contract={contract}
        />
        <div className="column is-3">
          <CustomerInformations
            customer={kanban.customer}
            onEditCustomerButtonClicked={onEditCustomerButtonClicked}
            onSelectPredefinedCustomerButtonClicked={onSelectPredefinedCustomerButtonClicked}
          />
        </div>
      </div>
    </>
  );
}
