import React from 'react';
import { useTranslation } from 'react-i18next';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthy, isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback } from '@stimcar/libs-uikernel';
import { FaIcon } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { ConvertUrlsToLinks } from '../../../lib/components/DisplayAttributesComponent.js';
import { PRICE_DECIMAL_LENGTH } from '../kanbanDetailsUtils.js';
import { openDeleteInvoiceInfosDeletionModalAction } from './DeleteInvoiceInfosModal.js';
import { openRefundInvoiceModalAction } from './RefundInvoiceModal.js';
import { type InvoiceDetail, type KanbanInvoiceTabState } from './typings/store.js';

type InvoiceDetailItemProps = {
  readonly invoiceDetail: InvoiceDetail;
  readonly kanbanId: string;
  readonly canModify: boolean;
  readonly showTechnicalId: boolean;
  readonly $: StoreStateSelector<Store, KanbanInvoiceTabState>;
};

export function InvoiceDetailItem({
  invoiceDetail,
  canModify,
  showTechnicalId,
  $,
}: InvoiceDetailItemProps): JSX.Element {
  const [t] = useTranslation('details');

  const { $invoiceRefundModalState, $invoiceInfoDeletionModalState } = $;

  const {
    invoiceDetailId,
    purchaseOrderId,
    invoiceInfoId,
    invoiceId,
    purchaseOrderLabel,
    reference,
    amount,
    isRefund,
    refundedInvoiceReference,
    refundingInvoiceId,
    link,
  } = invoiceDetail;

  const openRefundInvoiceDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      if (isTruthy(invoiceId)) {
        await actionDispatch.exec(
          openRefundInvoiceModalAction,
          invoiceId,
          purchaseOrderId,
          reference ?? ''
        );
      }
    },
    [invoiceId, purchaseOrderId, reference],
    $invoiceRefundModalState
  );

  const openDeleteInvoiceInfosDialogCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        openDeleteInvoiceInfosDeletionModalAction,
        purchaseOrderId,
        invoiceInfoId ?? ''
      );
    },
    [purchaseOrderId, invoiceInfoId],
    $invoiceInfoDeletionModalState
  );

  const typeLabel = isRefund ? t('tabs.invoice.refund') : t('tabs.invoice.invoice');

  const strikeThrough = {
    style: { textDecoration: 'line-through' },
  };

  // A refund is possible on InvoiceInfos instances :
  // - which do not correspond to a refund
  // - which correspond to an invoice which has not yet been refunded
  const hasNoInvoiceInfos = !isTruthy(invoiceId);
  const isARefundedInvoice = isTruthy(refundingInvoiceId);
  const isRefundPossible = !hasNoInvoiceInfos && !isRefund && !isARefundedInvoice;

  let refundTooltip = t('tabs.invoice.actions.refund');
  if (hasNoInvoiceInfos) {
    refundTooltip = t('tabs.invoice.actions.noRefundWhenNoInvoiceInfos');
  } else if (isRefund) {
    refundTooltip = t('tabs.invoice.actions.noRefundOnRefund');
  } else if (isARefundedInvoice) {
    refundTooltip = t('tabs.invoice.actions.noRefundOnRefundedInvoice');
  }

  const isDeletePossible = isTruthy(invoiceInfoId);

  return (
    <tr key={invoiceDetailId}>
      {showTechnicalId && <td>{invoiceDetailId}</td>}
      <td>{purchaseOrderLabel}</td>
      <td>{isTruthyAndNotEmpty(reference) ? typeLabel : ''}</td>
      <td>
        {isTruthy(refundingInvoiceId) ? (
          <span {...strikeThrough}>{reference}</span>
        ) : (
          <span>{reference}</span>
        )}
        {isTruthy(refundedInvoiceReference) && (
          <span className="is-size-7 p-l-sm">
            <span>(</span>
            <span {...strikeThrough}>{refundedInvoiceReference}</span>
            <span>)</span>
          </span>
        )}
      </td>
      <td className="has-text-right">
        {isTruthy(amount)
          ? t('tabs.price', {
              price: amount.toFixed(PRICE_DECIMAL_LENGTH),
            })
          : ''}
      </td>
      <td aria-label="link">
        <ConvertUrlsToLinks jsxElementKeyPrefix="link" text={link ?? ''} />
      </td>
      {canModify && (
        <td
          aria-label="actions"
          className="has-text-centered"
          style={{ paddingRight: 0, paddingLeft: 0 }}
        >
          <button
            key="refund"
            aria-label="refund"
            type="button"
            className="button is-small is-white"
            title={t('tabs.invoice.actions.refund')}
            onClick={openRefundInvoiceDialogCallback}
            disabled={!isRefundPossible}
          >
            <FaIcon id="rotate-left" tooltip={refundTooltip} />
          </button>
          <button
            key="delete"
            aria-label="delete"
            type="button"
            className="button is-small is-white"
            title={t('tabs.invoice.actions.delete')}
            onClick={openDeleteInvoiceInfosDialogCallback}
            disabled={!isDeletePossible}
          >
            <FaIcon
              id="trash"
              tooltip={
                isDeletePossible
                  ? t('tabs.invoice.actions.delete')
                  : t('tabs.invoice.actions.noDeleteWhenNoInvoiceInfos')
              }
            />
          </button>
        </td>
      )}
    </tr>
  );
}
