import { isTruthy } from '@stimcar/libs-kernel';
import type { FlatOperationItem } from './packageDealHelpers.js';

function isCompletedOperation({ completionDate }: FlatOperationItem): boolean {
  return isTruthy(completionDate);
}

function hasOperationsForStand(
  operations: readonly FlatOperationItem[],
  givenStandId: string
): boolean {
  return operations.some(({ standId }) => standId === givenStandId);
}

function isValidatedVEXPOperation({ completionDate, type }: FlatOperationItem): boolean {
  return type === 'Validation' && isTruthy(completionDate);
}
function isMarketplaceSellOperation({ type }: FlatOperationItem): boolean {
  return type === 'MarketPlaceOnlineSell';
}

function areAllOperationsCompletedForStand(
  operations: readonly FlatOperationItem[],
  givenStandId: string
): boolean {
  return operations.filter(({ standId }) => standId === givenStandId).every(isCompletedOperation);
}

export const operationHelpers = {
  hasOperationsForStand,
  areAllOperationsCompletedForStand,
  isValidatedVEXPOperation,
  isMarketplaceSellOperation,
};
