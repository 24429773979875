import { isTruthy } from '@stimcar/libs-kernel';
import type { Priceable } from '../../model/index.js';
import { VATPercentage } from '../../model/index.js';

function getPriceWithoutVAT<T extends Priceable>(element: T): number {
  return isTruthy(element.price) ? element.price : 0;
}

function getPriceWithVAT<T extends Priceable>(
  element: T,
  vatPercentage: number = VATPercentage
): number {
  const priceWithoutVAT = getPriceWithoutVAT(element);
  const VAT = priceWithoutVAT * (vatPercentage / 100);
  return priceWithoutVAT + VAT;
}

export const priceHelpers = {
  getPriceWithoutVAT,
  getPriceWithVAT,
};
