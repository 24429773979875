import React, { useMemo } from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { toUpperFirst } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceDetailsProps } from './MarketplaceDetails.js';

export interface MarketplaceAdHeaderProps extends Pick<MarketplaceDetailsProps, '$gs'> {
  readonly $publicSellingPrice: StoreStateSelector<Store, string>;
  readonly $brand: StoreStateSelector<Store, string>;
  readonly $model: StoreStateSelector<Store, string>;
  readonly $motor: StoreStateSelector<Store, string>;
  readonly $color: StoreStateSelector<Store, string>;
  readonly $mileage: StoreStateSelector<Store, string | number>;
  readonly $dateOfRegistration: StoreStateSelector<Store, string | number | null>;
}

export function MarketplaceAdHeader({
  $gs,
  $publicSellingPrice,
  $brand,
  $color,
  $dateOfRegistration,
  $mileage,
  $model,
  $motor,
}: MarketplaceAdHeaderProps): JSX.Element {
  const publicSellingPrice = useGetState($publicSellingPrice);
  const brand = useGetState($brand);
  const color = useGetState($color);
  const dateOfRegistration = useGetState($dateOfRegistration);
  const mileage = useGetState($mileage);
  const model = useGetState($model);
  const motor = useGetState($motor);

  const year = useMemo(
    () => (dateOfRegistration ? new Date(dateOfRegistration).getFullYear() : undefined),
    [dateOfRegistration]
  );
  const siteId = useGetState($gs.$session.$infos.$site.$id);
  return (
    <>
      <div className="title is-3">
        <>{`${brand} ${model} ${color} ${motor}`}</>
      </div>
      <div className="subtitle is-5">{`${toUpperFirst(siteId)} - ${year} - ${mileage} km`}</div>
      <div className="title is-4">{`${publicSellingPrice} €`}</div>
    </>
  );
}
