export type GearboxValues = '' | 'auto' | 'manual';
export type FuelValues = '' | 'diesel' | 'gas' | 'hybrid' | 'electric';

export type MarketplaceProcess = '' | 'BUY' | 'SELL';

export const MKTP_OFFLINE_STATUS = 'offline';
export const MKTP_ONLINE_STATUS = 'online';
export const MKTP_SOLD_STATUS = 'sold';

export type MarketplaceStatus =
  | typeof MKTP_OFFLINE_STATUS
  | typeof MKTP_ONLINE_STATUS
  | typeof MKTP_SOLD_STATUS;
