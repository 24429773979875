import type {
  MergedCarViewCategory,
  OperationRequestMessage,
  SubcontractorKanban,
} from '@stimcar/libs-base';
import type { AttachmentGalleryState, ExpandedKanbanStatuses } from '@stimcar/libs-uitoolkit';
import { EMPTY_ATTACHMENT_GALLERY_STATE } from '@stimcar/libs-uitoolkit';
import type { SubcontractorUIOperation } from '../../lib/subcontractorCardUtils.js';

export type SubcontractorOperationMessageModal = {
  readonly active: boolean;
  readonly messageContent: string;
  readonly uiOperation: SubcontractorUIOperation | undefined;
  readonly initialMessage: OperationRequestMessage | undefined;
};

export const SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE: SubcontractorOperationMessageModal =
  {
    active: false,
    messageContent: '',
    uiOperation: undefined,
    initialMessage: undefined,
  };

export type OtherOperatationTabState = {
  readonly expandedCategories: Record<MergedCarViewCategory, boolean>;
};

export type OperationToDoState = {
  readonly isAttachmentsExpanded: boolean;
};

export type OperationsToDoTabState = {
  readonly operationsState: Record<string, OperationToDoState>;
};

export type SubcontractorOperateState = {
  readonly kanban: SubcontractorKanban | undefined;
  readonly tab: 'operationsToDo' | 'otherOperations' | 'messages' | 'folders';
  readonly messageModal: SubcontractorOperationMessageModal;
  readonly attachmentsState: AttachmentGalleryState;
  readonly otherOperationsTabState: OtherOperatationTabState;
  readonly operationsToDoTabState: OperationsToDoTabState;
  readonly expandedKanbanStatuses: ExpandedKanbanStatuses;
};

export const SUBCONTRACTOR_OPERATE_EMPTY_STATE: SubcontractorOperateState = {
  kanban: undefined,
  tab: 'operationsToDo',
  messageModal: SUBCONTRACTOR_OPERATION_MESSAGE_MODAL_EMPTY_STATE,
  attachmentsState: EMPTY_ATTACHMENT_GALLERY_STATE,
  otherOperationsTabState: {
    expandedCategories: {
      BODY: false,
      INTE: false,
      MECA: false,
      MISC: false,
    },
  },
  operationsToDoTabState: {
    operationsState: {},
  },
  expandedKanbanStatuses: {},
};
