import type { TFunction } from 'i18next';
import type { Operation, PackageDeal } from '@stimcar/libs-base';
import type { DeepPartial } from '@stimcar/libs-kernel';
import type { ActionContext } from '@stimcar/libs-uikernel';
import { nonDeleted } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import type { Store } from '../../state/typings/store.js';
import type {
  ApplyToggleOperationsPayloadAction,
  SelectSubcontractorFirmModalStateContainer,
  SubcontractableInfo,
} from '../../utils/subcontractor/SelectSubcontractorFirmModalDialog.js';
import type { OperatorViewState } from '../typings/store.js';
import { canCompleteOperation } from '../../utils/operatorUtils.js';
import { toggleOperationOrOpenSelectSubcontractorFirmModalAction } from '../../utils/subcontractor/SelectSubcontractorFirmModalDialog.js';

async function applyToggleOperationsPayloadToRepository<
  S extends SelectSubcontractorFirmModalStateContainer,
>(
  ctx: ActionContext<Store, S>,
  kanbanId: string,
  updatedPackageDeals: readonly DeepPartial<PackageDeal>[]
): Promise<void> {
  const { kanbanRepository } = ctx;
  await kanbanRepository.updateEntityFromPayload({
    entityId: kanbanId,
    payload: {
      packageDeals: updatedPackageDeals,
    },
  });
}

function getAuthorizedOperationsToDo({
  getState,
  getGlobalState,
}: ActionContext<Store, OperatorViewState>): readonly Operation[] {
  const { user } = getGlobalState().session;
  const { operationsToDoIds, generalOperatorState } = getState();
  const { permissions } = user!;

  const authorizedOperationsToDo: Operation[] = [];
  generalOperatorState.standRelatedPackageDeals.forEach((pd) => {
    pd.operations.forEach((o) => {
      if (operationsToDoIds.includes(o.id)) {
        if (canCompleteOperation(o, permissions)) {
          authorizedOperationsToDo.push(o);
        }
      }
    });
  });

  return authorizedOperationsToDo;
}

const DEFAULT_APPLY_TOGGLE_OPERATIONS_PAYLOAD_INTO_KANBAN_REPOSITORY: ApplyToggleOperationsPayloadAction<OperatorViewState> =
  applyToggleOperationsPayloadToRepository;

export async function toggleOperationStatusInOperatorViewAction(
  ctx: ActionContext<Store, OperatorViewState>,
  operationId: string,
  applyToggleOperationPayloadAction: ApplyToggleOperationsPayloadAction<OperatorViewState> = DEFAULT_APPLY_TOGGLE_OPERATIONS_PAYLOAD_INTO_KANBAN_REPOSITORY,
  // Only used for Subcontractable operations once the SelectSubcontractorFirmModalDialog
  // has been shown to let the user select the right subcontractor
  subcontractableInfo?: SubcontractableInfo
): Promise<void> {
  const { getState, actionDispatch } = ctx;
  const { generalOperatorState, operatedKanban } = getState();
  const { standRelatedPackageDeals } = generalOperatorState;

  // Check that it is allowed to update the operation
  const authorizedOperationsToDo = getAuthorizedOperationsToDo(ctx);
  const authorizedOperationsToDoIds = authorizedOperationsToDo.map((o) => o.id);
  if (!authorizedOperationsToDoIds.includes(operationId)) {
    throw Error(`Toggling this operation is not permitted for the current user`);
  }

  // If the operation is subcontractable and not completed and the SelectSubcontractorFirmModalDialog
  // has not yet been shown, open it to let the user select the right subcontractor
  await actionDispatch.exec(
    toggleOperationOrOpenSelectSubcontractorFirmModalAction<OperatorViewState>,
    operatedKanban!.id,
    standRelatedPackageDeals,
    operationId,
    applyToggleOperationPayloadAction,
    subcontractableInfo
  );
}

export async function toggleAllOperationsStatusAction(
  ctx: ActionContext<Store, OperatorViewState>,
  t: TFunction,
  applyToggleOperationPayloadAction: ApplyToggleOperationsPayloadAction<OperatorViewState> = DEFAULT_APPLY_TOGGLE_OPERATIONS_PAYLOAD_INTO_KANBAN_REPOSITORY
): Promise<void> {
  const { actionDispatch, getState, getGlobalState, globalActionDispatch } = ctx;
  const { user } = getGlobalState().session;
  const { generalOperatorState, operatedKanban } = getState();
  const { standRelatedPackageDeals } = generalOperatorState;
  const { login: userLogin } = user!;

  const authorizedOperationsToDo = getAuthorizedOperationsToDo(ctx);
  const authorizedOperationsToDoIds = authorizedOperationsToDo.map((o) => o.id);

  const allOperationsAreFinished = authorizedOperationsToDo.reduce<boolean>(
    (p, c): boolean => p && !!c.completionDate,
    true
  );

  const mode = allOperationsAreFinished ? 'deselectAll' : 'selectAll';

  const unprocessedSubcontractableOperations: Operation[] = [];
  const packageDealsToUpdate = standRelatedPackageDeals
    .map(
      ({ id: pkgId, operations, isSubcontractable }): DeepPartial<PackageDeal> => ({
        id: pkgId,
        operations:
          // Filter deleted operations
          operations
            .filter(nonDeleted)
            // Filter unauthorized operations
            .filter(({ id }) => authorizedOperationsToDoIds.includes(id))
            // Filter checked/unchecked operations (according to the mode)
            .filter(
              ({ completionDate }) =>
                (mode === 'selectAll' && !isTruthy(completionDate)) ||
                (mode === 'deselectAll' && isTruthy(completionDate))
            )
            // Filter (and keep track of) subcontractable operations
            .filter((op) => {
              switch (mode) {
                case 'deselectAll': {
                  if (isSubcontractable) {
                    // If the operation is subcontractable, only prevent
                    // from unchecking it if it has been marked as realized
                    // by a subcontractor. In other words, if it has been
                    // realized by a stimcar user, don't prevent from
                    // unchecking it.
                    if (isTruthy(op.subcontractor)) {
                      unprocessedSubcontractableOperations.push(op);
                      return false;
                    }
                  }
                  // Otherwise...
                  return true;
                }
                case 'selectAll': {
                  // If the operation is subcontractable, don't automatically
                  // check it among other operations
                  if (isSubcontractable) {
                    // Keep track of the subcontractable operation
                    unprocessedSubcontractableOperations.push(op);
                    return false;
                  }
                  // Otherwise...
                  return true;
                }
                default:
                  throw new Error(`Unknown mode: ${mode}`);
              }
            })
            .map((op): DeepPartial<Operation> => {
              const { id } = op;
              switch (mode) {
                case 'deselectAll':
                  // We don't need to handle subcontractor case because
                  // subcontractable operations must be checked one by one
                  return { id, completionDate: null, user: null };
                case 'selectAll':
                  // We don't need to handle subcontractor case because
                  // subcontractable operations must be checked one by one
                  return { id, completionDate: Date.now(), user: userLogin };
                default:
                  throw new Error(`Unknown mode: ${mode}`);
              }
            }),
      })
    )
    .filter(({ operations }) => operations?.length);
  // Apply the payload (by default in the repository, see DEFAULT_APPLY_TOGGLE_OPERATIONS_PAYLOAD)
  await actionDispatch.exec(
    applyToggleOperationPayloadAction,
    operatedKanban!.id,
    packageDealsToUpdate
  );

  // Subcontractable operations warning
  if (unprocessedSubcontractableOperations.length > 0) {
    globalActionDispatch.setProperty(
      'message',
      `${t(mode === 'selectAll' ? 'generalView.operations.selectAllWithSubcontractableWarning' : 'generalView.operations.deselectAllWithSubcontractableWarning')}:\n${unprocessedSubcontractableOperations.map((op) => `* ${op.label}`).join('\n')}`
    );
  }
}

export async function updateKanbanAttributeAction(
  { kanbanRepository, getState }: ActionContext<Store, OperatorViewState>,
  attributeId: string
): Promise<void> {
  const { operatedKanban } = getState();
  if (isTruthy(operatedKanban)) {
    const actualKanban = await kanbanRepository.getEntity(operatedKanban.id);
    // If the attribute value has changed, update the attribute in the repository
    if (actualKanban.attributes[attributeId] !== operatedKanban.attributes[attributeId]) {
      await kanbanRepository.updateEntityFromPayload({
        entityId: operatedKanban.id,
        payload: {
          attributes: {
            [attributeId]: operatedKanban.attributes[attributeId],
          },
        },
      });
    }
  }
}
