import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  Attachment,
  AttachmentFolder,
  AttachmentMetadata,
  CarViewCategory,
  Memo,
  MemoDesc,
  PackageDeal,
  StorageCategories,
} from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import {
  CoreBackendRoutes,
  KANBAN_ATTRIBUTES,
  nonDeleted,
  packageDealHelpers,
  sortingHelpers,
  sortRecord,
  URL_LIST_ELEMENTS_SEPARATOR,
} from '@stimcar/libs-base';
import { isTruthy, isTruthyAndNotEmpty, keysOf, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState, useRecordItemSelector } from '@stimcar/libs-uikernel';
import {
  computeMemosToDisplay,
  computeMemoStringValue,
  DisplayContentOrPlaceholder,
  KanbanMobileGeneralInformations,
  OneFolderAttachmentsGallery,
  TruncableLi,
} from '@stimcar/libs-uitoolkit';
import type { ShowHideBoxContainerProps } from '../../lib/components/ShowHideContainer.js';
import type { Store } from '../state/typings/store.js';
import { MobileAttributesComponent } from '../../lib/components/DisplayAttributesComponent.js';
import { GraphicWorkflowWithProgress } from '../../lib/components/GraphicWorkflowWithProgress.js';
import { ShowHideBoxContainer } from '../../lib/components/ShowHideContainer.js';
import {
  convertToPdfAction,
  EXPERTISE_ATTACHMENT_CATEGORY,
  importAttachmentsAction,
  importAttachmentsAndOverrideAction,
  loadAttachmentsGalleryAction,
  removePdfPageAction,
  showRemoveAttachmentConfirmDialogAction,
} from '../utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../utils/useComputeAttachmentUrl.js';
import { useGetContractByCode } from '../utils/useGetContract.js';
import type {
  KanbanDetailsState,
  MemoDetailsInternalDataStructure,
  MobileDetailsSubPartState,
  MobileKanbanDetailsAttachmentState,
} from './typings/store.js';
import { MobileOperationsComponent } from './DisplayAllOperationsComponent.js';
import { MobilePackageDealsComponent } from './DisplayAllPackageDealsComponent.js';
import { MobileHistoryComponent } from './DisplayHistoryComponent.js';
import { MobileKanbanMessageComponent } from './DisplayKanbanMessagesComponent.js';
import { shouldAllowAttachmentDeletion } from './kanbanDetailsUtils.js';
import { MobileDetailsModalMessageDialog } from './MobileDetailsModalMessageDialog.js';

interface DocumentFolderContainerProps extends Omit<ShowHideBoxContainerProps, '$'> {
  readonly $: StoreStateSelector<Store, Record<string, MobileKanbanDetailsAttachmentState>>;
  readonly id: string;
}

function DocumentFolderContainer({
  $,
  id,
  title,
  children,
  titleSize = 5,
  titleTextWeight = 'bold',
  isMobile = false,
}: DocumentFolderContainerProps): JSX.Element {
  const $attachmentFolderState = useRecordItemSelector($, id);
  return (
    <ShowHideBoxContainer
      title={title}
      $={$attachmentFolderState.optChaining().$isExpanded}
      isMobile={isMobile}
      titleSize={titleSize}
      titleTextWeight={titleTextWeight}
    >
      {children}
    </ShowHideBoxContainer>
  );
}

interface Props extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, KanbanDetailsState>;
  readonly documentsFolders: readonly AttachmentFolder[];
  readonly attachmentCategory?: StorageCategories;
}

export function MobileDetailsView({
  $gs,
  $,
  documentsFolders,
  attachmentCategory = EXPERTISE_ATTACHMENT_CATEGORY,
}: Props): JSX.Element {
  const [t] = useTranslation('details');
  const { $mobileState, $attachmentsState, $desktopState } = $;
  const selectedKanban = useGetState($.$selectedKanban);
  const {
    $history,
    $availablePackageDeals,
    $canceledPackageDeals,
    $unachievablePackageDeals,
    $operations,
    $kanbanMessages,
    $attributes,
    $memos,
  } = $mobileState;

  const contract = useGetContractByCode($gs, selectedKanban?.contract.code);

  const workflows = useGetState($gs.$siteConfiguration.$workflows);
  const workflow = useMemo(() => {
    return nonnull(workflows.find((w) => w.id === nonnull(selectedKanban).workflowId));
  }, [workflows, selectedKanban]);

  const activePackageDeals = useMemo(() => {
    return nonnull(selectedKanban).packageDeals.filter(nonDeleted);
  }, [selectedKanban]);

  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    showRemoveAttachmentConfirmDialogAction,
    [],
    $attachmentsState
  );

  const isOnline = useGetState($gs.$session.$isOnline);
  const browserInfos = useGetState($gs.$session.$infos);

  const setLoadingStatusActionCallback = useActionCallback(
    ({ actionDispatch }, status: string | undefined) => {
      actionDispatch.setValue(status);
    },
    [],
    $attachmentsState.$loadingStatus
  );

  const loadAttachmentsAsyncEffect = useActionCallback(
    async ({ actionDispatch, getGlobalState }) => {
      if (isTruthyAndNotEmpty(selectedKanban?.id)) {
        const { session } = getGlobalState();
        if (session.isOnline) {
          const attachmentsStateDispatch = actionDispatch.scopeProperty('attachmentsState');
          try {
            // The following actions are not grouped in one action because we
            // want to flush the state when the loading status changes
            await attachmentsStateDispatch.exec(
              loadAttachmentsGalleryAction,
              CoreBackendRoutes.ATTACHMENT_FOLDER(
                attachmentCategory,
                selectedKanban?.id ?? '',
                documentsFolders.map((f) => f.id).join(URL_LIST_ELEMENTS_SEPARATOR)
              )
            );
          } finally {
            attachmentsStateDispatch.setProperty('loadingStatus', undefined);
          }
        }
        // Set document folder states (isExpanded = false by default)
        const newAttachmentFolderStates = documentsFolders.reduce<
          Record<string, MobileKanbanDetailsAttachmentState>
        >((r, folder) => {
          return {
            ...r,
            [folder.id]: {
              isExpanded: false,
            },
          };
        }, {});
        actionDispatch
          .scopeProperty('mobileState')
          .setProperty('attachmentFolderStates', newAttachmentFolderStates);
      }
    },
    [selectedKanban?.id, attachmentCategory, documentsFolders],
    $
  );

  useEffect((): void => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    setLoadingStatusActionCallback(t('files.gallery.loading'));
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    loadAttachmentsAsyncEffect();
  }, [loadAttachmentsAsyncEffect, setLoadingStatusActionCallback, t]);

  const attachments = useGetState($.$attachmentsState.$attachments);

  const attachmentsCountPerFolder = useMemo((): Record<string, number> => {
    const countPerFolder: Record<string, number> = {};
    attachments.forEach((a) => {
      if (!isTruthy(countPerFolder[a.folder])) {
        countPerFolder[a.folder] = 0;
      }
      countPerFolder[a.folder] += 1;
    });
    return countPerFolder;
  }, [attachments]);

  const updateKanbanIdentityPictureAction = useActionCallback(
    async (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { actionDispatch }: ActionContext<Store, any>,
      category: StorageCategories,
      objectId: string,
      folder: string,
      file: File
    ) => {
      await actionDispatch.exec(importAttachmentsAndOverrideAction, category, objectId, folder, [
        file,
      ]);
    },
    [],
    $attachmentsState
  );

  const displayRemoveAttachmentHandler = useCallback(
    (attachment: Attachment, metadata: AttachmentMetadata | undefined) => {
      return shouldAllowAttachmentDeletion(selectedKanban?.status, attachment, metadata);
    },
    [selectedKanban]
  );

  const { availablePackageDeals, canceledPackageDeals, unachievablePackageDeals } = useMemo((): {
    availablePackageDeals: readonly PackageDeal[];
    canceledPackageDeals: readonly PackageDeal[];
    unachievablePackageDeals: readonly PackageDeal[];
  } => {
    const available = activePackageDeals.filter((pd) =>
      packageDealHelpers.isPackageDealAvailableAndAchievable(pd)
    );
    const canceled = activePackageDeals.filter((pd) =>
      packageDealHelpers.isPackageDealCanceled(pd)
    );
    const unachievable = activePackageDeals.filter(
      (pd) => !packageDealHelpers.isPackageDealAchievable(pd)
    );
    return {
      availablePackageDeals: available,
      canceledPackageDeals: canceled,
      unachievablePackageDeals: unachievable,
    };
  }, [activePackageDeals]);

  const importAttachmentsActionCallback = useActionCallback(importAttachmentsAction, [], $gs);
  const loadGalleryAttachmentsActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsState
  );

  const loadPdfAttachmentContentActionCallback = useActionCallback(
    async (
      { actionDispatch },
      category: StorageCategories,
      objectId: string,
      folders: readonly string[],
      reloadElements?: boolean
    ) => {
      await actionDispatch.exec(
        loadAttachmentsGalleryAction,
        CoreBackendRoutes.ATTACHMENT_FOLDER(
          category,
          objectId,
          folders.join(URL_LIST_ELEMENTS_SEPARATOR)
        ),
        reloadElements
      );
      actionDispatch.setProperty('loadingStatus', undefined);
    },
    [],
    $attachmentsState.$pdfCreationAndUploadModal
  );

  const purchaseOrders = selectedKanban?.purchaseOrders ?? [];

  return (
    <>
      <KanbanMobileGeneralInformations
        kanban={nonnull(selectedKanban)}
        $imageModal={$gs.$imageModal}
        computeUrlCallback={computeAttachmentUrl}
        isOnline={isOnline}
        $expanded={$mobileState.$showAllDetailsModal}
        pictureEditionToolkit={{
          $: $desktopState.$pictureEditionToolkitState,
          updateKanbanIdentityPictureAction,
        }}
      />
      <div>
        <ShowHideBoxContainer
          title={t('tabs.graphicalWorkflowTabTitle')}
          $={$mobileState.$isGraphicWorkflowUnfolded}
          isMobile
        >
          <div className="columns is-mobile">
            <div className="column" />
            <div className="column is-narrow">
              <GraphicWorkflowWithProgress kanban={nonnull(selectedKanban)} workflow={workflow} />
            </div>
            <div className="column" />
          </div>
        </ShowHideBoxContainer>
        <MobileHistoryComponent $={$history} $gs={$gs} kanban={nonnull(selectedKanban)} />
        <MobilePackageDealsComponent
          $={$availablePackageDeals}
          displayedPackageDeals={availablePackageDeals}
          purchaseOrders={purchaseOrders}
          tabHeaderLabelTitleKey="available"
          delegationSite={
            selectedKanban?.attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE] as string | undefined
          }
        />
        {unachievablePackageDeals.length > 0 && (
          <MobilePackageDealsComponent
            $={$unachievablePackageDeals}
            displayedPackageDeals={unachievablePackageDeals}
            purchaseOrders={purchaseOrders}
            tabHeaderLabelTitleKey="unachievable"
            delegationSite={
              selectedKanban?.attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE] as string | undefined
            }
          />
        )}
        <MobilePackageDealsComponent
          $={$canceledPackageDeals}
          displayedPackageDeals={canceledPackageDeals}
          purchaseOrders={purchaseOrders}
          tabHeaderLabelTitleKey="canceled"
          delegationSite={
            selectedKanban?.attributes[KANBAN_ATTRIBUTES.DELEGATION_SITE] as string | undefined
          }
        />
        <MobileOperationsComponent $={$operations} packageDeals={activePackageDeals} />
        <MobileKanbanMessageComponent
          $={$kanbanMessages}
          kanbanMessages={nonnull(selectedKanban).messages}
          kanbanId={nonnull(selectedKanban).id}
        />
        <MobileAttributesComponent
          $={$attributes}
          attributes={nonnull(selectedKanban).attributes ?? {}}
          contractAttributeDescs={nonnull(contract).attributeDescs}
        />
        <MobileMemosComponent
          $={$memos}
          memos={nonnull(selectedKanban).memos ?? {}}
          memoDescs={nonnull(contract).memoDescs}
        />
        {documentsFolders.map((folder): JSX.Element => {
          return (
            <DocumentFolderContainer
              key={folder.id}
              title={
                isTruthy(attachmentsCountPerFolder[folder.id]) &&
                attachmentsCountPerFolder[folder.id] > 0
                  ? t('tabs.files.specificFolderWithActualAttchmentsTitle', {
                      folder: folder.label ?? folder.id,
                      count: attachmentsCountPerFolder[folder.id],
                    })
                  : t('tabs.files.specificEmptyFolderTitle', {
                      folder: folder.label ?? folder.id,
                    })
              }
              $={$.$mobileState.$attachmentFolderStates}
              isMobile
              id={folder.id}
            >
              <OneFolderAttachmentsGallery
                category={attachmentCategory}
                objectId={nonnull(selectedKanban).id}
                $={$attachmentsState}
                $imageModal={$gs.$imageModal}
                computeAttachmentUrl={computeAttachmentUrl}
                loadAttachmentsActionCallback={loadGalleryAttachmentsActionCallback}
                uploadToolkit={{
                  importAttachmentsActionCallback,
                  convertToPdfToolkit: {
                    loadPdfAttachmentContentActionCallback,
                    convertToPdfHttpRequestAction: convertToPdfAction,
                    clientSpecificFolderId: nonnull(browserInfos).label,
                    removePdfPageHttpRequestAction: removePdfPageAction,
                  },
                }}
                removeToolkit={{
                  onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
                  showRemoveAction: displayRemoveAttachmentHandler,
                }}
                isOnline={isOnline}
                theFolder={folder}
              />
            </DocumentFolderContainer>
          );
        })}
      </div>
    </>
  );
}

interface MobileMemosComponentProps {
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<MemoDetailsInternalDataStructure>
  >;
  readonly memos: Record<string, Memo>;
  readonly memoDescs: Record<CarViewCategory, MemoDesc[]>;
  readonly showOnlyEstimateMemos?: boolean;
}

export function MobileMemosComponent({
  $,
  memoDescs,
  memos,
  showOnlyEstimateMemos = false,
}: MobileMemosComponentProps): JSX.Element {
  const [t] = useTranslation('details');

  const internalMemoData = useMemo(() => {
    const memosToDisplay = computeMemosToDisplay(memoDescs, memos, showOnlyEstimateMemos);
    const sortedMemos = sortRecord(memosToDisplay, sortingHelpers.categoryComparatorForMemo);
    return keysOf(sortedMemos).map((k): MemoDetailsInternalDataStructure => {
      return {
        ...sortedMemos[k],
        id: k,
      };
    });
  }, [memoDescs, memos, showOnlyEstimateMemos]);

  const details = useGetState($.$showDetailsFor);

  const emptyContentCallback = useMemo(
    () => internalMemoData.length > 0,
    [internalMemoData.length]
  );

  return (
    <>
      <ShowHideBoxContainer title={t('tabs.memos.title')} $={$.$isUnfolded} isMobile>
        <DisplayContentOrPlaceholder
          displayCondition={emptyContentCallback}
          placeholder={t('tabs.memos.emptyPlaceholder')}
        >
          <ul>
            {internalMemoData.map((memo): JSX.Element => {
              return <MobileMemoLine key={memo.id} $={$} memo={memo} />;
            })}
          </ul>
        </DisplayContentOrPlaceholder>
      </ShowHideBoxContainer>
      <MobileDetailsModalMessageDialog $={$}>
        <>
          <p>
            <strong>{`${details?.id}: `}</strong>
            {computeMemoStringValue(t, details)}
          </p>
          <p>
            <strong>{`${t('tabs.memos.category')}: `}</strong>
            {details?.category}
          </p>
        </>
      </MobileDetailsModalMessageDialog>
    </>
  );
}

interface MobileMemoLineProps {
  readonly memo: MemoDetailsInternalDataStructure;
  readonly $: StoreStateSelector<
    Store,
    MobileDetailsSubPartState<MemoDetailsInternalDataStructure>
  >;
}

function MobileMemoLine({ memo, $ }: MobileMemoLineProps): JSX.Element {
  const [t] = useTranslation('details');
  const actionCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('showDetailsFor', memo);
    },
    [memo],
    $
  );

  return (
    <TruncableLi
      key={memo.id}
      actionToolbox={{ action: actionCallback, className: 'is-rounded is-small is-text' }}
    >
      <span>
        <strong>{`${memo.id}: `}</strong>
        {computeMemoStringValue(t, memo)}
      </span>
    </TruncableLi>
  );
}
