/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line max-classes-per-file
import Datastore from 'nedb';
import { mapRecordValues } from '@stimcar/libs-base';
import { ensureError, keysOf, Logger } from '@stimcar/libs-kernel';
import type {
  Database,
  DatabaseFactory,
  DatabaseStoreDesc,
  DatabaseStoresConfigurator,
  DatabaseTx,
  DatabaseUpgrader,
} from './index.js';
import type { Filesystem } from './typings/fs.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

type NeDbDatabaseStores<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: Datastore;
};

type NeDbDatabaseIndexes<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: { [name: string]: string };
};

type NeDbDatabaseIdKeys<DSD extends DatabaseStoreDesc> = {
  readonly [SN in keyof DSD]: string;
};

interface NeDbDatabaseConfiguration<DSD extends DatabaseStoreDesc> {
  readonly version: number;
  readonly indexes: NeDbDatabaseIndexes<DSD>;
  readonly idKeys: NeDbDatabaseIdKeys<DSD>;
}

function neToDTO(doc: any): any {
  if (!doc) {
    return doc;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { _id, ...dto } = doc;
  return dto;
}

class NeDbDatabaseTx<DSD extends DatabaseStoreDesc> implements DatabaseTx<DSD> {
  private datastores: NeDbDatabaseStores<DSD>;

  private configuration: NeDbDatabaseConfiguration<DSD>;

  public constructor(
    datastores: NeDbDatabaseStores<DSD>,
    configuration: NeDbDatabaseConfiguration<DSD>
  ) {
    this.datastores = datastores;
    this.configuration = configuration;
  }

  // eslint-disable-next-line class-methods-use-this
  public async commit(): Promise<void> {
    // Do nothing...
  }

  // eslint-disable-next-line class-methods-use-this
  public async rollback(): Promise<void> {
    // Do nothing...
  }

  public async getFromIndex<SN extends keyof DSD>(
    storeName: SN,
    indexName: string,
    indexValue: string | number | boolean
  ): Promise<DSD[SN][]> {
    return new Promise((resolve, reject): void => {
      const field = this.configuration.indexes[storeName][indexName];
      const query = {};
      Reflect.set(query, field, indexValue);
      this.datastores[storeName].find(query, { multi: true }, (err, doc): void => {
        if (err) {
          reject(err);
        } else if (!doc) {
          resolve([]);
        } else {
          resolve(doc.map((d: any): any => neToDTO(d)));
        }
      });
    });
  }

  public async get<SN extends keyof DSD>(storeName: SN, id: string): Promise<DSD[SN] | undefined> {
    const result = await this.getN(storeName, id);
    return result.length === 0 ? undefined : result[0];
  }

  public async put<SN extends keyof DSD>(storeName: SN, obj: DSD[SN]): Promise<void> {
    return new Promise((resolve, reject): void => {
      const idKey = this.configuration.idKeys[storeName];
      const objId = Reflect.get(obj, idKey);
      const query = {};
      Reflect.set(query, idKey, objId);
      const datastore = this.datastores[storeName];
      datastore.findOne(query, (findOneErr, doc): void => {
        if (findOneErr) {
          reject(findOneErr);
        } else if (doc) {
          datastore.update(query, obj, {}, (updateErr): void => {
            if (updateErr) {
              reject(updateErr);
            } else {
              resolve();
            }
          });
        } else {
          datastore.insert(obj, (err1): void => {
            if (err1) {
              reject(err1);
            } else {
              resolve();
            }
          });
        }
      });
    });
  }

  public async getAll<SN extends keyof DSD>(storeName: SN): Promise<DSD[SN][]> {
    return new Promise((resolve, reject): void => {
      this.datastores[storeName].find({}, (err: any, doc: any): void => {
        if (err) {
          reject(ensureError(err));
        } else {
          resolve(doc.map((d: any): any => neToDTO(d)));
        }
      });
    });
  }

  public async getN<SN extends keyof DSD>(storeName: SN, ...ids: string[]): Promise<DSD[SN][]> {
    return new Promise((resolve, reject): void => {
      if (ids.length === 0) {
        resolve([]);
      } else {
        const idKey = this.configuration.idKeys[storeName];
        const query = {};
        Reflect.set(query, idKey, ids.length === 1 ? ids[0] : { $in: ids });
        this.datastores[storeName].find(query, (err: any, doc: any): void => {
          if (err) {
            reject(ensureError(err));
          } else if (!doc) {
            resolve([]);
          } else {
            resolve(doc.map((d: any): any => neToDTO(d)));
          }
        });
      }
    });
  }

  public async delete<SN extends keyof DSD>(storeName: SN, id: string): Promise<void> {
    return new Promise((resolve, reject): void => {
      const idKey = this.configuration.idKeys[storeName];
      const query = {};
      Reflect.set(query, idKey, id);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.datastores[storeName].remove(query, (err, n): void => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  public async deleteAll<SN extends keyof DSD>(storeName: SN): Promise<void> {
    return new Promise((resolve, reject): void => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.datastores[storeName].remove({}, { multi: true }, (err, n): void => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

  public async count<SN extends keyof DSD>(storeName: SN): Promise<number> {
    return new Promise((resolve, reject): void => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.datastores[storeName].count({}, (err, count): void => {
        if (err) {
          reject(err);
        } else {
          resolve(count);
        }
      });
    });
  }

  public async exists<SN extends keyof DSD>(storeName: SN, id: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject): void => {
      const idKey = this.configuration.idKeys[storeName];
      const query = {};
      Reflect.set(query, idKey, id);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.datastores[storeName].count(query, (err, count): void => {
        if (err) {
          reject(err);
        } else {
          resolve(count > 0);
        }
      });
    });
  }

  public getStoreNames = (): readonly (keyof DSD)[] => keysOf(this.datastores);
}

abstract class AbstractNeDbDatabaseFactoryImpl implements DatabaseFactory {
  protected databases: Record<string, Database<any>> = {};

  abstract create<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<Database<DSD>>;

  protected handleUpgradeAndUpdateConfiguration<DSD extends DatabaseStoreDesc>(
    version: number,
    upgrade: DatabaseUpgrader<DSD>,
    configuration: NeDbDatabaseConfiguration<DSD>,
    datastores: NeDbDatabaseStores<DSD>,
    datastoreOptionsProvider: DataStoreOptionsProvider<DSD>
  ): void {
    // Perform upgrade if needed (collections & indexes creations)
    if (version > configuration.version) {
      const configurator: DatabaseStoresConfigurator<DSD> = {
        // eslint-disable-next-line @typescript-eslint/no-misused-promises, @typescript-eslint/require-await
        createObjectStore: async (storeName: keyof DSD, idKey: string): Promise<void> => {
          Reflect.set(datastores, storeName, new Datastore(datastoreOptionsProvider(storeName)));
          Reflect.set(configuration.indexes, storeName, {});
          Reflect.set(configuration.idKeys, storeName, idKey);
        },
        // eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/no-misused-promises
        createIndex: async (
          storeName: keyof DSD,
          indexName: string,
          keyPath: string
        ): Promise<void> => {
          const store = Reflect.get(datastores, storeName);
          Reflect.set(configuration.indexes[storeName], indexName, keyPath);
          store.ensureIndex({ fieldName: keyPath });
        },
        deleteIndex: (storeName: keyof DSD, indexName: string, keyPath: string): void => {
          const store = Reflect.get(datastores, storeName);
          Reflect.deleteProperty(configuration.indexes[storeName], indexName);
          store.removeIndex(keyPath);
        },
      };
      upgrade(configurator, configuration.version, version);
      // Update current version
      Reflect.set(configuration, 'version', version);
    }
  }

  public async removeAllData(): Promise<void> {
    await Promise.all(
      mapRecordValues(this.databases, async (database: Database<any>): Promise<void> => {
        const tx = database.beginTx() as NeDbDatabaseTx<any>;
        await Promise.all(
          tx.getStoreNames().map(async (storeName): Promise<void> => tx.deleteAll(storeName))
        );
      })
    );
  }
}
type DataStoreOptionsProvider<DSD extends DatabaseStoreDesc> = (
  storeName: keyof DSD
) => Nedb.DataStoreOptions;

class NeDbDatabaseImpl<DSD extends DatabaseStoreDesc> implements Database<DSD> {
  protected datastores: NeDbDatabaseStores<DSD>;

  protected configuration: NeDbDatabaseConfiguration<DSD>;

  public constructor(
    configuration: NeDbDatabaseConfiguration<DSD>,
    datastores: NeDbDatabaseStores<DSD>
  ) {
    this.datastores = datastores;
    this.configuration = configuration;
  }

  public beginTx(): DatabaseTx<DSD> {
    return new NeDbDatabaseTx<DSD>(this.datastores, this.configuration);
  }
}

export class NeDbDatabaseFactoryImpl extends AbstractNeDbDatabaseFactoryImpl {
  private fsImpl: Filesystem;

  private path: string;

  public constructor(fsImpl: Filesystem, path: string) {
    super();
    this.fsImpl = fsImpl;
    this.path = path;
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  public async create<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<Database<DSD>> {
    const { fsImpl, path } = this;
    const baseDir = `${path}/${databaseName}`;

    const datastores: NeDbDatabaseStores<DSD> = {} as any;
    let configuration: NeDbDatabaseConfiguration<DSD> = {
      version: 0,
      indexes: {} as any,
      idKeys: {} as any,
    };

    // Create directory if it doesn't exist
    if (!fsImpl.existsSync(baseDir)) {
      fsImpl.mkdirSync(baseDir);
    }

    // Check that the folder exists
    if (!fsImpl.existsSync(baseDir)) {
      throw new Error(`Database directory '${baseDir}' does not exist`);
    } else if (!fsImpl.lstatSync(baseDir).isDirectory()) {
      throw new Error(`Database path '${baseDir}' is not a directory`);
    }

    // Load configuration if it exists
    const cfgFile = `${baseDir}/config.txt`;
    if (fsImpl.existsSync(cfgFile)) {
      const contents = fsImpl.readFileSync(cfgFile, 'utf8');
      configuration = JSON.parse(contents);
    }

    const datastoreOptionsProvider: DataStoreOptionsProvider<DSD> = (
      storeName: keyof DSD
    ): Nedb.DataStoreOptions => {
      return {
        filename: `${baseDir}/${String(storeName)}.db`,
      };
    };

    // Load existing stores
    fsImpl
      .readdirSync(baseDir)
      .filter((file) => file.endsWith('.db'))
      .forEach((databaseFile) => {
        const storeName = databaseFile.replace(/^.*\//, '').replace(/.db$/, '');
        Reflect.set(datastores, storeName, new Datastore(datastoreOptionsProvider(storeName)));
      });

    // Perform upgrade if needed (collections & indexes creations)
    this.handleUpgradeAndUpdateConfiguration(
      version,
      upgrade,
      configuration,
      datastores,
      datastoreOptionsProvider
    );
    // Save configuration
    fsImpl.writeFileSync(cfgFile, JSON.stringify(configuration, null, 2));

    // Load databases
    await Promise.all(
      keysOf(datastores).map(async (k): Promise<void> => {
        return new Promise((resolve, reject): void => {
          datastores[k].loadDatabase((err: Error | null): void => {
            if (err) {
              reject(err);
            } else {
              resolve();
            }
          });
        });
      })
    );
    const db = new NeDbDatabaseImpl(configuration, datastores);
    this.databases[databaseName] = db;
    return db;
  }
}

class NeDbInMemoryDatabaseFactoryImpl extends AbstractNeDbDatabaseFactoryImpl {
  // eslint-disable-next-line @typescript-eslint/require-await
  public async create<DSD extends DatabaseStoreDesc>(
    databaseName: string,
    version: number,
    upgrade: DatabaseUpgrader<DSD>
  ): Promise<Database<DSD>> {
    const datastores: NeDbDatabaseStores<DSD> = {} as any;
    const configuration: NeDbDatabaseConfiguration<DSD> = {
      version: 0,
      indexes: {} as any,
      idKeys: {} as any,
    };
    const datastoreOptionsProvider: DataStoreOptionsProvider<DSD> = (
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      storeName: keyof DSD
    ): Nedb.DataStoreOptions => {
      return { inMemoryOnly: true };
    };
    // Perform upgrade if needed (collections & indexes creations)
    this.handleUpgradeAndUpdateConfiguration(
      version,
      upgrade,
      configuration,
      datastores,
      datastoreOptionsProvider
    );
    const db = new NeDbDatabaseImpl(configuration, datastores);
    this.databases[databaseName] = db;
    return db;
  }
}

export const IN_MEMORY_NEDB_DATABASE_FACTORY: DatabaseFactory =
  new NeDbInMemoryDatabaseFactoryImpl();
