/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { PackageDeal, PurchaseOrder } from '@stimcar/libs-base';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { nonDeleted, packageDealHelpers, uniqueValues } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { FaIcon, RawSwitch, TableActionHeaderCell } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import {
  getPackageDealsWithPurchaseOrder,
  updatePackageExpressionComputationsFromFormData,
} from '../createKanbanUtils.js';

type PackageDealWithPurchaseNumber = PackageDeal & {
  readonly purchaseNumber: string | null;
};

/**
 * Sort the values first by the purchaseNumber, then by package deal's label
 * @param values
 */
function sortPackageDealsWithPurchaseNumber(
  values: readonly PackageDealWithPurchaseNumber[]
): readonly PackageDealWithPurchaseNumber[] {
  return [...values].sort(
    (
      { purchaseNumber: purchaseNumber1, label: pkgLabel1 },
      { purchaseNumber: purchaseNumber2, label: pkgLabel2 }
    ) => {
      // First compare purchase number
      const comparePurchaseNumber = (purchaseNumber1 ?? '').localeCompare(purchaseNumber2 ?? '');
      if (comparePurchaseNumber === 0) {
        // Same purchase number, we compare package deals labels
        return pkgLabel1.localeCompare(pkgLabel2);
      }
      return comparePurchaseNumber;
    }
  );
}

function getPackageDealsWithPurchaseNumber(
  packageDeals: readonly PackageDeal[],
  purchaseOrders: readonly PurchaseOrder[]
): readonly PackageDealWithPurchaseNumber[] {
  const pkgDealsWithPurchaseOrder = getPackageDealsWithPurchaseOrder(packageDeals, purchaseOrders);
  return pkgDealsWithPurchaseOrder.map(({ packageDeal, purchaseOrder }) => ({
    ...packageDeal,
    purchaseNumber: isTruthy(purchaseOrder) ? purchaseOrder.purchaseNumber : null,
  }));
}

export function CreationPackageDealsComponent({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('creation');
  const $ = $gs.$creationView;

  const packageDeals = useGetState($.$packageDeals);
  const purchaseOrders = useGetState($.$newPurchaseOrders);

  const packageDealsWithPurchaseNumber = useMemo(
    (): readonly PackageDealWithPurchaseNumber[] =>
      sortPackageDealsWithPurchaseNumber(
        getPackageDealsWithPurchaseNumber(packageDeals, purchaseOrders)
      ),
    [packageDeals, purchaseOrders]
  );

  const showPurchaseOrder = useMemo(
    (): boolean =>
      uniqueValues(
        packageDealsWithPurchaseNumber.map(({ purchaseNumber }) => purchaseNumber).filter(isTruthy)
      ).length > 1,
    [packageDealsWithPurchaseNumber]
  );

  const totalCost = useMemo(() => {
    const activePkgDeals = packageDealsWithPurchaseNumber.filter((pkgDeal) => {
      return packageDealHelpers.isPackageDealAvailable(pkgDeal, true);
    });
    return activePkgDeals.reduce((sum, { price }) => sum + price, 0);
  }, [packageDealsWithPurchaseNumber]);

  const togglePackageDealPrice = useActionCallback(
    ({ actionDispatch, getGlobalState }, packageDealId: string) => {
      const { contracts } = getGlobalState();
      actionDispatch.reduce((initial) => {
        const newPackageDeals = initial.packageDeals.map((pd): PackageDeal => {
          if (pd.id === packageDealId) {
            return {
              ...pd,
              price: 0,
              priceIsOverridden: !pd.priceIsOverridden,
            };
          }
          return pd;
        });
        const { formData, attributesState, parentKanbanId, newPurchaseOrders } = initial;
        return {
          ...initial,
          packageDeals: updatePackageExpressionComputationsFromFormData(
            contracts,
            formData,
            newPackageDeals,
            newPurchaseOrders,
            attributesState,
            parentKanbanId
          ),
        };
      });
    },
    [],
    $
  );

  const deleteRestoreCallback = useActionCallback(
    ({ actionDispatch, getGlobalState }, packageDealId: string) => {
      const { contracts } = getGlobalState();
      actionDispatch.reduce((initial) => {
        const newPackageDeals = initial.packageDeals.map((pd): PackageDeal => {
          if (pd.id === packageDealId) {
            return {
              ...pd,
              deleted: !pd.deleted,
            };
          }
          return pd;
        });
        const { formData, attributesState, parentKanbanId, newPurchaseOrders } = initial;
        return {
          ...initial,
          packageDeals: updatePackageExpressionComputationsFromFormData(
            contracts,
            formData,
            newPackageDeals,
            newPurchaseOrders,
            attributesState,
            parentKanbanId
          ),
        };
      });
    },
    [],
    $
  );

  return (
    <div className="box">
      <p className="title">{t('packageDeals')}</p>
      <table className="table is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            {showPurchaseOrder && <th>{t('packageDealTable.purchaseOrderHeader')}</th>}
            <th>{t('packageDealTable.labelHeader')}</th>
            <th style={{ width: '10%' }}>{t('packageDealTable.priceHeader')}</th>
            <th style={{ width: '10%' }}>{t('packageDealTable.freeHeader')}</th>
            <TableActionHeaderCell />
          </tr>
        </thead>
        <tbody>
          {packageDealsWithPurchaseNumber.map(
            (pkgDealWithNumber: PackageDealWithPurchaseNumber): JSX.Element => {
              const key = `${pkgDealWithNumber.code}-${pkgDealWithNumber.purchaseOrderId ?? ''}`;
              return (
                <PackageDealLine
                  key={key}
                  packageDealWithNumber={pkgDealWithNumber}
                  togglePackageDealDeletion={deleteRestoreCallback}
                  togglePackageDealPrice={togglePackageDealPrice}
                  showPurchaseOrder={showPurchaseOrder}
                />
              );
            }
          )}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={1} className="has-text-right">
              <strong>{t('packageDealTable.totalPriceLabel')}</strong>
            </td>
            <td className="has-text-right">
              {t('packageDealTable.price', { price: totalCost.toFixed(2) })}
            </td>
            <td colSpan={2} />
          </tr>
        </tfoot>
      </table>
    </div>
  );
}

interface PackageDealLineProps {
  readonly packageDealWithNumber: PackageDealWithPurchaseNumber;
  readonly togglePackageDealPrice: (id: string) => void | Promise<void>;
  readonly togglePackageDealDeletion: (id: string) => void | Promise<void>;
  readonly showPurchaseOrder: boolean;
}

function PackageDealLine({
  packageDealWithNumber,
  togglePackageDealPrice,
  togglePackageDealDeletion,
  showPurchaseOrder,
}: PackageDealLineProps): JSX.Element {
  const [t] = useTranslation('creation');

  const togglePackageDealPriceCallback = useCallback(async () => {
    await togglePackageDealPrice(packageDealWithNumber.id);
  }, [packageDealWithNumber.id, togglePackageDealPrice]);

  const togglePackageDealDeletionCallback = useCallback(async () => {
    await togglePackageDealDeletion(packageDealWithNumber.id);
  }, [packageDealWithNumber.id, togglePackageDealDeletion]);

  const hidePriceSwitch = (): boolean => {
    return (
      (packageDealWithNumber.price === 0 && !packageDealWithNumber.priceIsOverridden) ||
      !packageDealWithNumber.overridablePrice
    );
  };

  const isDeleted = !nonDeleted(packageDealWithNumber);

  return (
    <tr className={isDeleted ? 'strikeout' : ''}>
      {showPurchaseOrder && <td>{packageDealWithNumber.purchaseNumber}</td>}
      <td>{packageDealHelpers.getPackageDealDisplayedLabel(packageDealWithNumber)}</td>
      <td className="has-text-right">
        {t('packageDealTable.price', {
          price: packageDealWithNumber.price.toFixed(2),
        })}
      </td>
      <td className="has-text-right">
        {!hidePriceSwitch() && (
          <RawSwitch
            className="is-small"
            disabled={isDeleted}
            switchId={packageDealWithNumber.id}
            isChecked={packageDealWithNumber.priceIsOverridden}
            toggleAction={togglePackageDealPriceCallback}
          />
        )}
      </td>
      <td className="is-narrow">
        {!packageDealHelpers.isPackageDealUnremovable(packageDealWithNumber.code) && (
          <button
            type="button"
            className="button is-small is-white"
            title="update"
            onClick={togglePackageDealDeletionCallback}
          >
            <FaIcon
              id={isDeleted ? 'trash-restore' : 'trash'}
              tooltip={isDeleted ? t('restorePackageDealTooltip') : t('deletePackageDealTooltip')}
            />
          </button>
        )}
      </td>
    </tr>
  );
}
