import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type {
  AirtableConnectorArgs,
  CarsExpectedAtStandMailNotificationArgs,
  ColumnToDestinationDef,
  CustomersBasedMailNotificationArgs,
  DailyProdMailNotificationArgs,
  InvoiceMailNotificationArgs,
  MailNotificationArgs,
  ScheduledTask,
  ScheduledTaskSchedule,
  ScheduledTaskType,
  SparePartsMailNotificationArgs,
  VOLeanConnectorArgs,
} from '@stimcar/libs-base';
import type {
  ActionContext,
  StoreStateSelector,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type {
  AppProps,
  CheckFormFieldContentActions,
  HorizontalFormFieldProps,
} from '@stimcar/libs-uitoolkit';
import { CoreBackendRoutes, scheduledTaskHelpers } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty, nonnull } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import { ModalCardDialog, useFormWithValidation } from '@stimcar/libs-uitoolkit';
import type { LabelledEntity } from '../../../lib/components/LabelledEntityList.js';
import type { Store } from '../../state/typings/store.js';
import type {
  AdminScheduledTasksState,
  AirtableConnectorArgsData,
  CreateScheduledTaskData,
  CreateScheduledTaskDialogState,
  OnScheduledTaskChangeActionCallback,
  ScheduledTaskIdentityData,
  ScheduledTaskScheduleData,
  UpdateImportAirtableDialogState,
  VOLeanConnectorArgsData,
} from './typings/store.js';
import {
  checkEmailFieldContentActions,
  checkIdentityFieldContentActions,
  checkInvoiceEmailFieldContentActions,
  checkLabelFieldContentActions,
  checkScheduleFieldContentActions,
  getActualCustomerShortnames,
  getActualScheduleDays,
  getArgumentsMandatoryFields,
  getScheduledTaskSchedule,
  SCHEDULE_MANDATORY_FIELDS,
} from './adminScheduledTasksUtils.js';
import { ArgumentsInputsForScheduledTaskCreation } from './ArgumentsInputsForScheduledTaskCreation.js';
import { ImportAirtableInputs } from './inputs/ImportAirtableInputs.js';
import { ScheduledTaskIdentityInputs } from './inputs/ScheduledTaskIdentityInputs.js';
import { ScheduledTaskScheduleInputs } from './inputs/ScheduledTaskScheduleInputs.js';
import { EMPTY_CREATE_SCHEDULED_TASK_DIALOG_STATE } from './typings/store.js';

const BASE_MANDATORY_FIELDS: (keyof (ScheduledTaskIdentityData & ScheduledTaskScheduleData))[] = [
  'type',
  'label',
  'scheduledWeekDays',
  'scheduledTaskActive',
];

function getArgumentsFieldContentActions(
  cronTaskType: ScheduledTaskType
): CheckFormFieldContentActions<Store, CreateScheduledTaskDialogState, []> | undefined {
  if (!isTruthyAndNotEmpty(cronTaskType)) {
    return undefined;
  }
  switch (cronTaskType) {
    case 'voLeanImport':
    case 'airtableImport':
      return undefined;
    case 'ctmail':
    case 'dailyprodmail':
    case 'sparepartsmail':
    case 'carsexpectedatstandmail':
      return checkEmailFieldContentActions;
    case 'invoiceMail':
      return { ...checkEmailFieldContentActions, ...checkInvoiceEmailFieldContentActions };
    default:
      throw Error('Unexpected cron task type');
  }
}

async function createScheduledTaskAction(
  { getState, httpClient, actionDispatch }: ActionContext<Store, AdminScheduledTasksState>,
  onScheduledTaskChangeActionCallback: OnScheduledTaskChangeActionCallback
): Promise<void> {
  const { formData, siteId } = getState().createScheduledTaskDialogState;
  const { type, label, scheduledTaskActive } = formData;
  const { scheduledWeekDays, scheduledHours, scheduledMinutes, asOftenAsPossibleWithinADay } =
    formData;
  const scheduledTaskScheduleData: ScheduledTaskScheduleData = {
    scheduledWeekDays: getActualScheduleDays(scheduledWeekDays),
    scheduledHours,
    scheduledMinutes,
    asOftenAsPossibleWithinADay,
  };
  const schedule: ScheduledTaskSchedule = getScheduledTaskSchedule(scheduledTaskScheduleData);
  const taskToCreateWithoutArgs: Omit<ScheduledTask, 'id' | 'cronArgs'> = {
    type,
    label,
    schedule,
    active: scheduledTaskActive,
  };
  let taskToCreate: Omit<ScheduledTask, 'id'>;
  if (scheduledTaskHelpers.isEmailScheduledTaskType(type)) {
    const {
      to,
      cc,
      replyTo,
      subject,
      customerShortNames,
      customersGroupName,
      overrideDefaultSubject,
    } = formData as WithFormValidationWarnings<CreateScheduledTaskData>;
    const mailNotificationArgs: MailNotificationArgs = {
      to,
      cc,
      replyTo: isTruthyAndNotEmpty(replyTo) ? replyTo : undefined,
      subject: overrideDefaultSubject ? subject : undefined,
    };
    const customersBasedMailNotificationArgs: CustomersBasedMailNotificationArgs = {
      ...mailNotificationArgs,
      customerShortNames: getActualCustomerShortnames(customerShortNames),
      customersGroupName,
    };
    let actualEmailCronArgs:
      | CustomersBasedMailNotificationArgs
      | InvoiceMailNotificationArgs
      | DailyProdMailNotificationArgs
      // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
      | SparePartsMailNotificationArgs
      | CarsExpectedAtStandMailNotificationArgs;
    switch (type) {
      case 'invoiceMail':
        actualEmailCronArgs = {
          ...mailNotificationArgs,
          customerShortNames,
        };
        break;
      case 'ctmail':
      case 'sparepartsmail':
        actualEmailCronArgs = customersBasedMailNotificationArgs;
        break;
      case 'dailyprodmail': {
        const { standId, displayEstimateInfos, displayAcceptanceInfos } =
          formData as WithFormValidationWarnings<CreateScheduledTaskData>;
        actualEmailCronArgs = {
          ...customersBasedMailNotificationArgs,
          standId,
          displayEstimateInfos,
          displayAcceptanceInfos,
        };
        break;
      }
      case 'carsexpectedatstandmail': {
        const { standId, mode, tagsFilteringPkgs } =
          formData as WithFormValidationWarnings<CreateScheduledTaskData>;
        actualEmailCronArgs = {
          ...customersBasedMailNotificationArgs,
          standId,
          mode,
          tagsFilteringPkgs,
        };
        break;
      }
      default:
        throw Error('Unknown email task type.');
    }
    taskToCreate = {
      ...taskToCreateWithoutArgs,
      scheduledTaskArgs: actualEmailCronArgs,
    };
  } else if (type === 'airtableImport') {
    const {
      tableNames,
      contractCode,
      token,
      databaseId,
      databaseName,
      workflowId,
      customerAsFieldMode,
      to,
      cc,
      replyTo,
      subject,
      overrideDefaultSubject,
    } = formData as WithFormValidationWarnings<
      ScheduledTaskIdentityData & ScheduledTaskScheduleData & AirtableConnectorArgsData
    >;

    const mailNotificationArgs: MailNotificationArgs | undefined =
      to.length > 0
        ? {
            to,
            cc,
            replyTo: isTruthyAndNotEmpty(replyTo) ? replyTo : undefined,
            subject: overrideDefaultSubject ? subject : undefined,
          }
        : undefined;

    const {
      customColumnDialogState,
      documentColumnsToDownloadState,
      textToDocumentColumnsToDownloadState,
      textColumnsToAttributesState,
    } = getState().createScheduledTaskDialogState as UpdateImportAirtableDialogState;

    const airtableImportCronArgument: AirtableConnectorArgs = {
      tableNames,
      contractCode,
      token,
      databaseId,
      databaseName,
      workflowId,
      customerAsFieldMode,
      packageDealColumnsToHandle: customColumnDialogState.customColumns,
      importErrorMail: mailNotificationArgs,
      documentColumnsToDownload: documentColumnsToDownloadState.columnsToDestinations,
      textToDocumentColumnsToDownload: textToDocumentColumnsToDownloadState.columnsToDestinations,
      textColumnsToAttributes: textColumnsToAttributesState.columnsToDestinations,
    };
    taskToCreate = {
      ...taskToCreateWithoutArgs,
      scheduledTaskArgs: airtableImportCronArgument,
    };
  } else if (type === 'voLeanImport') {
    const {
      contractCode,
      workflowId,
      voLeanClientId,
      voLeanPole,
      enablePkgDealsModifications,
      qualityControlInVOLean,
      to,
      cc,
      replyTo,
      subject,
      overrideDefaultSubject,
    } = formData as WithFormValidationWarnings<
      ScheduledTaskIdentityData & ScheduledTaskScheduleData & VOLeanConnectorArgsData
    >;
    const mailNotificationArgs: MailNotificationArgs | undefined =
      to.length > 0
        ? {
            to,
            cc,
            replyTo: isTruthyAndNotEmpty(replyTo) ? replyTo : undefined,
            subject: overrideDefaultSubject ? subject : undefined,
          }
        : undefined;
    const voLeanImportCronArgument: VOLeanConnectorArgs = {
      contractCode,
      workflowId,
      voLeanClientId,
      voLeanPole,
      enablePkgDealsModifications,
      qualityControlInVOLean,
      importErrorMail: mailNotificationArgs,
    };
    taskToCreate = {
      ...taskToCreateWithoutArgs,
      scheduledTaskArgs: voLeanImportCronArgument,
    };
  } else {
    throw Error(`Unexpected task type: ${type}`);
  }
  const id = await httpClient.httpPostAsJSON(CoreBackendRoutes.CREATE_SCHEDULED_TASK, {
    siteId,
    taskToCreate,
  });
  await actionDispatch.execCallback(
    onScheduledTaskChangeActionCallback,
    [{ ...taskToCreate, id: String(nonnull(id)) }],
    []
  );
  actionDispatch.setProperty(
    'createScheduledTaskDialogState',
    EMPTY_CREATE_SCHEDULED_TASK_DIALOG_STATE
  );
}

export function CreateNonAirtableScheduledTaskModal({
  $,
  $gs,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
}: CreateScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const $createScheduledTaskDialogState = $.$createScheduledTaskDialogState as StoreStateSelector<
    Store,
    CreateScheduledTaskDialogState
  >;
  const formWarning = useGetState($createScheduledTaskDialogState.$formWarning);
  const allCustomerSummaries = useGetState($createScheduledTaskDialogState.$allCustomerSummaries);
  const allStandIds = useGetState($createScheduledTaskDialogState.$allStandIds);
  const allWorkflowIds = useGetState($createScheduledTaskDialogState.$allWorkflowIds);
  const type = useGetState($createScheduledTaskDialogState.$formData.$type);
  const overrideDefaultSubject = useGetState(
    $createScheduledTaskDialogState.$formData.$overrideDefaultSubject
  );
  const customerShortNames = useGetState(
    $createScheduledTaskDialogState.$formData.$customerShortNames
  );

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(createScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const mandatoryFields = useMemo((): (keyof CreateScheduledTaskData)[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...getArgumentsMandatoryFields(type, overrideDefaultSubject, customerShortNames),
    ];
  }, [customerShortNames, overrideDefaultSubject, type]);

  const checkFieldContentActions = useMemo(():
    | CheckFormFieldContentActions<Store, CreateScheduledTaskDialogState, []>
    | undefined => {
    return {
      ...checkIdentityFieldContentActions,
      ...checkScheduleFieldContentActions,
      ...checkLabelFieldContentActions,
      ...getArgumentsFieldContentActions(type),
    };
  }, [type]);

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    CreateScheduledTaskDialogState
  >({
    $: $createScheduledTaskDialogState,
    mandatoryFields,
    checkFieldContentActions,
    submitValidDataAction,
    t,
  });

  const allCustomerShortnames = useMemo(() => {
    return allCustomerSummaries.map((c) => c.shortName);
  }, [allCustomerSummaries]);

  return (
    <ModalCardDialog
      $active={$createScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('createTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <ScheduledTaskIdentityInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <ArgumentsInputsForScheduledTaskCreation
        $formData={$formDataWithChangeTrigger}
        $gs={$gs}
        type={type}
        allCustomerShortnames={allCustomerShortnames}
        standIds={allStandIds ?? []}
        allWorkflowIds={allWorkflowIds ?? []}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

function convertColumnsToDestinationsToEntities(
  columnsToDestinations: readonly ColumnToDestinationDef[]
) {
  return columnsToDestinations.map(({ id, destination }) => ({
    id,
    label: `${id} => ${destination}`,
  }));
}

export function CreateAirtableScheduledTaskModal({
  $,
  $gs,
  horizontalFormFields,
  onScheduledTaskChangeActionCallback,
}: CreateScheduledTaskProps): JSX.Element {
  const [t] = useTranslation('adminScheduledTasks');
  const $createScheduledTaskDialogState = $.$createScheduledTaskDialogState as StoreStateSelector<
    Store,
    UpdateImportAirtableDialogState
  >;
  const formWarning = useGetState($createScheduledTaskDialogState.$formWarning);
  const type = useGetState($createScheduledTaskDialogState.$formData.$type);
  const customColumns = useGetState(
    $createScheduledTaskDialogState.$customColumnDialogState.$customColumns
  );
  const documentColumnsToDownload = useGetState(
    $createScheduledTaskDialogState.$documentColumnsToDownloadState.$columnsToDestinations
  );
  const textToDocColumnsToDownload = useGetState(
    $createScheduledTaskDialogState.$textToDocumentColumnsToDownloadState.$columnsToDestinations
  );
  const allWorkflowIds = useGetState($createScheduledTaskDialogState.$allWorkflowIds);
  const allCustomerSummaries = useGetState($createScheduledTaskDialogState.$allCustomerSummaries);
  const textColumnsToAttributes = useGetState(
    $createScheduledTaskDialogState.$textColumnsToAttributesState.$columnsToDestinations
  );

  const mandatoryFields = useMemo((): (
    | keyof ScheduledTaskIdentityData
    | keyof ScheduledTaskScheduleData
    | keyof AirtableConnectorArgsData
  )[] => {
    return [
      ...BASE_MANDATORY_FIELDS,
      ...SCHEDULE_MANDATORY_FIELDS,
      ...(getArgumentsMandatoryFields(
        type,
        false,
        undefined
      ) as (keyof AirtableConnectorArgsData)[]),
    ];
  }, [type]);

  const submitValidDataAction = useActionCallback(
    async ({ actionDispatch }) =>
      await actionDispatch.exec(createScheduledTaskAction, onScheduledTaskChangeActionCallback),
    [onScheduledTaskChangeActionCallback],
    $
  );

  const [onFormSubmit, , $formDataWithChangeTrigger] = useFormWithValidation<
    Store,
    UpdateImportAirtableDialogState
  >({
    $: $createScheduledTaskDialogState,
    mandatoryFields,
    checkFieldContentActions: checkLabelFieldContentActions,
    submitValidDataAction,
    t,
  });

  const labelledEntities = useMemo(() => {
    return customColumns.map((o): LabelledEntity => {
      return {
        label: o.id,
        ...o,
      };
    });
  }, [customColumns]);

  const documentColumnsToDownloadAsEntities = useMemo(
    (): LabelledEntity[] => convertColumnsToDestinationsToEntities(documentColumnsToDownload),
    [documentColumnsToDownload]
  );

  const textToDocColumnsToDownloadAsEntities = useMemo(
    (): LabelledEntity[] => convertColumnsToDestinationsToEntities(textToDocColumnsToDownload),
    [textToDocColumnsToDownload]
  );

  const textColumnsToAttributesAsEntities = useMemo(
    (): LabelledEntity[] => convertColumnsToDestinationsToEntities(textColumnsToAttributes),
    [textColumnsToAttributes]
  );

  return (
    <ModalCardDialog
      $active={$createScheduledTaskDialogState.$active}
      titleIconId="exclamation-triangle"
      title={t('updateTitle')}
      onOkClicked={onFormSubmit}
      warning={formWarning}
    >
      <ScheduledTaskIdentityInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <ScheduledTaskScheduleInputs
        $formData={$formDataWithChangeTrigger}
        horizontalFormFields={horizontalFormFields}
      />
      <ImportAirtableInputs
        $={$createScheduledTaskDialogState}
        $gs={$gs}
        $formData={$formDataWithChangeTrigger}
        allCustomerSummaries={allCustomerSummaries}
        allWorkflowIds={allWorkflowIds ?? []}
        packageDealColumnsToHandleEntries={labelledEntities}
        documentColumnsToDownloadEntries={documentColumnsToDownloadAsEntities}
        textToDocColumnsToDownloadEntries={textToDocColumnsToDownloadAsEntities}
        textColumnsToAttributesEntries={textColumnsToAttributesAsEntities}
        horizontalFormFields={horizontalFormFields}
      />
    </ModalCardDialog>
  );
}

export interface CreateScheduledTaskProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, AdminScheduledTasksState>;
  readonly horizontalFormFields?: boolean | HorizontalFormFieldProps;
  readonly onScheduledTaskChangeActionCallback: OnScheduledTaskChangeActionCallback;
}

export function CreateScheduledTaskModal({
  $,
  $gs,
  onScheduledTaskChangeActionCallback,
  horizontalFormFields,
}: CreateScheduledTaskProps): JSX.Element {
  const type = useGetState($.$createScheduledTaskDialogState.$formData.$type);
  if (type === 'airtableImport') {
    return (
      <CreateAirtableScheduledTaskModal
        $={$}
        $gs={$gs}
        horizontalFormFields={horizontalFormFields}
        onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
      />
    );
  }
  return (
    <CreateNonAirtableScheduledTaskModal
      $={$}
      $gs={$gs}
      horizontalFormFields={horizontalFormFields}
      onScheduledTaskChangeActionCallback={onScheduledTaskChangeActionCallback}
    />
  );
}
