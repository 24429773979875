import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Workflow } from '@stimcar/libs-base';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import type {
  CheckFormFieldContentActions,
  FormFieldEntry,
  FormWithValidationState,
  HorizontalFormFieldProps,
  ImageModalState,
} from '@stimcar/libs-uitoolkit';
import { AVAILABLE_BRANDS } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import {
  CalendarFormField,
  FormField,
  InputFormField,
  isIntegerCheck,
  KanbanIdentityPictureComponent,
  RadioButtonsFormField,
  ReactSelectFormField,
  SelectFormField,
  TextAreaFormField,
} from '@stimcar/libs-uitoolkit';
import type { CreateKanbanViewState } from '../../creation/typings/store.js';
import type { Store } from '../../state/typings/store.js';
import { useComputeAttachmentUrlFromSessionToken } from '../useComputeAttachmentUrl.js';
import type { GeneralInformationsFormData } from './typings/store.js';

const CONTRACT_WORKFLOW_ID_BRAND_MANDATORY_FIELDS: (keyof GeneralInformationsFormData)[] = [
  'contractCode',
  'workflowId',
  'brand',
];

const COMMON_MANDATORY_FIELDS: (keyof GeneralInformationsFormData)[] = [
  'license',
  'vin',
  'dateOfRegistration',
  'brand',
  'model',
];

export const checkEditGeneralInformationsFormFieldContentActions: CheckFormFieldContentActions<
  AnyStoreDef,
  FormWithValidationState<GeneralInformationsFormData>
> = {
  // eslint-disable-next-line @typescript-eslint/require-await
  dateOfRegistration: ({ value, t }): string | undefined => {
    if (!isTruthy(value)) {
      return t('refititCommonComponents:editGeneralInformationsForm.warnings.dateIncorrectFormat');
    }
    return undefined;
  },
  mileage: ({ value, t }): string | undefined => {
    return isIntegerCheck(value, t);
  },
  refitEndDate: ({ value, t, formState }): string | undefined => {
    const { dueDate } = formState.formData;
    return isTruthy(dueDate) && isTruthy(value) && dueDate < value
      ? t(
          'refititCommonComponents:editGeneralInformationsForm.warnings.dueDateAndRefitEndDateInconsistent'
        )
      : undefined;
  },
};

export const editGeneralInformationsFormMandatoryFields = (
  form: GeneralInformationsFormData,
  hideTypeWorkflowContractBrandModelFields?: boolean
): readonly (keyof GeneralInformationsFormData)[] => {
  return [
    ...COMMON_MANDATORY_FIELDS,
    ...(hideTypeWorkflowContractBrandModelFields
      ? []
      : CONTRACT_WORKFLOW_ID_BRAND_MANDATORY_FIELDS),
  ];
};

const HORIZONTAL_PROPS: HorizontalFormFieldProps = {
  bodyFlexGrow: 3,
  labelFlexGrow: 1,
};

interface BaseEditGeneralInformationsFormProps {
  readonly $formData: StoreStateSelector<Store, GeneralInformationsFormData>;
}

interface EditGeneralInformationsFormProps extends BaseEditGeneralInformationsFormProps {
  readonly $creationView: StoreStateSelector<Store, CreateKanbanViewState>;
  readonly $imageModal: StoreStateSelector<Store, ImageModalState>;
  readonly $sessionIsOnline: StoreStateSelector<Store, boolean>;
  readonly $sessionToken: StoreStateSelector<Store, string>;
  readonly $windowWidth: StoreStateSelector<Store, number>;
  readonly contractCodes: readonly string[];
  readonly workflows: readonly Workflow[];
  readonly allowDateOfRegistrationReset?: boolean;
  readonly selectedContractOrWorkflowChangeGlobalActionCallback?: NoArgActionCallback<Store>;
  readonly selectedContractChangeGlobalActionCallback?: NoArgActionCallback<Store>;
  readonly purchaseOrderChangeGlobalActionCallback?: NoArgActionCallback<Store>;
  readonly hideTypeWorkflowContractPhotoFields?: boolean;
}

type ColumnsClassName = {
  fistColumnClassName: string;
  secondColumnClassName: string;
  thirdColumnClassName: string;
};

function computeColumnsClassName(hideTypeWorkflowContractPhotoFields: boolean): ColumnsClassName {
  if (hideTypeWorkflowContractPhotoFields) {
    return {
      fistColumnClassName: 'm-t-sm1',
      secondColumnClassName: 'm-t-sm1',
      thirdColumnClassName: 'm-t-sm1',
    };
  }

  return {
    fistColumnClassName: `column is-3`,
    secondColumnClassName: `column is-5`,
    thirdColumnClassName: `column is-4`,
  };
}

export function EditGeneralInformationsForm({
  $creationView,
  $formData,
  $imageModal,
  $sessionIsOnline,
  $sessionToken,
  $windowWidth,
  contractCodes,
  workflows,
  selectedContractOrWorkflowChangeGlobalActionCallback,
  selectedContractChangeGlobalActionCallback,
  purchaseOrderChangeGlobalActionCallback,
  hideTypeWorkflowContractPhotoFields = false,
  allowDateOfRegistrationReset = false,
}: EditGeneralInformationsFormProps): JSX.Element {
  const [t] = useTranslation(['refititCommonComponents']);
  const isOnline = useGetState($sessionIsOnline);
  const windowWidth = useGetState($windowWidth);

  const computeAttachmentUrlCallback = useComputeAttachmentUrlFromSessionToken($sessionToken);
  const showRemoveAttachmentConfirmDialogCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('isAttachmentRemovalDialogActive', true);
    },
    [],
    $creationView
  );

  const contractOrWorkflowFormChange = useActionCallback(
    async ({ globalActionDispatch }) => {
      if (isTruthy(selectedContractOrWorkflowChangeGlobalActionCallback)) {
        await globalActionDispatch.execCallback(
          selectedContractOrWorkflowChangeGlobalActionCallback
        );
      }
    },
    [selectedContractOrWorkflowChangeGlobalActionCallback],
    $formData
  );

  const purchaseOrderChange = useActionCallback(
    async ({ globalActionDispatch }) => {
      await globalActionDispatch.execCallback(contractOrWorkflowFormChange);
      if (isTruthy(purchaseOrderChangeGlobalActionCallback)) {
        await globalActionDispatch.execCallback(purchaseOrderChangeGlobalActionCallback);
      }
    },
    [contractOrWorkflowFormChange, purchaseOrderChangeGlobalActionCallback],
    $formData
  );

  const contractFormChange = useActionCallback(
    async ({ actionDispatch, globalActionDispatch }) => {
      await actionDispatch.execCallback(contractOrWorkflowFormChange);
      if (isTruthy(selectedContractChangeGlobalActionCallback)) {
        await globalActionDispatch.execCallback(selectedContractChangeGlobalActionCallback);
      }
    },
    [contractOrWorkflowFormChange, selectedContractChangeGlobalActionCallback],
    $formData
  );

  const $workflowIdWithChangeTrigger = useSelectorWithChangeTrigger(
    $formData.$workflowId,
    contractOrWorkflowFormChange
  );
  const $purchaseOrdersWithChangeTrigger = useSelectorWithChangeTrigger(
    $formData.$purchaseOrders,
    purchaseOrderChange
  );
  const $contractCodeWithChangeTrigger = useSelectorWithChangeTrigger(
    $formData.$contractCode,
    contractFormChange
  );

  const contractEntries: readonly FormFieldEntry<string>[] = useMemo(
    () =>
      contractCodes.map((c): FormFieldEntry<string> => {
        return {
          id: c,
          label: c,
        };
      }),
    [contractCodes]
  );

  const workflowEntries: readonly FormFieldEntry<string>[] = useMemo(
    () =>
      workflows.map((w): FormFieldEntry<string> => {
        return {
          id: w.id,
          label: w.id,
        };
      }),
    [workflows]
  );

  // Fields layout depends on hideTypeWorkflowContractPhotoFields value:
  // - If value is false ==> the layout will be with columns
  // - Otherwise ==> the layout will be vertical
  const columnsClassName = computeColumnsClassName(hideTypeWorkflowContractPhotoFields);
  const thumbnailDisplayWidth = useMemo(
    (): number => Math.min(150, windowWidth / 10),
    [windowWidth]
  );

  return (
    <>
      <div className={columnsClassName.fistColumnClassName}>
        {!hideTypeWorkflowContractPhotoFields && (
          <>
            <RadioButtonsFormField
              id="type"
              label={t('editGeneralInformationsForm.type')}
              entries={['standard', 'marketplace']}
              $={$formData.$kanbanType}
              horizontal={HORIZONTAL_PROPS}
              radioGroupLayout="vertical"
            />
            <SelectFormField
              label={t('editGeneralInformationsForm.workflow')}
              isEmptyValueAllowed
              entries={workflowEntries}
              $={$workflowIdWithChangeTrigger}
              horizontal={HORIZONTAL_PROPS}
            />
            <ReactSelectFormField
              label={t('editGeneralInformationsForm.contract')}
              suggestions={contractEntries.map((entry) => ({
                value: entry.id,
                label: entry.label,
              }))}
              $={$contractCodeWithChangeTrigger}
              horizontal={HORIZONTAL_PROPS}
            />
            <FormField
              label={t('editGeneralInformationsForm.mainPicture')}
              horizontal={HORIZONTAL_PROPS}
            >
              {/* prevent picture from growing horizontally */}
              <div style={{ width: thumbnailDisplayWidth }}>
                <KanbanIdentityPictureComponent
                  $imageModal={$imageModal}
                  pictureEditionToolkit={{
                    $: $creationView.$pictureEditionToolkitState,
                    onRemoveCallback: showRemoveAttachmentConfirmDialogCallback,
                  }}
                  computeAttachmentUrl={computeAttachmentUrlCallback}
                  isOnline={isOnline}
                  thumbnailDisplayWidth={thumbnailDisplayWidth}
                />
              </div>
            </FormField>
          </>
        )}
      </div>
      <div className={columnsClassName.secondColumnClassName}>
        <ReactSelectFormField
          label={t('editGeneralInformationsForm.brand')}
          $={$formData.$brand}
          creation
          suggestions={AVAILABLE_BRANDS}
          horizontal={HORIZONTAL_PROPS}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.model')}
          $={$formData.$model}
          horizontal={HORIZONTAL_PROPS}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.motor')}
          $={$formData.$motor}
          horizontal={HORIZONTAL_PROPS}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.color')}
          $={$formData.$color}
          horizontal={HORIZONTAL_PROPS}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.license')}
          $={$formData.$license}
          horizontal={HORIZONTAL_PROPS}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.vin')}
          $={$formData.$vin}
          horizontal={HORIZONTAL_PROPS}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.mileage')}
          type="number"
          $={$formData.$mileage}
          horizontal={HORIZONTAL_PROPS}
        />
      </div>
      <div className={columnsClassName.thirdColumnClassName}>
        <CalendarFormField
          horizontal={HORIZONTAL_PROPS}
          noExclamationTriangleIfWarning
          $={$formData.$dateOfRegistration}
          hideInputClearButton={!allowDateOfRegistrationReset}
          label={t('editGeneralInformationsForm.dateOfRegistration')}
        />
        <CalendarFormField
          datePickerType="date"
          $={$formData.$dueDate}
          horizontal={HORIZONTAL_PROPS}
          noExclamationTriangleIfWarning
          label={t('editGeneralInformationsForm.dueDate')}
        />
        <CalendarFormField
          datePickerType="date"
          $={$formData.$refitEndDate}
          horizontal={HORIZONTAL_PROPS}
          noExclamationTriangleIfWarning
          label={t('editGeneralInformationsForm.refitEndDate')}
        />
        <InputFormField
          label={t('editGeneralInformationsForm.carLocation')}
          $={$formData.$carLocation}
          horizontal={HORIZONTAL_PROPS}
        />
        <TextAreaFormField
          label={t('editGeneralInformationsForm.purchaseOrder')}
          $={$purchaseOrdersWithChangeTrigger}
          horizontal={HORIZONTAL_PROPS}
        />
      </div>
    </>
  );
}
