import type { ActionDispatch, GetState, StoreDef } from '@stimcar/libs-uikernel';
import { Logger } from '@stimcar/libs-kernel';
import { getWindowState } from '@stimcar/libs-uikernel';
import { EMPTY_IMAGE_MODAL_STATE } from '../../react/index.js';
import type { BaseStoreState } from './typings/store.js';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log: Logger = Logger.new(import.meta.url);

export const EMPTY_BASE_STORE_STATE: BaseStoreState = {
  imageModal: EMPTY_IMAGE_MODAL_STATE,
  message: false,
  window: getWindowState(),
};

/**
 * Default error handler implementation (based on the message field of the state).
 */
export function baseAppContainerErrorHandler<GS extends BaseStoreState>(
  dispatch: ActionDispatch<StoreDef<GS, object>, GS>,
  getState: GetState<GS>,
  error: Error
): void {
  log.error(
    'The error handler caught an unexpected error in the dispatch. Injecting the error in the state to give a feedback to the user',
    error
  );
  // Try to notify the user through the generic error message
  dispatch.setProperty('message', error.message);
}
