import type { InvoiceInfo } from '@stimcar/libs-base';
import type { FormWithValidationState } from '@stimcar/libs-uitoolkit';
import { EMPTY_FORM_WITH_VALIDATION_STATE } from '@stimcar/libs-uitoolkit';
import type { SortState } from '../../../../lib/components/TableSortableHeaderComponent.js';
import type { ListWithTechnicalIdTabState } from '../../typings/store.js';

export type InvoiceReferenceAddFormData = {
  readonly purchaseOrderId: string;
  readonly invoiceReference: string;
};

export type InvoiceReferenceAddModalState = FormWithValidationState<InvoiceReferenceAddFormData> & {
  readonly isActive: boolean;
};

export type InvoiceRefundModalState = {
  readonly invoiceId: string;
  readonly purchaseOrderId: string;
  readonly invoiceReference: string;
  readonly generateNewInvoice: boolean;
  readonly isActive: boolean;
};

export type InvoiceInfoDeletionModalState = {
  readonly purchaseOrderId: string;
  readonly invoiceInfoId: string;
  readonly deleteAssociatedFiles: boolean;
  readonly deleteAssociatedEmailOperations: boolean;
  readonly isActive: boolean;
};

export type KanbanInvoiceTabState = ListWithTechnicalIdTabState & {
  readonly invoiceReferenceAddModalState: InvoiceReferenceAddModalState;
  readonly invoiceRefundModalState: InvoiceRefundModalState;
  readonly invoiceInfoDeletionModalState: InvoiceInfoDeletionModalState;
  readonly sort: SortState<
    'purchaseOrderLabel' | 'reference' | 'amount' | 'isRefund' | 'refundedInvoiceReference' | 'link'
  >;
};

export const INVOICE_REFERENCE_ADD_EMPTY_FORM_DATA: InvoiceReferenceAddFormData = {
  purchaseOrderId: '',
  invoiceReference: '',
};

export const INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE: InvoiceReferenceAddModalState = {
  ...EMPTY_FORM_WITH_VALIDATION_STATE,
  isActive: false,
  formData: { ...INVOICE_REFERENCE_ADD_EMPTY_FORM_DATA, warnings: {} },
};

export const INVOICE_REFUND_MODAL_EMPTY_STATE: InvoiceRefundModalState = {
  invoiceId: '',
  purchaseOrderId: '',
  invoiceReference: '',
  generateNewInvoice: false,
  isActive: false,
};

export const INVOICE_INFO_DELETION_MODAL_EMPTY_STATE: InvoiceInfoDeletionModalState = {
  purchaseOrderId: '',
  invoiceInfoId: '',
  deleteAssociatedFiles: false,
  deleteAssociatedEmailOperations: false,
  isActive: false,
};

export const EMPTY_KANBAN_INVOICE_TAB_STATE: KanbanInvoiceTabState = {
  showTechnicalId: false,
  invoiceReferenceAddModalState: INVOICE_REFERENCE_ADD_MODAL_EMPTY_STATE,
  invoiceRefundModalState: INVOICE_REFUND_MODAL_EMPTY_STATE,
  invoiceInfoDeletionModalState: INVOICE_INFO_DELETION_MODAL_EMPTY_STATE,
  sort: { by: 'purchaseOrderLabel', direction: 'UP' },
};

export type InvoiceDetail = Pick<
  InvoiceInfo,
  'reference' | 'amount' | 'invoiceId' | 'invoiceFirmId' | 'isRefund'
> & {
  invoiceDetailId: string;
  purchaseOrderId: string;
  purchaseOrderLabel: string;
  invoiceInfoId: string | null;
  link: string | null;
  refundedInvoiceReference: string | null; // For a refund, this is the reference of the refunded invoice
  refundingInvoiceId: number | null; // For an invoice which has been refunded, this is the ID of the refund
};
