/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useCallback, useMemo } from 'react';
import type { AnyStoreDef, NoArgActionCallback, StoreStateSelector } from '@stimcar/libs-uikernel';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import type { FormFieldEntry } from '../typings/FormFieldEntry.js';
import { FaIcon } from '../../elements/FaIcon.js';

export interface ListSelectProps<SD extends AnyStoreDef, T extends string | readonly string[]> {
  readonly height?: number;
  readonly entries: readonly FormFieldEntry<string>[];
  readonly sortingFunction?: (a: FormFieldEntry<string>, b: FormFieldEntry<string>) => number;
  readonly $: StoreStateSelector<SD, T>;
  readonly isComponentFocused?: boolean;
  readonly hasBorder?: boolean;
  readonly setComponentFocus?: NoArgActionCallback<SD>;
  readonly noElementsPlaceholder?: string;
  readonly readOnly?: boolean;
  readonly lineActionToolkit?: {
    readonly actionHandler: (lineIndex: number) => void | Promise<void>;
    readonly tooltip?: string;
    readonly icon?: string;
  };
}

export function ListSelect<SD extends AnyStoreDef, T extends string | readonly string[]>({
  height,
  entries,
  $,
  isComponentFocused = true,
  sortingFunction,
  setComponentFocus,
  hasBorder = false,
  lineActionToolkit,
  noElementsPlaceholder,
  readOnly = false,
}: ListSelectProps<SD, T>): JSX.Element {
  const selection = useGetState($);

  const style = useMemo((): React.CSSProperties => {
    return {
      overflow: 'auto',
      cursor: 'pointer',
      maxHeight: height ? `${height}px` : undefined,
      minHeight: height ? `${height}px` : undefined,
    };
  }, [height]);

  const selectedIds = useMemo((): string[] => {
    if (Array.isArray(selection)) {
      return selection;
    }
    if (selection !== undefined && selection !== null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return [selection as any];
    }
    return [];
  }, [selection]);

  const onElementClickedHandler = useActionCallback(
    async (
      { actionDispatch, getState },
      event: React.MouseEvent<HTMLTableDataCellElement, MouseEvent>
    ) => {
      const selection = getState();
      const { id } = event.currentTarget;
      if (Array.isArray(selection)) {
        const index = selection.indexOf(id);
        if (index < 0) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actionDispatch.setValue([id, ...selection] as any);
        } else {
          const newSelection: string[] = [];
          if (index > 0) {
            newSelection.push(...selection.slice(0, index));
          }
          if (index < selection.length - 1) {
            newSelection.push(...selection.slice(index + 1, selection.length));
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actionDispatch.setValue(newSelection as any);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        actionDispatch.setValue(id as any);
      }
      if (setComponentFocus) {
        await actionDispatch.execCallback(setComponentFocus);
      }
    },
    [setComponentFocus],
    $
  );

  const onElementButtonClickedHandler = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): Promise<void> => {
      if (lineActionToolkit) {
        const { id } = event.currentTarget;
        await lineActionToolkit.actionHandler(Number.parseInt(id, 10));
      }
    },
    [lineActionToolkit]
  );

  const computeSelectClassesStyle = (id: string): string => {
    if (selectedIds.includes(id)) {
      if (isComponentFocused) {
        return 'is-selected';
      }
      return 'is-selected has-background-grey-lighter';
    }
    return '';
  };

  const sortedElements = useMemo((): readonly FormFieldEntry<string>[] => {
    if (sortingFunction) {
      const tempEntries = entries.slice();
      return tempEntries.sort(sortingFunction);
    }
    return entries;
  }, [entries, sortingFunction]);

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
  return (
    <div className={`table-container ${hasBorder ? 'mimicInput' : ''}`} style={style}>
      <table className="table is-fullwidth is-narrow">
        <tbody>
          {sortedElements.length === 0 && isTruthyAndNotEmpty(noElementsPlaceholder) && (
            <tr>
              <td className="has-text-centered placeholder">{noElementsPlaceholder}</td>
            </tr>
          )}
          {sortedElements.map(({ id, label }, index): JSX.Element => {
            return (
              <tr key={id}>
                <td
                  className={computeSelectClassesStyle(id)}
                  id={id}
                  role="button"
                  style={{ whiteSpace: 'nowrap' }}
                  onClick={readOnly ? undefined : onElementClickedHandler}
                >
                  {label}
                </td>
                {lineActionToolkit && !readOnly && (
                  <td style={{ width: 0 }}>
                    <button
                      id={String(index)}
                      type="button"
                      className="button is-small is-white"
                      title="update"
                      onClick={readOnly ? undefined : onElementButtonClickedHandler}
                    >
                      <FaIcon
                        id={
                          isTruthyAndNotEmpty(lineActionToolkit.icon)
                            ? lineActionToolkit.icon
                            : 'plus-square'
                        }
                        tooltip={lineActionToolkit.tooltip}
                      />
                    </button>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
