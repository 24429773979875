import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { packageDealHelpers } from '@stimcar/libs-base';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../../state/typings/store.js';

export function ValidationCostComponent({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('operators');
  const packageDealsWorkingCopy = useGetState(
    $gs.$operatorView.$validateExpertiseViewState.$packageDealsWorkingCopy
  );

  const { availableCost, discaredCost } = useMemo(() => {
    let available = 0;
    let discared = 0;
    packageDealsWorkingCopy.forEach((pd) => {
      if (!pd.deleted) {
        if (packageDealHelpers.isPackageDealAvailable(pd)) {
          available += packageDealHelpers.getTotalPrice(pd, 'all');
        } else if (packageDealHelpers.isPackageDealCanceled(pd)) {
          discared += packageDealHelpers.getTotalPrice(pd, 'all');
        } else {
          throw Error('During expertise validation phase, all package deals must have a status');
        }
      }
    });
    return {
      availableCost: available,
      discaredCost: discared,
    };
  }, [packageDealsWorkingCopy]);

  return (
    <>
      <p className="is-4 title">
        {t('expertiseValidation.costs.available', { cost: availableCost.toFixed(2) })}
      </p>
      <p className="is-5 subtitle">
        {t('expertiseValidation.costs.rejected', { cost: discaredCost.toFixed(2) })}
      </p>
    </>
  );
}
