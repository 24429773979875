export function extractMessageFromError(error: Error): string {
  // message will be in the form :
  // "Unexpected HTTP request error for '/wan-api/invoice/refund' : Erreur lors de la création ... "
  const { message } = error;

  // First we get what is following the ':'
  // Find the index of the first colon
  const firstColonIndex = message.indexOf(':');
  if (firstColonIndex === -1) {
    return message;
  }

  // Extract the real message starting from the first colon
  return message.substring(firstColonIndex + 1).trim();
}
