/* eslint-disable jsx-a11y/control-has-associated-label */
import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { ContractAttributeDesc, Operation, PackageDeal } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { nonDeleted, packageDealHelpers, sortingHelpers } from '@stimcar/libs-base';
import { nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useRecordItemSelector,
  useSelectorWithChangeTrigger,
  useStateIsDefined,
} from '@stimcar/libs-uikernel';
import {
  ClickableIcon,
  InputFormField,
  ScrollableTableComponent,
  TruncableTableTd,
  TruncableTableTh,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import type {
  ApplyToggleOperationsPayloadAction,
  SubcontractableInfo,
} from '../../utils/subcontractor/SelectSubcontractorFirmModalDialog.js';
import type { OperatorViewState, WorkStatus } from '../typings/store.js';
import { TableSortableHeaderComponent } from '../../../lib/components/TableSortableHeaderComponent.js';
import { canCompleteOperation } from '../../utils/operatorUtils.js';
import { SelectSubcontractorFirmModalDialog } from '../../utils/subcontractor/SelectSubcontractorFirmModalDialog.js';
import {
  toggleAllOperationsStatusAction,
  toggleOperationStatusInOperatorViewAction,
  updateKanbanAttributeAction,
} from './action.js';
import { OperatorSparePartManagementTable } from './spareparts/common/OperatorSparePartManagementTable.js';

interface OperationItemProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly packageDeal: PackageDeal;
  readonly operation: Operation;
  readonly workStatus: WorkStatus;
  readonly t: TFunction;
  // Optional callback used to overload the default behavior when operations status are toggled
  readonly applyToggleOperationsPayloadAction?: ApplyToggleOperationsPayloadAction<OperatorViewState>;
}

function OperationItem({
  $,
  $gs,
  packageDeal,
  operation,
  workStatus,
  t,
  // By default save changes in the repository
  applyToggleOperationsPayloadAction,
}: OperationItemProps): JSX.Element {
  const { id, workload, completionDate, user, subcontractor } = operation;

  const operationLabel = useMemo(() => {
    return packageDealHelpers.getOperationDisplayedLabel(operation, packageDeal.variables);
  }, [operation, packageDeal.variables]);

  const toggleOperationStatusActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        toggleOperationStatusInOperatorViewAction,
        id,
        applyToggleOperationsPayloadAction
      );
    },
    [applyToggleOperationsPayloadAction, id],
    $
  );
  const { carElement } = packageDeal;

  const permissions = useGetState($gs.$session.$user.optChaining().$permissions) ?? {};
  const cannotBeToggled = !canCompleteOperation(operation, permissions) || workStatus !== 'started';

  return (
    <tr className={user ? 'has-background-success-light' : ''}>
      <TruncableTableTd>{packageDeal.code}</TruncableTableTd>
      <TruncableTableTd>{operationLabel}</TruncableTableTd>
      <TruncableTableTd>
        {carElement
          ? carElement.label
          : String(t('generalView.operations.miscOperationCarElementPlaceholder'))}
      </TruncableTableTd>
      <TruncableTableTd className="has-text-right">{workload.toFixed(2)}</TruncableTableTd>
      <TruncableTableTd>
        {packageDeal.spareParts
          .filter(nonDeleted)
          .map((s) => packageDealHelpers.getSparePartDisplayedLabel(s, packageDeal))
          .join(', ')}
      </TruncableTableTd>
      <TruncableTableTd>{subcontractor ?? user ?? ''}</TruncableTableTd>
      <td>
        <ClickableIcon
          id={
            completionDate !== undefined && completionDate !== null ? 'r/square-check' : 'r/square'
          }
          clickHandler={toggleOperationStatusActionCallback}
          isDisabled={cannotBeToggled}
          size="small"
          tooltip={
            completionDate
              ? t('generalView.operations.tooltip.unCheck')
              : t('generalView.operations.tooltip.check')
          }
        />
      </td>
    </tr>
  );
}

interface TabledOperationsProps extends AppProps<Store> {
  // Optional callback used to overload the default behavior when operations status are toggled
  readonly applyToggleOperationsPayloadAction?: ApplyToggleOperationsPayloadAction<OperatorViewState>;
}

function TabledOperations({
  $gs,
  applyToggleOperationsPayloadAction,
}: TabledOperationsProps): JSX.Element {
  const [t] = useTranslation('operators');

  const user = useGetState($gs.$session.$user);

  const { $operatorView } = $gs;
  const { $generalOperatorState } = $operatorView;

  const workStatus = useGetState($operatorView.$workStatus);
  const operationsToDoIds = useGetState($operatorView.$operationsToDoIds);

  const standRelatedPackageDeals = useGetState($generalOperatorState.$standRelatedPackageDeals);
  const sortBy = useGetState($generalOperatorState.$sort.$by);
  const sortDirection = useGetState($generalOperatorState.$sort.$direction);

  const operationsToDo = useMemo(
    () =>
      standRelatedPackageDeals.flatMap(({ operations }) =>
        operations.filter(({ id }) => operationsToDoIds.includes(id))
      ),
    [standRelatedPackageDeals, operationsToDoIds]
  );

  const allAuthorizedOperationsAreDone = useMemo((): boolean => {
    const userPermissions = user?.permissions ?? {};
    for (const op of operationsToDo) {
      if (canCompleteOperation(op, userPermissions) && !op.completionDate) {
        return false;
      }
    }
    return true;
  }, [operationsToDo, user]);

  const operationIdToPackageDealMap = useMemo((): Map<string, PackageDeal> => {
    const map = new Map<string, PackageDeal>();
    if (standRelatedPackageDeals) {
      standRelatedPackageDeals.forEach((pkgd) => {
        pkgd.operations.forEach((operation) => {
          map.set(operation.id, pkgd);
        });
      });
    }
    return map;
  }, [standRelatedPackageDeals]);

  const sortedOperations = useMemo((): readonly Operation[] => {
    const localOperations = operationsToDo.slice();
    let sortFunction: (o1: Operation, o2: Operation) => number;
    switch (sortBy) {
      case 'carElement':
        sortFunction = sortingHelpers.createSortOperationsByPackageDealsCarElementFunction(
          sortDirection,
          operationIdToPackageDealMap
        );
        break;
      case 'code':
        sortFunction = sortingHelpers.createSortOperationsByPackageDealsCodeFunction(
          sortDirection,
          operationIdToPackageDealMap
        );
        break;
      case 'label':
        sortFunction = sortingHelpers.createSortByStringField<Operation>(sortDirection, sortBy);
        break;
      case 'workload':
        sortFunction = sortingHelpers.createSortByNumericField<Operation>(sortDirection, sortBy);
        break;
      case 'user':
        sortFunction = sortingHelpers.createSortByStringField<Operation>(sortDirection, sortBy);
        break;
      default:
        return localOperations;
    }

    return localOperations.sort(sortFunction);
  }, [operationsToDo, sortBy, sortDirection, operationIdToPackageDealMap]);

  const toggleAllOperationsActionCallback = useActionCallback(
    async ({ actionDispatch }) => {
      await actionDispatch.exec(
        toggleAllOperationsStatusAction,
        t,
        applyToggleOperationsPayloadAction
      );
    },
    [applyToggleOperationsPayloadAction, t],
    $operatorView
  );

  return (
    <div className="columns">
      <div className="column is-full">
        <ScrollableTableComponent
          isTruncable
          height="100%"
          tableClassName="table is-bordered is-striped is-narrow is-hoverable is-fullwidth"
        >
          <thead>
            <tr>
              <TableSortableHeaderComponent
                content={t('generalView.operations.code')}
                isTruncable
                centerLabel={false}
                sortedField="code"
                $sort={$generalOperatorState.$sort}
                style={{ width: '8%' }}
              />
              <TableSortableHeaderComponent
                content={t('generalView.operations.label')}
                isTruncable
                centerLabel={false}
                sortedField="label"
                $sort={$generalOperatorState.$sort}
              />
              <TableSortableHeaderComponent
                content={t('generalView.operations.carElement')}
                isTruncable
                centerLabel={false}
                sortedField="carElement"
                $sort={$generalOperatorState.$sort}
                style={{ width: '15%' }}
              />
              <TableSortableHeaderComponent
                content={t('generalView.operations.workload')}
                isTruncable
                centerLabel={false}
                sortedField="workload"
                $sort={$generalOperatorState.$sort}
                style={{ width: '10%' }}
              />
              <TruncableTableTh style={{ width: '20%' }}>
                {t('generalView.operations.spareParts')}
              </TruncableTableTh>
              <TableSortableHeaderComponent
                content={t('generalView.operations.completedBy')}
                isTruncable
                centerLabel={false}
                sortedField="user"
                $sort={$generalOperatorState.$sort}
                style={{ width: '10%' }}
              />
              <th style={{ width: '1.875em' }}>
                <input
                  type="checkbox"
                  disabled={workStatus !== 'started'}
                  onChange={toggleAllOperationsActionCallback}
                  checked={allAuthorizedOperationsAreDone}
                  title={
                    allAuthorizedOperationsAreDone
                      ? t('generalView.operations.tooltip.unCheckAll')
                      : t('generalView.operations.tooltip.checkAll')
                  }
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {operationsToDo.length === 0 && (
              <tr key="emptyline">
                <td>&nbsp;</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            )}
            {sortedOperations.map((operation: Operation): JSX.Element => {
              const packageDeal = nonnull(operationIdToPackageDealMap.get(operation.id));
              return (
                <OperationItem
                  key={operation.id}
                  $gs={$gs}
                  packageDeal={packageDeal}
                  operation={operation}
                  $={$operatorView}
                  workStatus={workStatus}
                  t={t}
                  applyToggleOperationsPayloadAction={applyToggleOperationsPayloadAction}
                />
              );
            })}
          </tbody>
        </ScrollableTableComponent>
      </div>
    </div>
  );
}

interface QuickAccessAttributeEditionProps {
  readonly $: StoreStateSelector<Store, OperatorViewState>;
  readonly attributeId: string;
}

function QuickAccessAttributeEdition({
  $,
  attributeId,
}: QuickAccessAttributeEditionProps): JSX.Element {
  const onAttributeChangeActionCallback = useActionCallback(
    async ({ actionDispatch }: ActionContext<Store, OperatorViewState>): Promise<void> => {
      await actionDispatch.exec(updateKanbanAttributeAction, attributeId);
    },
    [attributeId],
    $
  );

  const $attribute = useRecordItemSelector($.$operatedKanban.$attributes, attributeId);

  const $attributeWithChangeTrigger = useSelectorWithChangeTrigger(
    $attribute,
    onAttributeChangeActionCallback
  );
  return (
    <InputFormField
      label={attributeId}
      horizontal
      $={$attributeWithChangeTrigger as StoreStateSelector<Store, string>}
    />
  );
}

interface CommonOperatorWorkingAreaProps extends AppProps<Store> {
  readonly standId: string;
  readonly attributeDescs: readonly ContractAttributeDesc[];
  // Optional callback used to overload the default behavior when operations status are toggled
  readonly applyToggleOperationsPayloadAction?: ApplyToggleOperationsPayloadAction<OperatorViewState>;
}

export function CommonOperatorWorkingArea({
  $gs,
  standId,
  attributeDescs,
  applyToggleOperationsPayloadAction,
}: CommonOperatorWorkingAreaProps): JSX.Element {
  const $ = $gs.$operatorView;
  const { $generalOperatorState } = $;
  const { $sparePartsState } = $generalOperatorState;

  const operatedKanbanIsSet = useStateIsDefined($.$operatedKanban);
  const workStatus = useGetState($.$workStatus);
  const operationsToDoIds = useGetState($.$operationsToDoIds);
  const kanbanId = useGetState($.$operatedKanban.$id);
  const packageDeals = useGetState($.$operatedKanban.$packageDeals);

  const quickAccessAttributeDescs = useMemo(() => {
    return attributeDescs.filter((a) => a.quickAccessForStand?.includes(standId)) ?? [];
  }, [standId, attributeDescs]);

  const spareParts = useGetState($generalOperatorState.$sparePartsState.$spareParts);

  const toggleSubcontractorOperationCallback = useActionCallback(
    async ({ actionDispatch }, operationId: string, subcontractorInfo: SubcontractableInfo) => {
      await actionDispatch.exec(
        toggleOperationStatusInOperatorViewAction,
        operationId,
        applyToggleOperationsPayloadAction,
        subcontractorInfo
      );
    },
    [applyToggleOperationsPayloadAction],
    $gs.$operatorView
  );

  return (
    <>
      <div className="columns is-multiline">
        {operatedKanbanIsSet &&
          quickAccessAttributeDescs
            .sort((a1, a2) => sortingHelpers.compareStrings(a1.id, a2.id, 'UP'))
            .map(
              (a): JSX.Element => (
                <div className="column is-3" key={a.id}>
                  <QuickAccessAttributeEdition attributeId={a.id} $={$} />
                </div>
              )
            )}
      </div>

      {spareParts.length > 0 && (
        <OperatorSparePartManagementTable
          $={$sparePartsState}
          $gs={$gs}
          workStatus={workStatus}
          kanbanId={kanbanId}
          kanbanPackageDeals={packageDeals}
        />
      )}

      {operationsToDoIds.length > 0 && (
        <TabledOperations
          $gs={$gs}
          applyToggleOperationsPayloadAction={applyToggleOperationsPayloadAction}
        />
      )}
      <SelectSubcontractorFirmModalDialog
        $={$}
        toggleOperationActionCallback={toggleSubcontractorOperationCallback}
      />
    </>
  );
}
