import React from 'react';
import type { Kanban } from '@stimcar/libs-base';
import type {
  ActionContext,
  GlobalStoreStateSelector,
  StoreStateSelector,
} from '@stimcar/libs-uikernel';
import { MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID } from '@stimcar/libs-base';
import { isTruthy } from '@stimcar/libs-kernel';
import { useActionCallback, useGetState } from '@stimcar/libs-uikernel';
import {
  DeleteClickableIcon,
  EMPTY_ATTACHMENT_GALLERY_DIALOG_STATE,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { useComputeAttachmentUrl } from '../../utils/useComputeAttachmentUrl.js';
import type { MarketplaceDetailsState } from './typings/store.js';

function openAttachmentGalleryModalAction(
  { actionDispatch }: ActionContext<Store, MarketplaceDetailsState>,
  filenameAttributeKey: string
) {
  const galleryDispatch = actionDispatch.scopeProperty('attachmentGalleryDialogModal');
  // Reset dialog
  galleryDispatch.setValue(EMPTY_ATTACHMENT_GALLERY_DIALOG_STATE);
  galleryDispatch.setProperty('active', true);
  // Enables the selection tools of the gallery
  galleryDispatch.setProperty('selectedPaths', []);
  actionDispatch.setProperty('editedPictureAttribute', filenameAttributeKey);
}

interface MarketplacePictureProps {
  readonly $: StoreStateSelector<Store, MarketplaceDetailsState>;
  readonly $gs: GlobalStoreStateSelector<Store>;
  readonly isEditable: boolean;
  readonly $selectedKanban: StoreStateSelector<Store, Kanban>;
  readonly filenameAttributeKey: string;
}

export function MarketplacePicture({
  $,
  $gs,
  isEditable,
  $selectedKanban,
  filenameAttributeKey,
}: MarketplacePictureProps): JSX.Element {
  const kanbanId = useGetState($selectedKanban.$id);
  const attributes = useGetState($selectedKanban.$attributes);
  const filename = attributes[filenameAttributeKey];
  const isSet = isTruthy(filename) && filename !== '';
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);

  const onRemoveActionCallback = useActionCallback(
    async function onRemoveAction({ kanbanRepository }) {
      await kanbanRepository.updateEntityFromPayload({
        entityId: kanbanId,
        payload: {
          attributes: {
            [filenameAttributeKey]: '',
          },
        },
      });
    },
    [filenameAttributeKey, kanbanId],
    $
  );

  const onClickActionCallback = useActionCallback(
    async function onClickAction({ actionDispatch, globalActionDispatch }) {
      if (!isSet) {
        if (isEditable) {
          await actionDispatch.exec(openAttachmentGalleryModalAction, filenameAttributeKey);
        } else {
          throw new Error(
            `The picture filename is not set, and the component is in readonly mode, so a click on it is note supposed to happen`
          );
        }
      } else {
        globalActionDispatch.applyPayload({
          imageModal: {
            active: true,
            imageIndex: 0,
            imagesUrls: [
              computeAttachmentUrl(
                'kanban',
                MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
                filename as string,
                kanbanId
              ),
            ],
          },
        });
      }
    },
    [computeAttachmentUrl, filename, filenameAttributeKey, isEditable, isSet, kanbanId],
    $
  );

  const onKeyDownActionCallback = useActionCallback(
    async function onKeyDownAction({ actionDispatch }, e: React.KeyboardEvent<HTMLElement>) {
      if (e.key === 'Enter') {
        e.stopPropagation();
        await actionDispatch.execCallback(onClickActionCallback);
      }
    },
    [onClickActionCallback],
    $
  );

  const isClickable = isSet || isEditable;

  return (
    <>
      <figure
        className={`image is-4by3 is-full-height${isClickable ? ' is-clickable' : ''} has-background-light`}
      >
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img
          src={
            isSet
              ? computeAttachmentUrl(
                  'kanban',
                  MARKETPLACE_PHOTO_ATTACHMENTS_FOLDER_ID,
                  filename as string,
                  kanbanId,
                  {
                    mode: 'cover',
                    size: '640x480',
                  }
                )
              : 'transp.png'
          }
          alt=""
          onClick={isClickable ? onClickActionCallback : undefined}
          onKeyDown={isClickable ? onKeyDownActionCallback : undefined}
        />
        {isSet && isEditable && (
          <DeleteClickableIcon
            handler={onRemoveActionCallback}
            customStyle={{ position: 'absolute', top: 2, right: 2 }}
            isSmall
          />
        )}
      </figure>
    </>
  );
}
