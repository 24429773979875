import type { TFunction } from 'i18next';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Contract } from '@stimcar/libs-base';
import type { ActionContext, ReadOnlyActionContext } from '@stimcar/libs-uikernel';
import type { AppProps, ColumnDesc } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { AvailablePermissionPaths, CoreBackendRoutes, mergeArrayItems } from '@stimcar/libs-base';
import { useActionCallback, useGetState, useSetCallback } from '@stimcar/libs-uikernel';
import {
  applyAndFilterTableItemsAction,
  DisplayContentOrPlaceholder,
  Table,
  useGetDefaultTableToolbarConf,
} from '@stimcar/libs-uitoolkit';
import type { Store } from '../../state/typings/store.js';
import { useHasModifyPermission } from '../../../registeredapp/permissionHooks.js';
import type { AdminContractsState, AdminUIContract } from './typings/store.js';
import { DeleteContractModal, openDeleteContractModalAction } from './DeleteContractModal.js';
import {
  EditContractModal,
  openCreateContractModalAction,
  openEditContractModalAction,
} from './EditContractModal.js';
import { EMPTY_CONTRACTS_ADMIN_STATE } from './typings/store.js';

const computeColumnDescs = (
  t: TFunction,
  isEditionForbidden: boolean
): readonly ColumnDesc<Store, AdminContractsState, AdminUIContract>[] => {
  return [
    {
      columnLabel: t('adminContracts:contractTable.code'),
      columnType: 'display',
      id: 'code',
      propertyType: 'string',
      getPropertyValue: (c): string =>
        c.id /* substitute code / id because in the state, objects must have ids to be within arrays */,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminContracts:contractTable.label'),
      columnType: 'display',
      id: 'label',
      propertyType: 'string',
      getPropertyValue: (c): string => c.label,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminContracts:contractTable.packageDealDatabase'),
      columnType: 'display',
      id: 'packageDealDatabase',
      propertyType: 'string',
      getPropertyValue: (c): string => c.packageDealDatabase,
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminContracts:contractTable.hourlyRate'),
      columnType: 'display',
      id: 'hourlyRate',
      propertyType: 'number',
      columnStyle: { width: '10%' },
      getPropertyValue: (c): number => c.configuration.hourlyRate,
      getDisplayedValue(c): string {
        return t('contractTable.hourlyRateValue', { value: this.getPropertyValue(c) });
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminContracts:contractTable.sparePartsPriceMargin'),
      columnType: 'display',
      id: 'sparePartMargin',
      propertyType: 'number',
      columnStyle: { width: '10%' },
      getPropertyValue: (c): number => c.configuration.sparePartMarginPercentage * 100,
      getDisplayedValue(c): string {
        return `${this.getPropertyValue(c)}%`;
      },
      isDisplayedByDefault: true,
    },
    {
      columnLabel: t('adminContracts:contractForm.shortSparePartManagementType'),
      columnType: 'display',
      id: 'sparePartsOutsourced',
      propertyType: 'string',
      columnStyle: { width: '15em' },
      getPropertyValue: (c): string =>
        t(
          `adminContracts:contractForm.sparePartManagementType.${c.configuration.sparePartManagementType}`
        ),
      isDisplayedByDefault: true,
    },
    {
      columnType: 'action',
      id: 'editContract',
      columnLabel: t('adminContracts:buttons.editContract'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      getCellIconId: (): string => {
        return 'edit';
      },
      cellTooltip: t('adminContracts:buttons.editContract'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      disabled: (): boolean => isEditionForbidden,
      action: async (dispatch, contract): Promise<void> => {
        await dispatch.exec(openEditContractModalAction, contract);
      },
    },
    {
      columnType: 'action',
      id: 'deleteContract',
      columnLabel: t('adminContracts:buttons.deleteContract'),
      columnIcon: {
        iconId: '',
        showText: false,
      },
      getCellIconId: (): string => {
        return 'trash';
      },
      cellTooltip: t('adminContracts:buttons.deleteContract'),
      columnStyle: { width: '2.25em' },
      isNotHideable: true,
      disabled: (): boolean => isEditionForbidden,
      action: async (dispatch, contract): Promise<void> => {
        await dispatch.exec(openDeleteContractModalAction, contract);
      },
    },
  ];
};

export function initializeDefaultSortAction({
  actionDispatch,
}: ActionContext<Store, AdminContractsState>) {
  // Add a default sort option (sort by code)
  actionDispatch
    .scopeProperty('sortsMenuState')
    .scopeProperty('sorts')
    .setValue([
      {
        id: 'code',
        direction: 'UP',
      },
    ]);
}

const tableContentProvider = async ({
  httpClient,
}: ReadOnlyActionContext<Store, AdminContractsState>): Promise<readonly AdminUIContract[]> => {
  const rawContracts = await httpClient.httpGetAsJson<Contract[]>(
    CoreBackendRoutes.GET_ALL_FULL_CONTRACTS
  );
  return rawContracts.map(({ code, ...contract }) => ({ ...contract, id: code }));
};

export function AdminContracts({ $gs }: AppProps<Store>): JSX.Element {
  const [t] = useTranslation('adminContracts');
  const $ = $gs.$adminView.$adminContracts;
  const { $editContractDialogState } = $;

  const isOnline = useGetState($gs.$session.$isOnline);

  const isEditionForbidden = !useHasModifyPermission($gs, AvailablePermissionPaths.CONTRACTS_VIEW);

  const columnDescs = useMemo(() => {
    return computeColumnDescs(t, isEditionForbidden);
  }, [t, isEditionForbidden]);

  const onContractsChangeActionCallback = useActionCallback(
    function _onContractsChangeActionCallback(
      ctx,
      addedOrUpdated: readonly AdminUIContract[],
      removedCodes: readonly string[]
    ) {
      const { getState } = ctx;
      // Merge actual items (the same way as SSE updates for repository entities)
      const { items } = getState();

      const newItems = mergeArrayItems(items, addedOrUpdated, removedCodes);
      applyAndFilterTableItemsAction(ctx, newItems, columnDescs);
    },
    [columnDescs],
    $
  );

  const asyncCleanupEffect = useSetCallback($, EMPTY_CONTRACTS_ADMIN_STATE);

  useEffect((): (() => void) => {
    return (): void => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      asyncCleanupEffect();
    };
  }, [asyncCleanupEffect]);

  const openCreateDialogActionCallback = useActionCallback(
    openCreateContractModalAction,
    [],
    $editContractDialogState
  );

  const toobarConf = useGetDefaultTableToolbarConf(
    LocalStorageKeys.ADMIN_CONTRACTS_COLUMNS_PREFERENCE,
    t('csvDownloadBaseFileName')
  );

  return (
    <DisplayContentOrPlaceholder
      displayCondition={isOnline}
      placeholder={t('warning.notAvailableIfOffline')}
    >
      <>
        <div className="level">
          <div className="level-left">
            <div>
              <p className="title is-2">{t('title')}</p>
              <p className="subtitle is-4">{t('subtitle')}</p>
            </div>
          </div>
          <div className="level-right">
            <div className="buttons">
              <button
                type="button"
                className="button"
                onClick={openCreateDialogActionCallback}
                disabled={isEditionForbidden}
              >
                <span>{t('buttons.new')}</span>
              </button>
            </div>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <Table
              $={$}
              columnDescs={columnDescs}
              contentProvider={tableContentProvider}
              preInitActionCallback={useActionCallback(initializeDefaultSortAction, [], $)}
              isScrollable
              isTruncable
              toolbar={toobarConf}
              tableClassName="table is-bordered is-narrow is-hoverable is-fullwidth"
            />
          </div>
        </div>
        <EditContractModal
          $={$}
          onContractsChangeActionCallback={onContractsChangeActionCallback}
        />
        <DeleteContractModal
          $={$}
          onContractsChangeActionCallback={onContractsChangeActionCallback}
        />
      </>
    </DisplayContentOrPlaceholder>
  );
}
