import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { WorkshopStandImplantation } from '@stimcar/libs-base';
import type { ActionContext, StoreStateSelector } from '@stimcar/libs-uikernel';
import type { AppProps } from '@stimcar/libs-uitoolkit';
import { LocalStorageKeys } from '@stimcar/core-libs-common';
import { CoreBackendRoutes, kanbanHelpers, workshopHelpers } from '@stimcar/libs-base';
import { isTruthy, keysOf, nonnull } from '@stimcar/libs-kernel';
import {
  useActionCallback,
  useGetState,
  useSelectorWithChangeTrigger,
} from '@stimcar/libs-uikernel';
import { downloadAndSave, EstimateView } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../../app/state/typings/store.js';
import type { WorkshopImplantationViewState } from '../typings/store.js';
import { loadEstimateAttachmentsAction } from '../../../../app/utils/attachmentGalleryActions.js';
import { useComputeAttachmentUrl } from '../../../../app/utils/useComputeAttachmentUrl.js';
import {
  useCreateAnomalyPostActionProvider,
  useCreatePostActionsProvider,
  useCreateQueueActionsProvider,
} from './useCreateActionsProviderForWorkshopImplantationView.js';
import { WorkshopImplantationComponent } from './WorkshopImplantationComponent.js';
import { getIdsOfKanbansInAnomaly } from './workshopImplantationUtils.js';

interface WorkshopImplantationViewFromProvidedStateProps extends AppProps<Store> {
  readonly $: StoreStateSelector<Store, WorkshopImplantationViewState>;
  readonly isInteractive: boolean;
}

export async function initializeWorkshopView({
  actionDispatch,
  kanbanRepository,
  getGlobalState,
  getState,
}: ActionContext<Store, WorkshopImplantationViewState>): Promise<void> {
  const { implantationId: actualImplantationID } = getState();
  const { siteConfiguration } = getGlobalState();
  const implantationIds = keysOf(
    workshopHelpers.getImplantationsFromStands(siteConfiguration.stands)
  );
  if (!implantationIds.includes(actualImplantationID)) {
    actionDispatch.setProperty(
      'implantationId',
      implantationIds.length > 0 ? implantationIds[0] : ''
    );
  }
  // Reread implantation id
  const { implantationId } = getState();
  const stand = siteConfiguration.stands.find((s) =>
    s.implantations?.map((i) => i.id).includes(implantationId)
  );
  const kanbans = await kanbanRepository.getAllEntities();
  const filteredKanbans = kanbanHelpers.getKanbansCurrentlyInImplantation(
    kanbans,
    nonnull(stand).id,
    implantationId
  );
  const storedMirrorEffect =
    localStorage.getItem(LocalStorageKeys.WORKSHOP_IMPLANTATION_VIEW_MIRROR_EFFECT) === 'true' ??
    false;
  actionDispatch.reduce((initial) => {
    return {
      ...initial,
      kanbans: filteredKanbans,
      kanbanInAnomalyIds: getIdsOfKanbansInAnomaly(nonnull(implantationId), filteredKanbans),
      standId: nonnull(stand?.id),
      mirrorEffect: storedMirrorEffect,
    };
  });
}

export function WorkshopImplantationViewFromProvidedState({
  $gs,
  $,
  isInteractive,
}: WorkshopImplantationViewFromProvidedStateProps): JSX.Element {
  const [t] = useTranslation('workshop');
  const { $imageModal } = $gs;

  const username = useGetState($gs.$session.$user.optChaining().$login) ?? '';
  const stands = useGetState($gs.$siteConfiguration.$stands);
  const contracts = useGetState($gs.$contracts);
  const implantationId = useGetState($.$implantationId);

  const selectedStandId = useMemo((): string | undefined => {
    const stand = stands.find((s) => s.implantations?.map((i) => i.id).includes(implantationId));
    return stand?.id;
  }, [stands, implantationId]);

  const selectedImplantation = useMemo(() => {
    let impl: WorkshopStandImplantation | undefined;

    const stand = stands.find((s) => s.implantations?.map((i) => i.id).includes(implantationId));
    if (stand) {
      impl = stand.implantations?.find((i) => i.id === implantationId);
    }
    return impl;
  }, [stands, implantationId]);

  const queueActionsProvider = useCreateQueueActionsProvider(
    t,
    selectedStandId,
    selectedImplantation,
    username,
    !isInteractive,
    $,
    $gs
  );

  const postActionsProvider = useCreatePostActionsProvider(
    t,
    selectedStandId,
    selectedImplantation,
    username,
    !isInteractive,
    $,
    $gs
  );

  const anomalyPostActionProvider = useCreateAnomalyPostActionProvider(
    t,
    selectedStandId,
    selectedImplantation,
    username,
    !isInteractive,
    $
  );

  const initializeViewActionCallback = useActionCallback(initializeWorkshopView, [], $);
  const $implantationIdWithChangeTrigger = useSelectorWithChangeTrigger(
    $.$implantationId,
    initializeViewActionCallback
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    initializeViewActionCallback();
  }, [initializeViewActionCallback]);

  const selectedKanbanForEstimate = useGetState($.$selectedKanbanForEstimate);
  const showEstimate = selectedKanbanForEstimate !== undefined;

  const contract = contracts.find((c) => selectedKanbanForEstimate?.contract.code === c.code);

  const { $desktopState } = $gs.$detailsView;
  const computeAttachmentUrl = useComputeAttachmentUrl($gs);
  const loadEstimateAttachmentsActionCallback = useActionCallback(
    async ({ actionDispatch }): Promise<void> => {
      if (selectedKanbanForEstimate) {
        await actionDispatch.exec(
          loadEstimateAttachmentsAction,
          'kanban',
          selectedKanbanForEstimate.id
        );
      }
    },
    [selectedKanbanForEstimate],
    $gs.$detailsView.$desktopState.$estimateView
  );

  const downloadAttachmentsZipCallback = useActionCallback(
    async ({ httpClient }) => {
      if (selectedKanbanForEstimate) {
        await downloadAndSave(
          httpClient,
          CoreBackendRoutes.ZIPPED_ATTACHMENT_FOLDER(
            'kanban',
            selectedKanbanForEstimate.id,
            'expertise'
          ),
          'download.zip'
        );
      }
    },
    [selectedKanbanForEstimate],
    $
  );

  const hideEstimateCallback = useActionCallback(
    ({ actionDispatch }) => {
      actionDispatch.setProperty('selectedKanbanForEstimate', undefined);
    },
    [],
    $
  );

  const siteAddress = useGetState($gs.$siteConfiguration.$infos.$address);
  const siteCompanyName = useGetState($gs.$siteConfiguration.$infos.$companyName);
  const siteLogoUrl = useGetState($gs.$siteConfiguration.$infos.$logoUrl);

  return (
    <div>
      {showEstimate &&
        isTruthy(selectedKanbanForEstimate) &&
        isTruthy(selectedKanbanForEstimate.contract) &&
        isTruthy(contract) && (
          <EstimateView
            returnAction={hideEstimateCallback}
            kanban={selectedKanbanForEstimate}
            attributeDescs={contract.attributeDescs}
            estimateMention={contract.estimateMention}
            memoDescs={contract.memoDescs}
            address={siteAddress}
            companyName={siteCompanyName}
            logoUrl={siteLogoUrl}
            computeAttachmentUrl={computeAttachmentUrl}
            $imageModal={$imageModal}
            $={$desktopState.$estimateView}
            loadAvailableAttachmentsEffect={loadEstimateAttachmentsActionCallback}
            downloadAttachmentsZipCallback={downloadAttachmentsZipCallback}
            showPrices={false}
          />
        )}
      {!showEstimate && selectedStandId && selectedImplantation && (
        <WorkshopImplantationComponent
          standId={selectedStandId}
          implantation={selectedImplantation}
          disableAllButtons={!isInteractive}
          $={$}
          postActionsProvider={postActionsProvider}
          queueActionsProvider={queueActionsProvider}
          anomalyPostActionProvider={anomalyPostActionProvider}
          $selectImplantation={$implantationIdWithChangeTrigger}
          $gs={$gs}
        />
      )}
    </div>
  );
}
