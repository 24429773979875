import type { SelectSubcontractorFirmModalStateContainer } from '../../../utils/subcontractor/SelectSubcontractorFirmModalDialog.js';
import { EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE } from '../../../utils/subcontractor/typings/store.js';

export type WorkOnFurtherOperationModalState = {
  readonly active: boolean;
  readonly furtherOperationToWorkOn?: string;
};

export type WorkshopPostViewState = SelectSubcontractorFirmModalStateContainer & {
  readonly expandedSectionIds: readonly string[];
  readonly showFurtherOperations: boolean;
  readonly workOnFurtherOperationModal: WorkOnFurtherOperationModalState;
};

export const EMPTY_WORK_ON_FURTHER_OPERATION_MODAL_STATE: WorkOnFurtherOperationModalState = {
  active: false,
  furtherOperationToWorkOn: undefined,
};

export const EMPTY_WORKSHOP_POST_VIEW_STATE: WorkshopPostViewState = {
  expandedSectionIds: [],
  showFurtherOperations: false,
  workOnFurtherOperationModal: EMPTY_WORK_ON_FURTHER_OPERATION_MODAL_STATE,
  selectSubcontractorFirmModal: EMPTY_SELECT_SUBCONTRACTOR_FIRM_MODAL_STATE,
};
