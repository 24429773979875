import { marked } from 'marked';
import React, { useMemo } from 'react';
import type { StoreStateSelector } from '@stimcar/libs-uikernel';
import { useGetState } from '@stimcar/libs-uikernel';
import type { Store } from '../../state/typings/store.js';
import type { MarketplaceAdHeaderProps } from './MarketplaceAdHeader.js';
import { MarketplaceAdHeader } from './MarketplaceAdHeader.js';

interface Props extends MarketplaceAdHeaderProps {
  readonly $markdownText: StoreStateSelector<Store, string>;
}

export function MarketplaceAd({ $markdownText, ...props }: Props): JSX.Element {
  const markdownText = useGetState($markdownText);
  const markdownAsHTML = useMemo(() => {
    marked.use({ async: false });
    return marked.parse(markdownText) as string;
  }, [markdownText]);
  return (
    <>
      <MarketplaceAdHeader {...props} />
      {/* eslint-disable-next-line react/no-danger */}
      <div className="content mb-5" dangerouslySetInnerHTML={{ __html: markdownAsHTML }} />
    </>
  );
}
