import type { TFunction } from 'i18next';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import type { Customer, SpecificFields } from '@stimcar/libs-base';
import { isTruthyAndNotEmpty } from '@stimcar/libs-kernel';
import { ClickableIcon } from '../../bulma/index.js';

type Line = {
  readonly id: string;
  readonly value: string;
};

interface CustomerInformationsProps {
  readonly customer: Omit<SpecificFields<Customer>, 'contract'>;
  readonly onEditCustomerButtonClicked?: () => Promise<void>;
  readonly onSelectPredefinedCustomerButtonClicked?: () => Promise<void>;
}

function getCustomerLines(
  {
    city,
    civility,
    company,
    email,
    firstName,
    lastName,
    phone,
    street,
    zipCode,
  }: Omit<SpecificFields<Customer>, 'contract'>,
  t: TFunction<string, undefined>
): readonly Line[] {
  const lines: Line[] = [];
  if (isTruthyAndNotEmpty(company)) {
    lines.push({
      id: 'company',
      value: company,
    });
  } else {
    const civilityText = t(`common:civilities.${civility}`);
    const personText = `${civilityText} ${lastName} ${firstName}`;
    lines.push({
      id: 'person',
      value: personText,
    });
  }

  if (isTruthyAndNotEmpty(street)) {
    lines.push({
      id: 'street',
      value: street,
    });
  }

  if (isTruthyAndNotEmpty(zipCode) && isTruthyAndNotEmpty(city)) {
    const addressText = `${zipCode} - ${city}`;
    lines.push({
      id: 'addressText',
      value: addressText,
    });
  } else if (isTruthyAndNotEmpty(zipCode)) {
    lines.push({
      id: 'addressText',
      value: zipCode,
    });
  } else if (isTruthyAndNotEmpty(city)) {
    lines.push({
      id: 'addressText',
      value: city,
    });
  }

  if (isTruthyAndNotEmpty(email)) {
    lines.push({
      id: 'email',
      value: email,
    });
  }

  if (isTruthyAndNotEmpty(phone)) {
    lines.push({
      id: 'phone',
      value: phone,
    });
  }

  return lines.map((line) => ({
    id: line.id,
    value: line.value.trim(),
  }));
}

export function CustomerInformations({
  customer,
  onEditCustomerButtonClicked,
  onSelectPredefinedCustomerButtonClicked,
}: CustomerInformationsProps): JSX.Element {
  const [t] = useTranslation('custom');
  const customerLines = useMemo(() => getCustomerLines(customer, t), [customer, t]);
  return (
    <div>
      {customerLines.map(({ id, value }, index) => (
        <span key={id}>
          {index !== 0 && <br />}
          {value}
          {onEditCustomerButtonClicked && index === 0 && (
            <ClickableIcon
              clickHandler={onEditCustomerButtonClicked}
              id="edit"
              elementClassName="m-l-sm"
            />
          )}
          {onSelectPredefinedCustomerButtonClicked && index === 0 && (
            <ClickableIcon
              clickHandler={onSelectPredefinedCustomerButtonClicked}
              id="address-book"
              elementClassName="m-l-sm"
            />
          )}
        </span>
      ))}
    </div>
  );
}
