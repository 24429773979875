import React from 'react';
import type { WithWarningProps } from './WithWarning.js';
import { WithWarning } from './WithWarning.js';

export interface HorizontalFormFieldProps {
  readonly isNarrow?: boolean;
  readonly labelFlexGrow?: number;
  readonly bodyFlexGrow?: number;
}

const HORIZONTAL_FORM_FIELD_PROPS_DEFAULTS: HorizontalFormFieldProps = {
  isNarrow: false,
  bodyFlexGrow: 2,
  labelFlexGrow: 1,
};

export const HORIZONTAL_FORM_SMALL_LABEL: HorizontalFormFieldProps = {
  bodyFlexGrow: 5,
  labelFlexGrow: 1,
};

// In WithWarning, warning props must be provided, even if undefined. This is to have validation if the composant
// is called for nothing, and using a WithWarning component without any warnings during all lifecycle is useless.
// For the FormField case, we might want to use it without validation warning and we don't want to give an undefined
// warning props each times
export interface FormFieldProps extends Omit<WithWarningProps, 'warning'> {
  readonly warning?: string;
  readonly label: string;
  readonly horizontal?: boolean | HorizontalFormFieldProps;
}

export function FormField({
  children,
  label,
  warning,
  noExclamationTriangleIfWarning,
  horizontal,
}: FormFieldProps): JSX.Element {
  if (horizontal) {
    let { bodyFlexGrow, labelFlexGrow, isNarrow } = HORIZONTAL_FORM_FIELD_PROPS_DEFAULTS;

    if (typeof horizontal === 'object') {
      isNarrow = horizontal.isNarrow !== undefined ? horizontal.isNarrow : isNarrow;
      bodyFlexGrow = horizontal.bodyFlexGrow !== undefined ? horizontal.bodyFlexGrow : bodyFlexGrow;
      labelFlexGrow =
        horizontal.labelFlexGrow !== undefined ? horizontal.labelFlexGrow : labelFlexGrow;
    }

    return (
      <>
        <div className="field is-horizontal">
          <div className="field-label is-normal" style={{ flexGrow: labelFlexGrow }}>
            <label className="label">{label}</label>
          </div>
          <div className="field-body" style={{ flexGrow: bodyFlexGrow }}>
            <div className={`field${isNarrow ? ' is-narrow' : ''} is-normal`}>
              <WithWarning
                warning={warning}
                noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
              >
                {children}
              </WithWarning>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="field">
        <label className="label">{label}</label>
        <WithWarning
          warning={warning}
          noExclamationTriangleIfWarning={noExclamationTriangleIfWarning}
        >
          {children}
        </WithWarning>
      </div>
    </>
  );
}
