import type {
  AttachmentFolder,
  Contract,
  MandatoryFields,
  SparePartManagementType,
} from '@stimcar/libs-base';
import type {
  ActionCallbackFromFunction,
  BusinessObjectToFormData,
  ObjectStateType,
  WithFormValidationWarnings,
} from '@stimcar/libs-uikernel';
import type { FormWithValidationState, TableState } from '@stimcar/libs-uitoolkit';
import {
  EMPTY_CONTRACT_CONFIGURATION,
  HOURLY_RATE_DEFAULT_VALUE,
  SPARE_PART_MARGIN_DEFAULT_PERCENTAGE,
} from '@stimcar/libs-base';
import { TABLE_EMPTY_STATE } from '@stimcar/libs-uitoolkit';
import type { Store } from '../../../state/typings/store.js';

export type OnContractsChangeActionCallback = ActionCallbackFromFunction<
  Store,
  (
    addedOrUpdatedContrats: readonly AdminUIContract[],
    removedContractCodes: readonly string[]
  ) => void
>;

export type ContractEditionModalMode = 'create' | 'update';

export const PRICE_ROUNDING_STEPS = [0.01, 0.05, 0.1, 0.25, 0.5, 1, 5];

export type ContractAttachmentFolderFormData = {
  readonly label: string;
  readonly id: string;
  readonly shareable: boolean;
};

export type EditContractAttachmentFolderModalState =
  FormWithValidationState<ContractAttachmentFolderFormData> & {
    readonly active: boolean;
    readonly mode: ContractEditionModalMode;
    readonly attachmentFolders: readonly AttachmentFolder[];
    readonly initialAttachmentFolder: AttachmentFolder | undefined;
  };

export const EMPTY_CONTRACT_ATTACHMENT_FOLDER_FORM_DATA: WithFormValidationWarnings<ContractAttachmentFolderFormData> =
  {
    id: '',
    label: '',
    shareable: false,
    warnings: {},
  };

export const EDIT_CONTRACT_ATTACHMENT_FOLDER_MODAL_EMPTY_STATE: EditContractAttachmentFolderModalState =
  {
    active: false,
    mode: 'create',
    attachmentFolders: [],
    formData: EMPTY_CONTRACT_ATTACHMENT_FOLDER_FORM_DATA,
    formSubmitClickedOnce: false,
    formSubmitted: false,
    initialAttachmentFolder: undefined,
  };

export type EditContractData = BusinessObjectToFormData<Omit<Contract, 'configuration'>> & {
  readonly estimateValidationMailingList: readonly string[];
  readonly sparePartMargin: string;
  readonly hourlyRate: string;
  readonly sparePartManagementType: SparePartManagementType;
  readonly roundPriceTo: string;
  readonly pkgDealDescCodesForKanban: readonly string[];
  readonly pkgDealDescCodesForPurchaseOrder: readonly string[];
  readonly upstreamTC: boolean;
  readonly mandatoryFields: MandatoryFields;
  readonly handleMultiplePurchaseOrders: boolean;
  readonly estimateMention: string;
  readonly expertHints: string;
  // The following properties are strings because for now the UO for those are TextAreas
  // because the UI is slightly complex to do
  // This is to speed up the mutation of legacyContracts into packageDealDatabases
  readonly attributeDescs: string;
  readonly memoDescs: string;
};

export const EMPTY_CREATE_CONTRACT_DATA: EditContractData = {
  code: '',
  label: '',
  roundPriceTo: String(EMPTY_CONTRACT_CONFIGURATION.roundPriceTo),
  packageDealDatabase: '',
  hourlyRate: String(HOURLY_RATE_DEFAULT_VALUE),
  sparePartMargin: String(SPARE_PART_MARGIN_DEFAULT_PERCENTAGE * 100),
  estimateValidationMailingList: [],
  sparePartManagementType: 'fullyManagedByStimcar',
  pkgDealDescCodesForKanban: [],
  pkgDealDescCodesForPurchaseOrder: [],
  upstreamTC: false,
  mandatoryFields: {
    purchaseOrders: false,
  },
  handleMultiplePurchaseOrders: false,
  attributeDescs: '',
  estimateMention: '',
  expertHints: '',
  memoDescs: '',
};

export type EditContractDialogState = FormWithValidationState<EditContractData> & {
  readonly active: boolean;
  readonly contractCode: string | undefined;
  readonly mode: ContractEditionModalMode;
  readonly availablePackageDealDescCodes: readonly string[];
  readonly costFreePackageDealDescCodes: readonly string[];
  readonly selectedAttachmentFolderId: string;
  readonly allpackageDealDatabases: readonly string[];
  readonly editContractAttachmentFolderModal: EditContractAttachmentFolderModalState;
};

export const EMPTY_EDIT_CONTRACT_DIALOG_STATE: EditContractDialogState = {
  active: false,
  contractCode: undefined,
  mode: 'create',
  allpackageDealDatabases: [],
  availablePackageDealDescCodes: [],
  costFreePackageDealDescCodes: [],
  selectedAttachmentFolderId: '',
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    ...EMPTY_CREATE_CONTRACT_DATA,
    warnings: {},
  },
  editContractAttachmentFolderModal: EDIT_CONTRACT_ATTACHMENT_FOLDER_MODAL_EMPTY_STATE,
};

export type DeleteContractDialogState = FormWithValidationState<ObjectStateType> & {
  readonly active: boolean;
  readonly contractCode: string;
};

export const EMPTY_DELETE_CONTRACT_DIALOG_STATE: DeleteContractDialogState = {
  active: false,
  contractCode: '',
  formSubmitted: false,
  formSubmitClickedOnce: false,
  formWarning: undefined,
  formData: {
    warnings: {},
  },
};

// Substitue code by ID (in order to be able to put it in the state : in the state, objects that are in arrays must have an id field by convention)
export type AdminUIContract = Omit<Contract, 'code'> & {
  readonly id: string;
};

export type AdminContractsState = TableState<AdminUIContract> & {
  readonly editContractDialogState: EditContractDialogState;
  readonly deleteContractDialogState: DeleteContractDialogState;
};

export const EMPTY_CONTRACTS_ADMIN_STATE: AdminContractsState = {
  ...TABLE_EMPTY_STATE,
  editContractDialogState: EMPTY_EDIT_CONTRACT_DIALOG_STATE,
  deleteContractDialogState: EMPTY_DELETE_CONTRACT_DIALOG_STATE,
};
